@media (max-width: 991px) {
.delivery__head {
	margin-bottom: 1.25rem;
	.nav-tabs {
		padding: 0.125rem;
		border-radius: 0.5rem;
		li {
			width: calc(50% - (0.125rem / 2));
		}
		.btn {
			height: 2.5rem;
			font-size: 0.75rem;
		}
	}
	p {
		margin: 0 0 1.25rem -0.75rem;
		padding: 1rem;
		width: 100vw;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.0005em;
		border-radius: 0.5rem;
	}
}
.delivery__head-title {
	margin-bottom: 0.75rem;
	font-size: 1.1875rem;
	line-height: 1.25rem;
	letter-spacing: 0;
}
.delivery__change-city {
	margin-bottom: 1.25rem;
	height: auto;
}
.delivery__change-city-title {
	flex-direction: column;
	align-items: flex-start;
	h4 {
		margin: 0;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}
	a {
		padding: 0.3125rem 0;
		font-size: 0.75rem;
		line-height: 1rem;
	}
}
.delivery__change-city-form {
	width: 100%;
	form {
		.form-control {
			padding: 0 5.375rem 0 2rem;
		}
		.ico {
			width: 1rem;
			height: 1rem;
		}
	}
}
.delivery__list {
	margin-bottom: 0.75rem;
}
.delivery__list-block {
	padding: 1.25rem 0;
	border-top-width: 0.0625rem;
	& > div {
		& > div {
			margin-bottom: 0.75rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	h5 {
		margin-bottom: 1.125rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}
	ul {
		li {
			&::before {
				padding: 0 0.5rem;
			}
		}
	}
	.btn {
		margin-top: 1rem;
		width: 100%;
		height: 2.125rem;
	}
}
.delivery__list-block h6,
	.delivery__list-block p,
	.delivery__list-block ul li {
	font-size: 1rem;
	line-height: 1.5rem;
}
.delivery__info-block {
	margin-bottom: 1.25rem;
	padding: 1.25rem 1rem;
	h5 {
		margin-bottom: 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}
	p {
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}
}

}