@media (max-width: 991px) {
	.mobile-menu {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background: #fff;
		z-index: 102;
	}

	.mobile-menu__wrap ul {
		display: flex;
	}

	.mobile-menu__wrap li {
		display: flex;
		width: 20%;
	}

	.mobile-menu__wrap li a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 2.5rem;
	}

	.mobile-menu__wrap li a .ico {
		display: block;
		width: 1.5rem;
		height: 1.5rem;
	}

	.mobile-menu__wrap li a span {
		position: relative;
	}

	.mobile-menu__wrap li a .nubmer {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -0.3125rem;
		right: -0.3125rem;
		width: 1rem;
		height: 1rem;
		color: #fff;
		font-size: 0.5625rem;
		line-height: 1;
		letter-spacing: 0.005em;
		text-align: center;
		background: var(--c-main);
		border-radius: 50%;
	}

	.mobile-menu__wrap li a .nubmer.active {
		background: #FF4E44;
	}
}