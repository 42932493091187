@media (max-width: 991px) {
	.lk {
		padding-top: 0.75rem;
	}

	.lk form .form-btns .btn {
		width: 100%;
	}

	.lk__container::before {
		display: none;
	}

	.lk__title {
		margin-bottom: 0.75rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.lk__favorite {
		margin-bottom: -0.75rem;
	}

	.lk__favorite .card {
		margin-right: auto;
		margin-bottom: 0.75rem;
		width: 10.75rem;
	}

	.lk__favorite .card:nth-child(2n + 2) {
		margin-right: 0;
	}

	.lk__favorite .card__btns .add {
		width: 8.25rem;
		height: 2.125rem;
	}

	.lk__favorite .card__btns .delete {
		width: 2.125rem;
		height: 2.125rem;
	}

	.lk__menu {
		position: relative;
		top: 0;
		margin-bottom: 1.25rem;
	}

	.lk__menu .nav-tabs {
		flex-direction: row;
		flex-wrap: nowrap;
		margin-left: -0.75rem;
		padding: 0 0.75rem;
		width: 100vw;
		overflow-x: auto;
	}

	.lk__menu .nav-tabs .nav-item:not(:last-child) {
		margin-right: 0.25rem;
	}

	.lk__menu .nav-tabs .nav-link {
		padding: 0.5rem;
		width: auto;
		font-size: 0.75rem;
		line-height: 1rem;
		white-space: nowrap;
		border-radius: 0.5rem;
	}

	.lk__menu-user {
		display: flex;
		align-items: center;
		margin-bottom: 0.875rem;
		padding: 0;
	}

	.lk__menu-user .photo {
		flex-shrink: 0;
		margin: 0 0.75rem 0 0;
		width: 3.25rem;
		height: 3.25rem;
	}

	.lk__menu-user .photo h4 {
		font-size: 1.0625rem;
		line-height: 1.375rem;
	}

	.lk__menu-user .name {
		margin-right: auto;
	}

	.lk__menu-user .name h5 {
		margin: 0;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		word-spacing: normal;
	}

	.lk__menu-user .exit {
		flex-shrink: 0;
		margin-left: 0.75rem;
	}

	.lk__menu-user .exit .ico {
		width: 1.5rem;
		height: 1.5rem;
	}

	.lk__main-banner {
		margin-bottom: 1.25rem;
		height: 4.5rem;
	}

	.lk__main-banner picture img {
		border-radius: 0.5rem;
	}

	.lk__main-banner h4 {
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.lk__main-top {
		margin-bottom: 1.5rem;
	}

	.lk__main-top ul li {
		margin-right: auto;
		padding: 1rem;
		width: 10.625rem;
	}

	.lk__main-top ul li:last-child {
		margin-right: 0;
	}

	.lk__main-top ul li p {
		margin-bottom: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.lk__main-top ul li h5 {
		font-size: 0.9375rem;
		line-height: 1.125rem;
	}

	.lk__main-body {
		margin-bottom: 1.625rem;
	}

	.lk__main-body--title {
		margin-bottom: 0.75rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
	}

	.lk__main-foot h4 {
		margin-bottom: 0.25rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.lk__main-foot h5 {
		margin-bottom: 0.75rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.lk__main-foot-list .swiper-slide {
		margin-right: 0.5rem;
		padding: 1rem;
		width: 18.875rem;
	}

	.lk__main-foot-list .product {
		margin-bottom: 0.75rem;
	}

	.lk__main-foot-list .product .img {
		margin-right: 0.75rem;
		width: 2.5rem;
		height: 3.5rem;
	}

	.lk__main-foot-list .product .img img {
		border-radius: 0.25rem;
	}

	.lk__main-foot-list .product p {
		display: block;
		display: -webkit-box;
		height: 2.375rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.lk__main-foot-list .vote {
		margin-bottom: 0.75rem;
		padding: 0.125rem 0;
	}

	.lk__main-foot-list .vote .stars li {
		margin-right: 0.125rem;
	}

	.lk__main-foot-list .vote .stars li i {
		width: 1rem;
		height: 1rem;
	}

	.lk__main-foot-list .vote p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.lk__main-foot-list .btns .btn {
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.lk__orders {
		display: flex;
		align-items: flex-start;
		margin-left: -0.75rem;
		padding: 0 0.75rem;
		width: 100vw;
		overflow-x: auto;
	}

	.lk__orders.column {
		flex-direction: column;
		overflow-x: visible;
	}

	.lk__orders.column .lk__order {
		margin: 0 0 0.75rem;
		width: 100%;
	}

	.lk__order {
		flex-shrink: 0;
		position: relative;
		margin: 0 0.5rem 0 0;
		padding: 1rem;
		width: 20.3125rem;
	}

	.lk__order hr {
		display: block;
		width: 100%;
		margin: 1.5rem 0;
		height: 0.0625rem;
		background: #DCD6D1;
		border: 0;
		opacity: 1;
	}

	.lk__order:last-child,
  .lk__orders.column .lk__order:last-child {
		margin: 0;
	}

	.lk__order-head {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		padding: 1rem;
		transform: rotate(90deg) translate(0, -100%);
		transform-origin: 0% 0%;
	}

	.lk__order-head h3 {
		margin: 0;
		font-size: 0.6875rem;
		line-height: 0.875rem;
		letter-spacing: 0.0005em;
	}

	.lk__order-photo {
		position: absolute;
		top: 1rem;
		left: 2.625rem;
	}

	.lk__order-photo .img {
		margin: 0;
		width: 3.75rem;
		height: 6.5625rem;
	}

	.lk__order-photo .img img {
		border-radius: 0.5rem;
	}

	.lk__order-number {
		flex-wrap: wrap;
		align-items: flex-start;
		margin-bottom: 0.5rem;
		padding-left: 6.125rem;
	}

	.lk__order-number .number--date {
		margin: 0 0.75rem 0.5rem 0;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: -0.005em;
	}

	.lk__order-number .number--payment {
		margin: 0 0 0.5rem 0;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: -0.005em;
	}

	.lk__order-number .number--payment .ico-left {
		margin-right: 0.25rem;
	}

	.lk__order-number .number--delivery {
		padding: 0.25rem 0.5rem;
		font-size: 0.75rem;
		line-height: 1rem;
		border-radius: 0.25rem;
	}

	.lk__order-date {
		margin: 0 0 0.875rem;
		padding-left: 6.125rem;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: -0.005em;
	}

	.lk__order-info {
		padding: 0;
		border: 0;
	}

	.lk__order-info .lk__order-info-btn.top {
		margin: 0 0 0 6.125rem;
		font-size: 0.6875rem;
		line-height: 0.875rem;
		letter-spacing: 0.0005em;
	}

	.lk__order-info.show .lk__order-info-btn span:nth-child(1) {
		display: none;
	}

	.lk__order-info.show .lk__order-info-btn span:nth-child(2) {
		display: block;
	}

	.lk__order-status {
		margin-bottom: 1rem;
		padding-top: 1rem;
	}

	.lk__order-status li {
		display: flex;
		align-items: center;
		padding: 0 0 1.75rem 1.75rem;
	}

	.lk__order-status li:last-child {
		padding-bottom: 0;
		width: 100%;
	}

	.lk__order-status li:not(:last-child)::before {
		top: 0;
		left: 0.375rem;
		width: 0.125rem;
		height: 100%;
	}

	.lk__order-status li i {
		width: 1rem;
		height: 1rem;
	}

	.lk__order-status li p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
		letter-spacing: 0;
	}

	.lk__order-status li small {
		margin-left: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		letter-spacing: 0;
	}

	.lk__order-info--wrap {
		flex-direction: column;
		justify-content: flex-start;
	}

	.lk__order-data {
		width: 100%;
	}

	.lk__order-data h3 {
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.lk__order-data ul li {
		flex-direction: column;
		margin-bottom: 0.5rem;
	}

	.lk__order-data ul li:last-child {
		margin-bottom: 0;
	}

	.lk__order-data ul li p:nth-child(1) {
		margin: 0;
		width: 100%;
		color: #B3A8A1;
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.lk__order-data ul li p:nth-child(2) {
		margin: 0;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.lk__order-info-col {
		width: 100%;
	}

	.lk__order-info-btn span:nth-child(1) {
		display: block;
	}

	.lk__order-info-btn span:nth-child(2) {
		display: none;
	}

	.lk__order-foot {
		margin-top: 1.5rem;
		display: flex !important;
		align-items: center;
		margin-top: 1rem;
	}

	.lk__order-foot .btn {
		margin-right: auto;
		width: 9.75rem;
		height: 2.5rem;
		font-size: 0.75rem;
	}

	.lk__order-foot .lk__order-info-btn {
		margin: 0;
		padding: 0.25rem;
		width: 9.75rem;
		font-size: 0.75rem;
		line-height: 1rem;
		text-align: center;
	}

	.lk__order-table {
		margin-top: 1rem;
		padding: 0;
		border: 0;
	}

	.lk__order-table .body ul li {
		flex-direction: column;
		position: relative;
		padding: 0.75rem 0 0.5rem;
		border-top: 0.0625rem solid #DCD6D1;
	}

	.lk__order-table .body ul li > div:nth-child(1) {
		width: 100%;
	}

	.lk__order-table .body ul li > div.cost {
		width: 100%;
	}

	.lk__order-table .body .product .img {
		position: relative;
		margin-right: 0.75rem;
		width: 2.5rem;
		height: 3.5rem;
	}

	.lk__order-table .body .product .img img {
		border-radius: 0.25rem;
	}

	.lk__order-table .body .product .img p {
		position: absolute;
		top: -0.375rem;
		right: -0.375rem;
		margin: 0;
		width: 0.75rem;
		height: 0.75rem;
		font-size: 0.5625rem;
		line-height: 0.75rem;
		letter-spacing: 0.0005em;
		text-align: center;
		color: #fff;
		background: var(--c-main);
		border-radius: 50%;
	}

	.lk__order-table .body .product .text {
		padding: 0;
	}

	.lk__order-table .body .product .text p {
		display: block;
		display: -webkit-box;
		height: 2.375rem;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.lk__order-table .body p {
		margin-bottom: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.lk__order-table .body small {
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
	}

	.lk__order-table .body small span {
		margin-right: 0.75rem;
	}

	.lk__order-table .foot {
		padding-top: 1rem;
	}

	.lk__order-table .foot ul {
		padding: 0.25rem 0;
	}

	.lk__order-table .foot ul li {
		padding: 0.25rem 0;
	}

	.lk__order-table .foot ul li p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.lk__profile .nav-tabs {
		flex-wrap: nowrap;
		margin: 0 0 1.25rem -0.75rem;
		padding: 0 0.75rem;
		width: 100vw;
		border-bottom-width: 0.0625rem;
		overflow-x: auto;
	}

	.lk__profile .nav-tabs li {
		margin-right: 1.5rem;
	}

	.lk__profile .nav-tabs .nav-link {
		padding: 0.25rem 0 0.125rem;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
		border-bottom-width: 0.125rem;
		white-space: nowrap;
	}

	.lk__profile form .form-link {
		position: absolute;
		top: 4.625rem;
		right: 0.75rem;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.lk-subscribe__block {
		margin-bottom: 1.25rem;
		padding: 1.5rem;
		width: 100%;
	}

	.lk-subscribe__block h4 {
		margin: 0;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}

	.lk-subscribe__block h5 {
		margin-bottom: 1.25rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.0002em;
	}

	.lk-subscribe__block :last-child {
		margin-bottom: 0;
	}

	.lk__address:not(:last-child) {
		margin-bottom: 0;
	}

	.lk__address .btn {
		height: 2.5rem;
	}

	.lk__address-row {
		margin-bottom: 1.5rem;
	}

	.lk__address-row .swiper-wrapper {
		flex-direction: column;
	}

	.lk__address-row .swiper-slide {
		margin: 0 0 1rem;
		padding: 1.25rem;
		width: 100%;
		min-height: 7.375rem;
	}

	.lk__address-row .swiper-slide:last-child {
		margin-bottom: 0;
	}

	.lk__address-row .head {
		margin-bottom: 1rem;
	}

	.lk__address-row .lk__address-main {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.lk__address-row .body p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
		max-height: 2.375rem;
	}

	.lk__address-col {
		left: auto;
		right: 0;
		padding: 0.5rem;
		box-shadow: 0 0.625rem 1.875rem 0 rgba(38, 36, 34, 0.08);
		border-radius: 0.5rem;
		transform: translate(0, 100%);
	}

	.lk__address-col li a {
		padding: 0.5rem;
		width: 11.75rem;
		border-radius: 0.25rem;
	}

	.lk__bonus-card {
		margin-bottom: 1.25rem;
		padding: 1rem;
		border-radius: 0.5rem;
		height: 12.125rem;
	}

	.lk__bonus-card .top h5 {
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.lk__bonus-card .mid p {
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
	}

	.lk__bonus-card .mid h2 {
		margin: 0;
		font-size: 1.5625rem;
		line-height: 2rem;
		letter-spacing: -0.001em;
	}

	.lk__bonus-card .mid h6 {
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.lk__bonus-card .bot p {
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
	}

	.lk__bonus-card .bot h6 {
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.lk__bonus-score {
		margin-bottom: 1.25rem;
		padding: 1rem 0.5rem 1rem 1rem;
	}

	.lk__bonus-score--title {
		margin-bottom: 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.lk__bonus-score-list {
		height: 21.75rem;
	}

	.lk__bonus-score-list ul {
		padding-right: 1rem;
	}

	.lk__bonus-score-list ul li {
		margin-bottom: 1rem;
	}

	.lk__bonus-score-list ul li h4 {
		margin-bottom: 0.5rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.lk__bonus-score-list ul li h5 {
		margin-bottom: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.0005em;
	}

	.lk__bonus-score-list ul li h6 {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.lk__bonus-score-list ul li p {
		bottom: 1rem;
		padding: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.0005em;
		border-radius: 0.25rem;
	}

	.lk__bonus-code {
		margin-bottom: 1.25rem;
		padding: 1rem;
	}

	.lk__bonus-code h5 {
		margin-bottom: 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.lk__bonus-code .form-control {
		padding: 0 4.75rem 0 1rem;
		height: 3.25rem;
		font-size: 0.8125rem;
	}

	.lk__bonus-code .form-group .btn {
		right: 0.375rem;
		width: 3.375rem;
		height: 2.5rem;
	}

	.lk__bonus-banner {
		margin-bottom: 1.25rem;
		padding: 0.5rem;
		height: 11.25rem;
		border-radius: 0.25rem;
	}

	.lk__bonus-banner h3 {
		margin-bottom: 0;
		font-size: 1.5625rem;
		line-height: 2rem;
	}

	.lk__bonus-banner p {
		margin-bottom: 0.5rem;
		font-size: 0.9375rem;
		line-height: 1.3125rem;
	}

	.lk__bonus-banner .btn {
		padding: 0.625rem 0.75rem;
		font-size: 0.6875rem;
		line-height: 0.875rem;
	}

	.lk__bonus-banner picture,
  .lk__bonus-banner picture img {
		border-radius: 0.25rem;
	}

	.lk__refund .btn {
		width: 100%;
		height: 2.5rem;
	}

	.lk__refund .top {
		margin-bottom: 1.5rem;
	}

	.lk__refund .lk__order-info {
		padding: 0;
	}

	.lk__refund .lk__order-data:first-child {
		padding-top: 1.5rem;
	}

	.lk__refund .lk__order-table .head ul li:nth-child(1) {
		width: 34%;
	}

	.lk__refund .lk__order-table .head ul li:nth-child(2) {
		width: 8%;
		padding-left: 0.75rem;
		text-align: left;
	}

	.lk__refund .lk__order-table .head ul li:nth-child(3) {
		width: 36%;
		padding-left: 0.75rem;
		text-align: left;
	}

	.lk__refund .lk__order-table .head ul li:nth-child(4) {
		width: 9%;
		text-align: right;
	}

	.lk__refund .lk__order-table .head ul li:nth-child(5) {
		width: 13%;
		text-align: right;
	}

	.lk__refund .lk__order-table .body ul li > div:nth-child(1) {
		width: 34%;
	}

	.lk__refund .lk__order-table .body ul li > div:nth-child(2) {
		width: 8%;
		padding-right: 0.75rem;
		text-align: right;
	}

	.lk__refund .lk__order-table .body ul li > div:nth-child(3) {
		width: 36%;
		padding-left: 0.75rem;
		text-align: left;
	}

	.lk__refund .lk__order-table .body ul li > div:nth-child(4) {
		width: 9%;
		text-align: right;
	}

	.lk__refund .lk__order-table .body ul li > div:nth-child(5) {
		width: 13%;
		text-align: right;
	}

	.lk__refund-petition .lk__title {
		margin-bottom: 1.5rem;
	}

	.lk__refund-petition .lk__order-status {
		flex-direction: column;
	}

	.lk__refund-petition .lk__order-status li:last-child {
		width: 100%;
	}

	.lk__refund-petition form .form-group {
		width: 100%;
	}

	.lk__refund-petition form .form-group .btn {
		width: 100%;
	}

	.lk__refund-petition--lk .information {
		padding: 1.5rem;
	}

	.lk__refund-petition--lk .information p {
		margin-bottom: 1rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}

	.lk__refund-petition--lk .information__link {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.lk__refund-petition--products .lk__order-table .body ul li {
		padding: 0.75rem 0;
	}

	.lk__refund-petition--products .lk__order-table .body ul li > div {
		margin-bottom: 0.5rem;
	}

	.lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(1) {
		margin-bottom: 0.75rem;
		padding-bottom: 0.5rem;
		width: 20rem;
	}

	.lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(5) {
		margin: 0;
	}

	.lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(6) {
		position: absolute;
		top: 1rem;
		right: 0.25rem;
		padding: 0;
		width: auto;
	}

	.lk__refund-petition--products .lk__order-table .product p {
		margin-bottom: 0.25rem;
	}

	.lk__refund-petition--products .lk__order-table p {
		margin: 0;
	}

	.lk__refund-petition--products .lk__order-table p span {
		color: #B3A8A1;
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.lk__refund-petition--products .lk__order-table .form-selector--list ul li {
		padding: 0;
		border: 0;
	}

	.lk__refund-petition--products .lk__order-table .foot {
		margin-bottom: 1.25rem;
		padding: 0.5rem 0;
		border-top: 0.0625rem solid #B3A8A1;
	}

	.lk__refund-petition--products .lk__order-table .foot ul {
		padding: 0;
	}

	.lk__refund-petition--products .lk__order-table .foot ul li {
		padding: 0;
	}

	.lk__refund-petition--products .lk__order-table .foot ul li p {
		padding: 0;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.lk__refund-petition--products ul li .product {
		margin-bottom: 0.75rem;
	}

	.lk__refund-petition--products .form-selector--btn {
		padding: 0;
		width: 100%;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		background: transparent;
	}

	.lk__refund-petition--products .form-selector--btn::after {
		margin-left: auto;
		margin-right: 0;
	}

	.lk__refund-petition--products .form-selector--list {
		bottom: -0.75rem;
		padding: 0;
		height: auto;
	}

	.lk__refund-petition--products .form-selector--list ul li a {
		padding: 0.625rem 1.25rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.lk__refund-petition--products .form-checkbox {
		justify-content: flex-end;
	}

	.lk__refund-petition--products .form-checkbox .label-dot {
		margin: 0;
	}

	.lk__refund-petition--products .form-checkbox .label-dot::after {
		background-image: url("../../img/svg/check-2-white.svg");
	}

	.lk__refund-petition--products .form-checkbox input:checked + .label-dot {
		background: var(--c-main);
	}

	.lk__refund-petition--products form .form-line > label {
		width: auto;
	}

	.lk__refund-petition--products form .form-group {
		flex-grow: 0;
		width: 100%;
	}

	.lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(2),
  .lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(3),
  .lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(4),
  .lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(5) {
		padding: 0;
		width: 100%;
		text-align-last: left;
	}

	.lk__refund-petition--products form .form-btns .btn,
  .lk__refund-petition--requisites form .form-btns .btn {
		margin: 0 0 0.75rem;
		width: 100%;
		height: 2.5rem;
	}

	.lk__refund-petition--products form .form-btns .btn:last-child,
  .lk__refund-petition--requisites form .form-btns .btn:last-child {
		margin-bottom: 0;
	}
}