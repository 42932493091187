@media (max-width: 991px) {
	.text-page__title {
		margin-bottom: 0.75rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
		letter-spacing: 0;
	}

	.text-page__title:last-child {
		margin-bottom: 0;
	}

	.promo .site-title__wrap {
		display: block;
		padding: 0.5rem 2.1875rem 0 1.4375rem;
		text-align: center;
	}

	.promo .site-title {
		position: relative;
		text-align: center;
	}

	.promo .site-title sup {
		right: -0.75rem;
		transform: translate(100%, 0);
	}

	.text-page__title-small {
		margin-bottom: 1.5rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.text-page__main-banner {
		margin-bottom: 1.25rem;
		height: 6.625rem;
		border-radius: 0.5rem;
	}

	.text-page__main-banner h3 {
		margin: 0 0 0.25rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
		letter-spacing: 0;
	}

	.text-page__main-banner h4 {
		margin: 0 0 0.25rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.text-page__main-banner h5 {
		margin: 0 0 0.25rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.text-page__main-banner p {
		margin: 0 0 0.75rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.text-page__main-banner .btn {
		margin-top: 0.25rem;
		width: 14.6875rem;
		height: 2.125rem;
	}

	.podeli .text-center {
		text-align: left !important;
	}

	.podeli .text-page__main-banner {
		height: 6rem;
	}

	.bonus .text-page__main-banner {
		height: 4.8125rem;
	}

	.text-page__main-banner picture,
  .text-page__main-banner picture img {
		border-radius: 0.5rem;
	}

	.text-page .form-btns {
		justify-content: space-between;
		margin-bottom: 1.5rem;
	}

	.text-page .form-btns .btn {
		margin: 0;
		padding: 0;
		width: 10.75rem;
		height: 2.25rem;
	}

	.text-page blockquote {
		display: flex;
		flex-direction: column;
		margin: 0 0 1.25rem -0.75rem;
		padding: 1rem;
		width: 100vw;
		font-size: 0.875rem;
		line-height: 1.25rem;
		border-radius: 0.5rem;
	}

	.text-page blockquote b {
		margin: 0 0 0.5rem;
		width: 100%;
	}

	.text-page blockquote ul {
		margin-left: 0;
	}

	.text-page blockquote ul li {
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.text-page blockquote ul li:last-child {
		margin-bottom: 0;
	}

	.text-page blockquote ul li::before {
		padding: 0 0.5rem;
	}

	.text-page .reserve__head blockquote {
		margin-bottom: 0;
	}

	.text-page h5 {
		margin-bottom: 1.5rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
	}

	.text-page .faq__list {
		margin-bottom: 2rem;
	}

	.text-page .faq .text-page__title-small {
		margin-bottom: 1.5rem;
	}

	.text-page__advantages .text-page__title-small {
		margin-bottom: 2rem;
	}

	.text-page__advantages.advantages--comfortable .text-page__advantages-foot {
		margin: 0.75rem 0 0;
	}

	.text-page__advantages-list {
		flex-wrap: nowrap;
		margin: 0 0 0.75rem -0.75rem;
		padding: 0 0.75rem;
		width: 100vw;
		overflow-x: auto;
	}

	.text-page__advantages-col {
		flex-shrink: 0;
		margin-right: 0.5rem;
		padding: 0;
		width: 17.5rem;
	}

	.text-page__advantages-col:last-child {
		margin-right: 0;
	}

	.text-page__advantages-block {
		padding: 1rem;
	}

	.text-page__advantages-block .img {
		margin-bottom: 0.75rem;
		width: 2.5rem;
		height: 2.5rem;
	}

	.text-page__advantages-block .img img {
		width: 1.5rem;
		height: 1.5rem;
	}

	.text-page__advantages-block h6 {
		margin-bottom: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.0005em;
	}

	.text-page__advantages-block p {
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.text-page__advantages-foot {
		margin-bottom: 0;
	}

	.text-page__advantages-foot p {
		margin-bottom: 1.25rem;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
	}

	.text-page__advantages-foot .btn {
		width: 100%;
		height: 2.5rem;
		font-size: 0.75rem;
	}

	.text-page__wrap {
		align-items: flex-start;
		margin-bottom: 1rem;
	}

	.text-page__left {
		position: relative;
		top: 0;
		margin-bottom: 1rem;
	}

	.text-page__left :last-child {
		margin-bottom: 0 !important;
	}

	.text-page__list-block {
		margin-bottom: 0.25rem;
	}

	.text-page__list-block .information__link {
		padding: 1rem 0.75rem;
	}

	.text-page__list-block .information__link .circle {
		margin-right: 0.5rem;
		width: 2.5rem;
		height: 2.5rem;
	}

	.text-page__list-block .information__link .circle .ico {
		width: 1.5rem;
		height: 1.5rem;
		background-size: 1.4375rem;
	}

	.text-page__list-block .information__link p {
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.text-page__list-block .information__link .ico-chevron-down-b {
		margin-left: 0.5rem;
		width: 1rem;
		height: 1rem;
	}

	.text-page__list-block .text-page__col-wrap {
		margin-top: 0;
		padding: 1rem 0.75rem;
	}

	.text-page__list-block .text-page__col-wrap .text-page__col-wrap {
		margin-bottom: 0.75rem;
	}

	.text-page__list-block .text-page__col-wrap h5 {
		margin: 0 0 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.text-page__list-block .text-page__col-wrap p {
		margin: 0 0 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.3125rem;
	}

	.text-page__list-block .text-page__col-wrap ul {
		margin-bottom: 0.75rem;
	}

	.text-page__list-block .text-page__col-wrap li {
		font-size: 0.9375rem;
		line-height: 1.3125rem;
	}

	.text-page__list-block .text-page__col-wrap li::before {
		padding: 0 0.5rem;
	}

	.text-page__list-block .text-page__col-wrap small {
		display: inline-block;
		margin-bottom: 1rem;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0.0002em;
	}

	.text-page__list-block .text-page__col-wrap .ico-cont {
		align-items: flex-start;
		margin-bottom: 1.25rem;
	}

	.text-page__list-block .text-page__col-wrap .ico {
		flex-shrink: 0;
		margin-right: 0.75rem;
		width: 2rem;
		height: 2rem;
	}

	.text-page__list-block .text-page__col-wrap :last-child {
		margin-bottom: 0;
	}

	.text-page__list-block.information .text-page__col-wrap {
		margin-top: -0.25rem;
		padding: 0 0.75rem 1rem;
	}

	.text-page__common {
		margin-bottom: 0.75rem;
	}

	.text-page__common hr {
		margin: 0 0 0.75rem;
		height: 0.0625rem;
	}

	.text-page__common h6 {
		margin: 0 0 0.375rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.text-page__common p {
		margin: 0 0 0.375rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
		letter-spacing: 0.0002em;
	}

	.text-page__common small {
		margin: 0 0 0.375rem;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0002em;
	}

	.text-page__banners {
		margin-bottom: -0.75rem;
	}

	.text-page__banner {
		margin-bottom: 0.75rem;
		padding: 0.5rem;
		height: 11.25rem;
	}

	.text-page__banner h2 {
		margin-bottom: 0;
		font-size: 1.5625rem;
		line-height: 2rem;
		letter-spacing: -0.001em;
	}

	.text-page__banner p {
		margin-bottom: 0.5rem;
		font-size: 0.9375rem;
		line-height: 1.3125rem;
	}

	.text-page__banner picture,
  .text-page__banner picture img {
		border-radius: 0.25rem;
	}

	.reserve__head {
		margin-bottom: 1.25rem;
	}

	.reserve__body {
		margin-bottom: 2.625rem;
	}

	.reserve__foot {
		margin-bottom: 0;
	}

	.reserve__foot ul li {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.reserve__foot ul li::before {
		padding: 0 0.5rem;
	}

	.reserve__foot h3 {
		font-size: 1.1875rem;
		line-height: 1.25rem;
		letter-spacing: 0;
	}

	.reserve__foot-wrap {
		flex-direction: column;
		margin-bottom: 1.5rem;
		padding: 1.25rem 1rem 1.25rem 0.5rem;
	}

	.reserve__foot-wrap .block {
		margin-bottom: 0.75rem;
		width: 100%;
	}

	.reserve__foot-wrap .block:last-child {
		margin-bottom: 0;
	}

	.how-work {
		padding: 1.5rem 0;
	}

	.how-work h3 {
		margin-bottom: 1.25rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
		letter-spacing: 0;
		text-align: left;
	}

	.how-work ul {
		justify-content: flex-start;
		margin-bottom: 1.25rem;
		margin-left: -0.75rem;
		padding: 0 0.75rem;
		width: 100vw;
		overflow-x: auto;
	}

	.how-work ul li {
		flex-shrink: 0;
		margin-right: 1.25rem;
		width: 12.5rem;
	}

	.how-work ul li:last-child {
		margin-right: 0;
	}

	.how-work ul li img {
		margin-bottom: 1rem;
		width: 2.75rem;
	}

	.how-work ul li h6 {
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.0005em;
	}

	.how-work ul li p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.how-work.text-page .form-btns {
		margin: 0;
	}

	.how-work.text-page .form-btns .btn {
		width: 100%;
		height: 2.5rem;
		font-size: 0.75rem;
	}

	.text-page__cards {
		margin-bottom: 1.75rem;
	}

	.text-page__cards .form-btns {
		margin: 0;
	}

	.text-page__cards .form-btns .btn {
		width: 100%;
		height: 2.5rem;
		font-size: 0.75rem;
	}

	.text-page__card {
		margin-bottom: 0.25rem;
		padding-bottom: 1.25rem;
	}

	.text-page__card .img {
		margin: 0;
		padding: 1rem;
		height: 13.25rem;
		border-radius: 0.5rem;
	}

	.text-page__card .img h2 {
		font-size: 1.9375rem;
		line-height: 2.25rem;
		letter-spacing: -0.001em;
	}

	.text-page__card .img h5 {
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.text-page__card .information__link {
		margin: 0;
		padding: 1.25rem 0 1rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.text-page__card .information__collapse ul > li:not(:last-child) {
		margin-bottom: 1rem;
	}

	.text-page__card .information__collapse ul > li p {
		margin-bottom: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.text-page__card .information__collapse ul > li ol li::before {
		padding: 0 0.5rem;
	}

	.text-page__link {
		font-size: 0.6875rem;
		line-height: 0.875rem;
	}

	.text-page__how-work .text-page__title-small {
		margin-bottom: 1.5rem;
	}

	.text-page__how-work ul {
		justify-content: flex-start;
		margin-bottom: 1rem;
		margin-left: -0.75rem;
		padding: 0 0.75rem;
		width: 100vw;
		overflow-x: auto;
	}

	.text-page__how-work ul li {
		flex-shrink: 0;
		margin-right: 0.75rem;
		width: 11rem;
	}

	.text-page__how-work ul li:last-child {
		margin-right: 0;
	}

	.text-page__how-work ul li .img {
		margin-bottom: 0.75rem;
		height: 12.875rem;
	}

	.text-page__how-work ul li .img img {
		border-radius: 0.5rem;
	}

	.text-page__how-work ul li .text {
		padding: 0 0.5rem;
	}

	.text-page__how-work ul li .text h6 {
		margin-bottom: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.text-page__how-work ul li .text p {
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.text-page__how-work .form-btns .btn {
		width: 100%;
		height: 2.5rem;
		font-size: 0.75rem;
	}
}