@media (max-width: 991px) {
.preloader__local {
	margin: 1.25rem auto;
	width: 7.125rem;
	height: 1.75rem;
	i {
		&::before {
			width: 7.125rem;
			height: 1.75rem;
		}
	}
}
section {
	margin-bottom: 1.5rem;
	overflow: hidden;
	&.mb {
		margin-bottom: 0.75rem;
	}
}
.banner {
	padding: 0.5rem;
	border-radius: 0.25rem;
}
.banner__img {
	border-radius: 0.25rem;
	img {
		border-radius: 0.25rem;
	}
}
.banner__title {
	font-size: 1.9375rem;
	line-height: 2.25rem;
	letter-spacing: -0.01em;
}
.banner__title-img {
	width: 100%;
}
.banner__medium-title {
	margin-bottom: 0;
	font-size: 1.5625rem;
	line-height: 2rem;
	letter-spacing: -0.01em;
}
.banner__small-title {
	margin-bottom: 0;
	font-size: 1.1875rem;
	line-height: 1.25rem;
	letter-spacing: 0;
}
.banner__text {
	margin-bottom: 0.5rem;
	font-size: 0.9375rem;
	line-height: 1.3125rem;
}
.page-title__back {
	height: 3.125rem;
	p {
		font-size: 4.375rem;
		letter-spacing: -0.01em;
		&.fz-big {
			font-size: 4.375rem;
		}
		&.fz-mid {
			font-size: 3.125rem;
		}
		&.fz-small {
			font-size: 1.75rem;
		}
	}
}
.page-title__front {
	height: 4rem;
	h2 {
		font-size: 1.5625rem;
		line-height: 2rem;
		letter-spacing: -0.01em;
	}
	p {
		margin: 0;
		font-size: 0.9375rem;
		line-height: 1.3125rem;
		text-align: center;
	}
}
.title {
	margin-bottom: 0.75rem;
	font-size: 1.1875rem;
	line-height: 1.25rem;
	letter-spacing: 0;
}
.site-title {
	margin: 0;
	font-size: 0.9375rem;
	line-height: 1.125rem;
	sup {
		left: 0;
		top: 0;
		font-size: 0.75rem;
		line-height: 1.2;
		letter-spacing: -0.0025em;
	}
}

}