@media (max-width: 991px) {
/* CATALOG START */
	.catalog { padding-top: 0; }

	.promo .text-page__list-block { margin-bottom: 1rem; }

	.catalog .card { margin-bottom: 0.75rem; }

	.catalog .card__img { height: 15.625rem; }

	.catalog .col-12 .card__img { height: 32rem; }

	.catalog__empty {
		height: auto;
		height: calc(100svh - 1.75rem - 2.5rem);
	}

	.catalog__empty h3 {
		margin-bottom: 1.25rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
		letter-spacing: 0;
	}

	.catalog__empty .btn { height: 2.5rem; font-size: 0.75rem; }

	.catalog .site-title__wrap {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: fixed;
		top: 4.875rem;
		left: 50%;
		padding: 0.125rem 2.1875rem 0 1.4375rem;
		width: auto;
		max-width: 15.9375rem;
		height: 2.25rem;
		overflow: hidden;
		transform: translate(-50%, 0);
		transition: 0.3s;
		z-index: 11;
	}

	.catalog .site-title__wrap.fixed { top: 0; transform: translate(-50%, -100%); }
	.catalog .site-title__wrap.fixed.hide { top: 4.875rem;  transform: translate(-50%, 0); }

	.catalog .site-title {
		display: inline;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.catalog .site-title sup { position: absolute; left: auto; right: 0.75rem; }

	.filters .filters__wrap {
		position: fixed;
		top: calc(1.75rem + 2.5rem);
		padding: 0.625rem 0.75rem;
		background: rgba(255, 255, 255, 0.85);
		border-radius: 0.5rem;
		backdrop-filter: blur(0.25rem);
		-webkit-backdrop-filter: blur(0.25rem);
		transition: 0.3s;
	}

	.filters.fixed .filters__wrap {
		top: calc(1.75rem + 2.5rem);
		transform: translate(0, -100%);
		opacity: 0;
		pointer-events: none;
	}

	.filters.fixed.hide .filters__wrap { transform: translate(0, 0); opacity: 1; pointer-events: auto; }

	.filters__list { flex-wrap: wrap; justify-content: space-between; background: rgba(0, 0, 0, 0); }

	.filters__list-first { margin-right: auto; }
	.filters__list-last { margin-left: auto; }

	.filters__list > div > a {
		justify-content: center;
		padding: 0;
		width: 2.25rem;
		height: 2.25rem;
		border-width: 0.0625rem;
	}

	.filters__list > div > a .numbers { padding: 0.1875rem 0 0; width: 1rem; height: 1rem; font-size: 0.5625rem; }

	.filters__list > div > a .ico { margin: 0; }

	.filters__list > li > .catalog__title {
		position: relative;
		justify-content: flex-start;
		width: 15.9375rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		text-align: left;
		border: 0;
		border-radius: 0;
	}

	.filters__list > li > .catalog__title span {
		display: block;
		position: relative;
		padding-right: 2.1875rem;
		padding-left: 1.4375rem;
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: left;
		overflow: hidden;
	}

	.filters__list > li > .catalog__title sup {
		position: absolute;
		top: 0.5625rem;
		right: 0.75rem;
		font-size: 0.75rem;
	}

	.filters__list .filters__activities {
		order: 3;
		position: relative;
		bottom: 0;
		left: 0;
		margin: 0.75rem 0 0;
		overflow-x: auto;
	}

	.filters__activities .filters__active { margin-right: 0.25rem; }

	.filters__activities .filters__active:last-child { margin-right: 0; }

	.filters__activities .filters__active a {
		padding: 0 0.6875rem;
		height: 2rem;
		font-size: 0.6875rem;
		line-height: 0.8125rem;
		border-width: 0.0625rem;
	}

	/*.filters__activities > li > a .ico { margin-left: 0.125rem; width: 1rem; height: 1rem; }*/

	.filters__collapse {
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		position: absolute;
		bottom: -0.5rem;
		left: 0;
		padding: 0.5rem;
		background: #fff;
		border-radius: 0.5rem;
		transform: translate(0, 100%);
		z-index: 2;
	}

	.filters__collapse-wrap {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		height: 100%;
	}

	.filters__collapse-wrap > .scrollbar-inner { height: 100%; }

	.filters__collapse-title {
		padding: 0.625rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.filters__collapse-link {
		padding: 0.625rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		border-radius: 0.25rem;
	}

	.filters__collapse-link::after { width: 1.25rem; height: 1.25rem; }

	.filters__collapse .filters__collapse-container {
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		flex-wrap: wrap;
		margin-left: 0;
		width: 0;
		height: 100%;
		transition: 0.3s;
		overflow: hidden;
	}

	.filters__collapse-checkboxes { margin-bottom: auto; }

	.filters__collapse-checkboxes li .form-checkbox { padding: 0.625rem 0.5rem; }

	.filters__collapse-checkboxes li .form-radio { padding: 0.625rem 0.5rem; }

	.filters__collapse .btn { margin-top: 1rem; width: 100%; font-size: 0.875rem; line-height: 1.25rem; }

	.range-inputs { margin-bottom: 1.375rem; }

	.range-inputs-group { width: calc(50% - (0.5rem / 2)); height: 2.5rem; }

	.range-inputs-group input {
		padding: 0 1.75rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
		border-width: 0.0625rem;
		border-radius: 0.5rem;
	}

	.range-inputs-group p { left: 0.75rem; font-size: 0.8125rem; line-height: 1.125rem; }

	.range-slider { height: 0.25rem; }

	.range-slider .irs-handle { width: 1rem; height: 1rem; }

	.catalog .banner { margin-bottom: 0.75rem; height: 21.875rem; }

	.catalog__foot.mt { margin-top: 1.5rem; }

	.catalog__foot .btn { width: 100%; }

	.paginator { margin-top: 0.75rem; }

	.paginator__arrow { width: 1.25rem; height: 1.25rem; }

	.paginator ul { padding: 0 0.125rem; }
	.paginator ul li { margin: 0.125rem; }

	.paginator ul li a {
		width: 2rem;
		height: 2rem;
		font-size: 1rem;
		letter-spacing: 0;
		border-width: 0.0625rem;
		border-radius: 0.25rem;
	}

	.catalog__tags { padding: 1rem; border-radius: 0.5rem; }

	.catalog__tags-list { margin: -0.125rem; }

	.catalog__tags-col { margin: -0.125rem; padding: 0.125rem 0; }

	.catalog__tags-col .catalog__tags-list { margin: 0; }

	.catalog__tags-list li { padding: 0.125rem; }

	.catalog__tags-list a {
		padding: 0 0.6875rem;
		height: 2rem;
		font-size: 0.6875rem;
		line-height: 0.8125rem;
		border-width: 0.0625rem;
	}

	.catalog__tags .catalog__tags-link {
		margin: 0.5rem 0 0 0.25rem;
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.catalog__tags .information__link { margin-top: 1.75rem; }
/* CATALOG END */

/* CATALOG-all-filters START */
	.all-filters {
		padding: 4.25rem 0.75rem 7rem;
		width: 100%;
		height: 100%;
		border-radius: 0;
		z-index: 100;
		overflow: hidden;
	}

	.all-filters .head { top: 1.75rem; padding: 0 0.75rem; height: 2.5rem; }

	.all-filters .head h5 { font-size: 1.0625rem; line-height: 1.375rem; }

	.all-filters .head .close { width: 1.5rem; height: 1.5rem; }

	.all-filters .body > .scrollbar-inner { height: 100%; }

	.all-filters .body > .scrollbar-inner .scrollbar-inner { padding: 0.75rem 0; }

	.all-filters .body > .scrollbar-inner>.scroll-element.scroll-y { display: none; }

	.all-filters .all-filters__height > .scrollbar-inner .scrollbar-inner { padding: 0; }

	.all-filters-block { margin-bottom: 0.5rem; border-radius: 0.5rem; }

	.all-filters__accord { padding: 0.5rem; }

	.all-filters__title { font-size: 0.9375rem; line-height: 1.125rem; letter-spacing: 0; }

	.all-filters__link { padding: 0.625rem 0.5rem; height: 2.5rem; }

	.all-filters__link .ico { transform: rotate(0deg); }
	.all-filters__link.active .ico { transform: rotate(-180deg); }

	/*.all-filters__col {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #F8F5F1;
		transform: translate(100%, 0);
		transition: 0.3s;
		z-index: 2;
	}*/

	/*.all-filters__col.active { transform: translate(0, 0); }*/

	.all-filters__head {
		display: flex !important;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		padding: 0.5rem 0.75rem;
		width: 100%;
		background: #F8F5F1;
	}

	.all-filters__head .ico { flex-shrink: 0; }

	.all-filters__head h4 {
		margin: 0;
		padding: 0 0.5rem;
		width: 100%;
		font-size: 1.0625rem;
		line-height: 1.375rem;
	}

	.all-filters__height {
		/*margin: 0.75rem auto 0;*/
		/*padding: 0.5rem;*/
		/*width: calc(100% - (0.75rem * 2));*/
		width: 100%;
		/*height: 18.5rem;*/
		height: auto;
		background: #fff;
		border-radius: 0.5rem;
	}

	.all-filters-block--size .all-filters__height{ height: auto; }
	.all-filters-block--other .all-filters__height{ height: auto; }

	.all-filters__height > .scrollbar-inner { height: 100%; }

	.all-filters__height h5 {
		margin: 0;
		padding: 0.625rem 0.5rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
	}

	.all-filters-block .form-checkbox { padding: 0.625rem 0.5rem; }

	.all-filters-price { padding: 0.75rem 0.75rem 0.875rem; }

	.all-filters-price .all-filters__title { margin-bottom: 0.5rem; }

	.all-filters-sizes { margin: -0.125rem 0; padding: 0 0.375rem; }

	.all-filters-sizes li { margin: 0.125rem; }

	.all-filters-sizes .form-checkbox { padding: 0; }

	.all-filters-sizes .form-checkbox .label-dot {
		margin: 0;
		width: 2.25rem;
		height: 2.25rem;
		font-size: 0.875rem;
		line-height: 1;
		border: 0.0625rem solid #DCD6D1;
		border-radius: 0.5rem;
	}

	.all-filters-sizes .form-checkbox input:checked + .label-dot { color: #fff; background: var(--c-main); border-color: var(--c-main); }

	.all-filters-sizes .form-checkbox .label-dot::after { display: none; }

	.all-filters__activated { margin-bottom: 0; }

	.all-filters__activated > li { margin-bottom: 0.5rem; margin-right: 0.5rem; }

	.all-filters__activated > li > a {
		padding: 0 0.625rem;
		height: 1.75rem;
		font-size: 0.75rem;
		line-height: 1rem;
		border-width: 0.0625rem;
	}

	.all-filters__activated > li > a .ico { margin-left: 0.125rem; width: 1rem; height: 1rem; }

	.all-filters__activated > li > a .color { width: 0.75rem; height: 0.75rem; }

	.all-filters .btns { bottom: 2.5rem; padding: 0 0.75rem; height: 4rem; }
	.all-filters .btns .btn { padding: 0.75rem 0; width: calc(50% - (0.5rem / 2)); }
/* CATALOG-all-filters END */

/* CATALOG-COMMON START */
	/*.catalog__list { padding-top: 5.875rem; }*/

	.filter--choosed { left: 50%; height: 70vh; transform: translate(-50%, 100%); }
	.filter--category { height: 20rem; }
	.filter--price { flex-direction: column; }
	.filter--sort { left: auto; right: 0; height: 13.5rem; }

	.filter--choosed .filters__collapse-wrap { width: 21.875rem; }
	.filter--category .filters__collapse-wrap { width: 16rem; }
	.filter--price .filters__collapse-wrap { width: 16rem; }
	.filter--sort .filters__collapse-wrap { width: 20.9375rem; }


	.filter--choosed .filters__collapse-container.out,
	.filter--choosed .filters__collapse-container.inn {
		position: absolute;
		top: 0;
		right: 0;
		background: #fff;
		transform: translate(100%, 0);
	}

	.filter--choosed .filters__collapse-container.out.active { right: 50%; margin: 0; width: 21.875rem; transform: translate(50%, 0); }
	.filter--choosed .filters__collapse-container.out.active.active-inn { width: 21.875rem; }
	.filter--choosed .active-inn .filters__collapse-container.inn { right: 50%; margin: 0; width: 21.875rem; transform: translate(50%, 0); }

	.filter--choosed .filters__collapse-container.out .filters__collapse-title .back {

	}
/* CATALOG-COMMON END */
}