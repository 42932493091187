@media (max-width: 991px) {
	.advantages-list .block {
		margin-right: 0.5rem;
		padding: 0.625rem;
		width: 14.75rem;
		height: 3.875rem;
		border-width: 0.0625rem;
		border-radius: 0.5rem;
	}

	.advantages-list .block:last-child {
		margin-right: 0;
	}

	.advantages-list .block .circle {
		margin-right: 0.75rem;
		width: 2.5rem;
		height: 2.5rem;
	}

	.advantages-list .block .circle .ico {
		width: 1.5rem;
		height: 1.5rem;
	}

	.advantages-list .block p {
		padding-top: 0.3125rem;
		font-size: 0.9375rem;
		line-height: 1.3125rem;
	}
}