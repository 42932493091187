.section-who__wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 0;
	color: #fff;
	background: var(--c-main);
	border-radius: 0.75rem;
}

.section-who__wrap h4 {
	margin: 0;
	color: #fff;
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.01em;
}

.section-who__wrap .nav-tabs {
	display: flex;
	align-items: center;
	margin-left: 2.5rem;
	padding: 0.25rem;
	background: #fff;
	border: 0;
	border-radius: 0.5rem;
}

.section-who__wrap .nav-tabs li:not(:last-child) {
	margin-right: 0.25rem;
}

.section-who__wrap .nav-tabs li .nav-link {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 1.125rem 1.75rem;
	color: var(--c-main);
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
	border: 0;
	border-radius: 0.5rem;
	box-shadow: none;
	outline: 0;
	z-index: 1;
}

.section-who__wrap .nav-tabs li button.active {
	color: #fff;
	background: var(--c-main);
}