@media (max-width: 991px) {
	.footer__wrap {
		margin-left: -0.75rem;
		padding: 0.75rem;
		width: 100vw;
		border-radius: 0;
	}

	.footer__shop {
		padding: 0.75rem;
		height: auto;
		border-radius: 0.25rem;
	}

	.footer__shop h5 {
		margin-bottom: 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.footer__shop .btns-app ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.footer__shop .btns-app ul li .btn-shop {
		display: block;
	}

	.footer__shop .btns-app ul li .btn-shop img {
		width: auto;
		height: 3rem;
	}

	.footer__menu {
		margin-bottom: 0.5rem;
		padding-top: 0;
	}

	.footer__menu h3 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0;
		padding: 0.5rem 0;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.005em;
	}

	.footer__menu h3::after {
		content: '';
		width: 1.25rem;
		height: 1.25rem;
		background: url("../../img/svg/mini-arrow-bot-black.svg") no-repeat 50%/contain;
		transition: 0.3s;
	}

	.footer__menu .show h3::after {
		transform: rotate(180deg);
	}

	.footer__menu ul li a {
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0;
	}

	.footer__data {
		margin-bottom: 0.5rem;
		padding: 0.75rem 0;
		border-top-width: 0.0625rem;
		border-bottom-width: 0.0625rem;
	}

	.footer__data h4 {
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.footer__data .phone {
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: -0.05em;
	}

	.footer__btns-list {
		justify-content: space-between;
		padding: 0.75rem 0;
		border-bottom: 0.0625rem solid #DCD6D1;
	}

	.footer__btns-list p {
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.footer__btns-list ul li img {
		height: 1.5rem;
	}

	.footer__btns .row > :last-child .footer__btns-list {
		border-bottom: 0;
	}

	.footer__btns-list ul li,
  .footer__btns-list ul li:not(:last-child) {
		margin: 0 0 0 0.5rem;
		border-radius: 0.25rem;
	}

	.footer__bot {
		height: 2.5rem;
	}

	.footer__bot p {
		font-size: 0.75rem;
		line-height: 1rem;
	}
}