.centeredSlider {
	position: absolute;
	width: 100%;
	left: 0;
	overflow: hidden;
	@media screen and (max-width: 768px) {
		position: relative;

		}
}

.sliderBanner{
		position: relative;
			margin: 0;
			max-height: 510px;
			@media screen and (max-width: 768px) {
			max-height: unset;
				}
}

.sliderSpace{
	padding-top: 510px;
	@media screen and (max-width: 768px) {
		display: none;
		padding-top: 0;
		}
}