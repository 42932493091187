.line-page section {
	position: relative;
}

.line-page .row:last-child .line-page__text :last-child {
	margin-bottom: 0;
}

.line-page__kv-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 1.75rem;
	width: 100%;
	height: 60.25rem;
}

.line-page__kv-block p {
	margin: 0;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
}

.line-page__kv-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.line-page__kv-img img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.line-page__kv-title {
	width: 40rem;
}

.line-page__container.center .row {
	align-items: center;
}

.line-page__text {
	position: relative;
}

.line-page__text h1 {
	margin: 0;
	color: #F5FF78;
	font-size: 15.25rem;
	line-height: 1;
	letter-spacing: -0.02em;
}

.line-page__text h3 {
	margin-bottom: 1.25rem;
	color: #000;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
}

.line-page__text p {
	margin-bottom: 1.25rem;
	color: #131313;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.line-page__img {
	display: block;
	width: 100%;
}

.line-page__img img {
	display: block;
	width: 100%;
	height: auto;
	border-radius: 0.5rem;
}

.line-page__title {
	position: relative;
	margin: 0;
	height: 0;
	z-index: -1;
}

.line-page__title img {
	display: block;
	position: relative;
	bottom: 1.625rem;
	width: 100%;
	transform: translate(0, -50%);
}

.line-page__absolute {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: none;
}

.line-page__absolute .row div * {
	pointer-events: auto;
}

.line-page__model {
	padding-bottom: 3.875rem;
}

.line-page__model-bg {
	position: absolute;
	top: -0.75rem;
	left: 50%;
	width: 100%;
	max-width: 108.125rem;
	transform: translate(-50%, 0);
	z-index: -1;
}

.line-page__model-img {
	position: absolute;
	top: 7.75rem;
	right: 2.625rem;
	width: 37.3125rem;
	z-index: -1;
}

.line-page__model--top {
	padding-top: 8.75rem;
}

.line-page__model--mid {
	justify-content: center;
}

.line-page__model--bot {
	justify-content: flex-end;
}

.line-page__advantage-block {
	position: relative;
}

.line-page__advantage-block picture {
	display: block;
	width: 100%;
	height: auto;
}

.line-page__advantage-block img {
	display: block;
	width: 100%;
	height: auto;
	border-radius: 0.5rem 0.5rem 0 0;
}

.line-page__advantage-block .line-page__text {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 1.75rem 1rem;
	width: 100%;
	background: #F5FF78;
	border-radius: 0 0 0.5rem 0.5rem;
	z-index: 1;
}

.line-page__text-title {
	display: block;
	position: relative;
	top: 0;
	left: -15.9375rem;
}

.line-page__choose-block {
	position: relative;
	width: 100%;
	height: 57.75rem;
}

.line-page__choose-block picture {
	display: block;
	width: 100%;
	height: 100%;
	transition: 0.3s;
}

.line-page__choose-block picture img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.line-page__choose-block .btn {
	position: absolute;
	top: 1.25rem;
	right: 1.25rem;
	padding: 0;
	width: 11.375rem;
	height: 3.5rem;
	border: 0;
}

.line-page__choose-block .hint {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.line-page__choose-block .hint__dot {
	display: block;
	position: absolute;
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
	background: rgba(255, 255, 255, 0.4);
	border-radius: 50%;
	pointer-events: auto;
}

.line-page__choose-block .hint__dot::before {
	opacity: 0;
}

.line-page__choose-block .hint__dot::after {
	width: 0.75rem;
	height: 0.75rem;
}

.line-page__choose-block .hint-1 .hint__dot {
	top: 35%;
	left: 60%;
}

.line-page__choose-block .hint-2 .hint__dot {
	top: 57%;
	left: 42%;
}

.line-page__choose-block .hint-3 .hint__dot {
	top: 69%;
	left: 61%;
}

.line-page__choose-block .card {
	position: absolute;
	padding: 0.5rem;
	width: 11.25rem;
	background: #fff;
	border-radius: 0.5rem;
	opacity: 0;
	pointer-events: none;
	transition: 0.3s;
}

.line-page__choose-block .card.active {
	opacity: 1;
	pointer-events: auto;
}

.line-page__choose-block .card .bot {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 0.25rem;
	margin-bottom: -2.25rem;
	transition: 0.3s;
}

.line-page__choose-block .card .bot .card__bag {
	display: block;
	width: 2.25rem;
	height: 2.25rem;
	background: var(--c-main) url("../../img/svg/bag-2.svg") no-repeat 50%/1rem;
	border-radius: 0.5rem;
}

.line-page__choose-block .card-1 {
	top: 7.625rem;
	right: 1.25rem;
}

.line-page__choose-block .card-2 {
	top: 7.625rem;
	left: 1.25rem;
}

.line-page__choose-block .card-3 {
	top: 30.625rem;
	right: 1.25rem;
}

.line-page__choose-block .card__img {
	height: 15rem;
}

.line-page__choose-block .card__img picture img {
	border-radius: 0.25rem;
}

.line-page__choose-block .card__info {
	overflow: hidden;
}

.line-page__choose-block .card__text {
	margin-bottom: 0.25rem;
	height: 1.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
	white-space: nowrap;
}

.line-page__choose-block .card__price {
	margin: 0;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.line-page__choose-block .card__img-slider-dots li {
	margin-right: 0.25rem;
}

.line-page__choose-block .card__basket {
	flex-direction: row;
	margin-bottom: 0;
	padding: 0;
	width: auto;
	height: auto;
	background: transparent;
}

.line-page__choose-block .card__basket ul li {
	display: none;
}

.line-page__choose-block .card__basket ul li.active {
	display: block;
}

.line-page__choose-block .card__basket ul li a {
	border: 0.0625rem solid #DCD6D1;
}

.line-page__choose-block .card__basket .sizes-swipe {
	display: block;
	width: 1.5rem;
	height: 1.5rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	cursor: pointer;
}

.line-page__choose-block .card__basket .sizes-swipe--prev {
	margin-right: 0.25rem;
	background-image: url("../../img/svg/chevron-left-black.svg");
}

.line-page__choose-block .card__basket .sizes-swipe--next {
	margin-left: 0.25rem;
	background-image: url("../../img/svg/chevron-right-black.svg");
}

.line-page__choose-block .hint__dot::before,
.line-page__choose-block .hint__dot::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	background: #fff;
	border-radius: 50%;
	transform: translate(-50%, -50%);
}

.line-page__choose .line-page__absolute {
	justify-content: flex-end;
	padding-bottom: 1.5rem;
}