@media (max-width: 991px) {
	.stocks__title {
		margin-bottom: 0.75rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
	}

	.stocks__tabs {
		display: block;
		margin-bottom: 1.25rem;
		margin-left: -0.75rem;
		padding: 0 0.75rem;
		width: 100vw;
	}

	.stocks .nav-tabs {
		flex-shrink: 0;
		flex-wrap: nowrap;
		border-bottom-width: 0.0625rem;
		width: 100%;
		overflow-x: auto;
	}

	.stocks .nav-tabs li {
		margin-right: 1.5rem;
	}

	.stocks .nav-tabs li:last-child {
		margin-right: 0;
	}

	.stocks .nav-tabs li .nav-link {
		padding: 0.25rem 0;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
		white-space: nowrap;
	}

	.stocks .nav-tabs li .nav-link::after {
		height: 0.125rem;
	}

	.stocks__list {
		margin-bottom: -0.75rem;
	}

	.stocks__block {
		margin-bottom: 0.75rem;
		padding: 0.75rem;
		height: 8.375rem;
		border-radius: 0.5rem;
	}

	.stocks__block::before {
		border-radius: 0.5rem;
	}

	.stocks__block h3 {
		margin-bottom: 0.25rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
		letter-spacing: 0;
	}

	.stocks__block p {
		margin-bottom: 0.75rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.stocks__block picture,
  .stocks__block picture img {
		border-radius: 0.5rem;
	}
}