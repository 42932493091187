.basket__row {
	display: flex;
	align-items: flex-start;
	position: relative;
}

.basket__products {
	margin-bottom: 1.5rem;
}

.basket__products .top {
	display: flex;
	align-items: center;
	padding: 1rem 1.5rem;
	border-bottom: 0.0625rem solid #DCD6D1;
}

.basket__products .top .basket-all-checks {
	margin-right: 1.5rem;
}

.basket__products .top .ico {
	width: 1.5rem;
	height: 1.5rem;
}

.basket__products-block {
	display: flex;
	align-items: center;
	padding: 1.5rem;
}

.basket__products-block .form-checkbox {
	flex-shrink: 0;
	margin-right: 0.75rem;
}

.basket__products-block .form-checkbox .label-dot {
	margin: 0;
}

.basket__products-block .img {
	display: block;
	flex-shrink: 0;
	margin-right: 0.75rem;
	width: 8.25rem;
	height: 12.375rem;
	border-radius: 0.5rem;
}

.basket__products-block .img img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.5rem;
}

.basket__products-block .info {
	padding: 1rem 0;
	width: 100%;
}

.basket__products-block .info .text {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.375rem;
}

.basket__products-block .info .text .left {
	padding-right: 1rem;
}

.basket__products-block .info .text .left h4 {
	margin-bottom: 0.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.basket__products-block .info .text .left ul {
	display: flex;
	margin-bottom: 0.5rem;
}

.basket__products-block .info .text .left ul li {
	margin-right: 0.75rem;
	color: #B3A8A1;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0002em;
}

.basket__products-block .info .text .left p {
	margin: 0;
	color: #FF4E44;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0002em;
}

.basket__products-block .info .text .right {
	flex-shrink: 0;
	padding-left: 1.5rem;
}

.basket__products-block .info .text .right p {
	margin-bottom: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.basket__products-block .info .text .right p:first-child {
	margin-bottom: 0.5rem;
}

.basket__products-block .info .text .right p.discount {
	color: #FF4E44;
}

.basket__products-block .info .text .right p.old {
	color: #B3A8A1;
	text-decoration: line-through;
}

.basket__products-block .info .meta {
	display: flex;
	justify-content: space-between;
}

.basket__products-block .info .meta .left {
	display: flex;
}

.basket__products-block .info .meta .left a {
	margin-right: 0.75rem;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.basket__form {
	margin-bottom: 1.5rem;
	padding: 1.5rem;
}

.basket__form:last-child {
	margin-bottom: 0;
}

.basket__form .tab-content {
	margin-bottom: 1.5rem;
}

.basket__form form .form-annotation {
	margin: 0;
}

.basket__form form .form-selector {
	max-width: 100%;
}

.basket__form .nav-tabs {
	display: inline-flex;
	margin-bottom: 1.5rem;
	padding: 0.125rem;
	border: 0;
	background: #F8F5F1;
	border-radius: 0.5rem;
}

.basket__form .nav-tabs li:not(:last-child) {
	margin-right: 0.125rem;
}

.basket__form .nav-tabs .nav-link {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	border: 0;
	width: 12.875rem;
	height: 3.5rem;
	color: var(--c-main);
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: center;
	background: transparent;
	border: 0;
	border-radius: 0.5rem;
}

.basket__form .nav-tabs .nav-link.active {
	color: #fff;
	background: var(--c-main);
}

.basket__form-title {
	margin-bottom: 1.5rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.basket__date-time ul {
	display: flex;
	margin-bottom: 1.5rem;
}

.basket__date-time ul:last-child {
	margin-bottom: 0;
}

.basket__date-time ul li {
	margin-right: 0.75rem;
}

.basket__date-time ul li a {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0.9375rem;
	width: 9.375rem;
	height: 5.5rem;
	text-align: center;
}

.basket__date-time ul li a p {
	margin-bottom: 0.25rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.basket__date-time ul li a small {
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.basket__date-time ul li a.active {
	color: #fff;
	background: var(--c-main);
}

.punkt-vidachi {
	padding: 1.5rem 0 0;
}

.punkt-vidachi h6 {
	margin-bottom: 0.75rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.punkt-vidachi p {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.punkt-vidachi ul {
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
}

.punkt-vidachi ul li {
	align-items: flex-start;
	margin-bottom: 0.75rem;
}

.punkt-vidachi ul li:last-child {
	margin-bottom: 0;
}

.punkt-vidachi ul li .ico {
	flex-shrink: 0;
	margin-top: -0.25rem;
	width: 1.5rem;
	height: 1.5rem;
}

.punkt-vidachi .btn {
	margin-top: 1.5rem;
}

.basket__aside {
	position: sticky;
	top: 6.875rem;
	right: 0;
}

.basket__promocode {
//	margin-bottom: 1.5rem;
}

.basket__promocode .form-control {
	height: 3.5rem;
}

.basket__promocode .form-group .btn {
	position: absolute;
	top: 50%;
	right: 0.25rem;
	padding: 0;
	width: 3rem;
	height: 3rem;
	transform: translate(0, -50%);
}

.basket__order {
	padding: 1.5rem;
	background: #F8F5F1;
	border-radius: 0.5rem;
}

.basket__order ul li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.basket__order ul li span {
	color: #FF4E44;
}

.basket__order ul * {
	margin: 0;
}

.basket__order .form-btns {
	margin-bottom: 0.75rem;
}

.basket__order-all {
	padding: 0.375rem 0 0.875rem;
	border-bottom: 0.0625rem solid #DCD6D1;
}

.basket__order-all p {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.basket__order-discount {
	margin-bottom: 0.75rem;
}

.basket__order-discount h6 {
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.basket__order-discount a {
	color: #B3A8A1;
	font-size: 0.75rem;
	line-height: 1rem;
	letter-spacing: 0.0005em;
	text-decoration: underline;
}

.basket__order-discount li {
	margin-top: 0.5rem;
}

.basket__order-discount p {
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0002em;
}

.basket__order-delivery {
	margin-bottom: 1.5rem;
	padding: 0.375rem 0;
}

.basket__order-delivery p {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.basket__order-total {
	margin-bottom: 1.25rem;
}

.basket__order-total li:not(:last-child) {
	margin-bottom: 0.25rem;
}

.basket__order-total h6 {
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.001em;
}

.basket__order-total p {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.basket__order-iframe {
	position: relative;
	margin-bottom: 1.25rem;
	width: 100%;
}

.basket__order-iframe img,
.basket__order-iframe iframe {
	display: block;
	width: 100%;
	height: 11.4375rem;
	object-fit: cover;
	border-radius: 0.5rem;
}

.basket__order-annotation p {
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.basket__order-annotation p a {
	text-decoration: underline;
}

.order__confirmed-top {
	margin-bottom: 1.5rem;
}

.order__confirmed-top div {
	margin-bottom: 1.25rem;
}

.order__confirmed-top div h6 {
	margin-bottom: 0.25rem;
}

.order__confirmed-top ul {
	display: flex;
	align-items: center;
}

.order__confirmed-top ul li {
	display: flex;
	align-items: center;
	margin-right: 1rem;
}

.order__confirmed-top ul li > * {
	margin: 0;
}

.order__confirmed-top h6 {
	margin-bottom: 0.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.order__confirmed-top p {
	margin-bottom: 0.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.order__confirmed-top small {
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0002em;
}

.order__confirmed-products {
	margin-bottom: 1.5rem;
}

.order__confirmed-products .basket__products-block {
	margin-bottom: 0.75rem;
}

.order__confirmed-products .basket__products-block:last-child {
	margin-bottom: 0;
}

.order__confirmed-products .basket__products-block .img {
	width: 4.75rem;
	height: 7.25rem;
}

.order__confirmed-bonuses {
	margin-bottom: 3.125rem;
	padding: 1.5rem;
	background: #F8F5F1;
	border-radius: 0.5rem;
}

.order__confirmed-bonuses h4 {
	margin-bottom: 0.5rem;
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.001em;
}

.order__confirmed-bonuses p {
	margin-bottom: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.order__confirmed-btns {
	justify-content: space-between;
	margin: 0;
	padding: 1.5rem 0;
}

.order__confirmed-btns .btn {
	width: calc(50% - (1.5rem / 2));
}