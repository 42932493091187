@media (max-width: 991px) {

	.main-look {
		.site-title {
			display: block;
			margin-bottom: 0.75rem;
		}
	}
	.page-look__title {
		display: flex;
		justify-content: center;
		margin-bottom: 0.5rem;
		overflow: hidden;
		h1 {
			font-size: 4.375rem;
			line-height: 3.0625rem;
			letter-spacing: -0.001em;
			white-space: nowrap;
		}
		h2 {
			font-size: 1.5625rem;
			line-height: 2rem;
		}
	}
	.choose__slider-look {
		.swiper-slide {
			margin: 0 0.75rem;
			width: 16.75rem;
			height: 29.25rem;
		}
		.swiper-slide-active {
			width: 16.75rem;
			.swiper-slide-cont {
				margin-left: 0;
				width: 100%;
				width: 16.75rem;
			}
			.slide__wrap {
				.btn {
					.ico {
						left: 1.125rem;
					}
				}
			}
		}
		.swiper-slide-cont {
			height: 27rem;
		}
		.swiper-slide-prev--prev {
			transform: translate(0, 0);
		}
		.swiper-slide-prev {
			transform: translate(0, 0);
		}
		.swiper-slide-next {
			transform: translate(0, 0);
		}
		.swiper-slide-next--next {
			transform: translate(0, 0);
		}
		.slide__wrap {
			width: 21.875rem;
			height: 27rem;
			picture {
				img {
					border-radius: 0.5rem;
				}
			}
			.btn {
				top: 0.5rem;
				right: 3.5rem;
				width: 2.25rem;
				height: 2.25rem;
				.ico {
					margin: 0;
					width: 1rem;
					height: 1rem;
				}
				span {
					left: 2.25rem;
					font-size: 0.6875rem;
					line-height: 0.875rem;
				}
			}
			.choose__slide-name {
				bottom: 1rem;
				font-size: 0.9375rem;
				line-height: 1.75rem;
				letter-spacing: 0;
			}
			.hint-1 {
				.hint__dot {
					top: 8rem;
					left: 13rem;
				}
				.card {
					top: 0.2rem;
					right: 0.5rem;
				}
			}
			.hint-2 {
				.hint__dot {
					top: 12rem;
					left: 8rem;
				}
				.card {
					top: 0.2rem;
					left: 0.5rem;
				}
			}
			.hint-3 {
				.hint__dot {
					top: 20rem;
					left: 14rem;
				}
				.card {
					top: 14.75rem;
					right: 0.5rem;
				}
			}
			.hint-4 {
				.hint__dot {
					top: 22rem;
					left: 7rem;
				}
				.card {
					top: 14.75rem;
					left: 0.5rem;
				}
			}
			.hint__dot {
				width: 1rem;
				height: 1rem;
				&::before {
					width: 300%;
					height: 300%;
				}
				&::after {
					width: 0.5rem;
					height: 0.5rem;
				}
			}
			.card__img {
				.top {
					display: none;
				}
			}
			.card__text {
				margin-bottom: 0.125rem;
				height: 2rem;
				font-size: 0.75rem;
				line-height: 1rem;
				white-space: normal;
			}
			.card__price {
				font-size: 0.6875rem;
				line-height: 0.875rem;
			}
		}
		.card {
			padding: 0.25rem;
			width: 7.75rem;
			border-radius: 0.5rem;
			&:hover {
				border-radius: 0.5rem;
				.card__img {
					picture {
						img {
							border-radius: 0.25rem;
						}
					}
				}
			}
			.bot {
				display: none;
			}
		}

		.card__img {
			height: 7.5rem;
			picture {
				img {
					border-radius: 0.25rem;
				}
			}
		}
		.card__img-slider-dots {
			li {
				margin-right: 0.25rem;
			}
		}
	}
	.choose.scale {
		.choose__slider-look {
			.swiper-slide-active {
				width: 21.875rem;
				.swiper-slide-cont {
					margin-left: -2.5rem;
					width: 100%;
					width: 21.875rem;
				}
				.slide__wrap {
					.btn {
						right: 0.5rem;
						width: 7.25rem;
					}
				}
			}
			.swiper-slide-prev--prev {
				transform: translate(0, 0);
			}
			.swiper-slide-prev {
				transform: translate(-2.5rem, 0);
			}
			.swiper-slide-next {
				transform: translate(2.5rem, 0);
			}
			.swiper-slide-next--next {
				transform: translate(0, 0);
			}
			.slide__wrap {
				.btn {
					right: 1.25rem;
				}
			}
		}
	}
	.page-look__img {
		margin: 0 auto;
		width: 12.5rem;
		height: 27rem;
		.swiper-slide-cont {
			height: 27rem;
		}
		.slide__wrap {
			width: 21.875rem;
			height: 27rem;
			picture {
				img {
					border-radius: 0.5rem;
				}
			}
			.btn {
				top: 0.5rem;
				right: 5.25rem;
				width: 2.25rem;
				height: 2.25rem;
				.ico {
					margin: 0;
					width: 1rem;
					height: 1rem;
				}
				span {
					left: 2.25rem;
					font-size: 0.6875rem;
					line-height: 0.875rem;
				}
			}
			.choose__slide-name {
				bottom: 1rem;
				font-size: 0.9375rem;
				line-height: 1.75rem;
				letter-spacing: 0;
			}
			.hint-1 {
				.hint__dot {
					top: 8rem;
					left: 13rem;
				}
				.card {
					top: 0.2rem;
					right: 0.5rem;
				}
			}
			.hint-2 {
				.hint__dot {
					top: 12rem;
					left: 8rem;
				}
				.card {
					top: 0.2rem;
					left: 0.5rem;
				}
			}
			.hint-3 {
				.hint__dot {
					top: 20rem;
					left: 14rem;
				}
				.card {
					top: 14.75rem;
					right: 0.5rem;
				}
			}
			.hint-4 {
				.hint__dot {
					top: 22rem;
					left: 7rem;
				}
				.card {
					top: 14.75rem;
					left: 0.5rem;
				}
			}
			.hint__dot {
				width: 1rem;
				height: 1rem;
				&::before {
					width: 300%;
					height: 300%;
				}
				&::after {
					width: 0.5rem;
					height: 0.5rem;
				}
			}
			.card__img {
				.top {
					display: none;
				}
			}
			.card__text {
				margin-bottom: 0.125rem;
				height: 2rem;
				font-size: 0.75rem;
				line-height: 1rem;
				white-space: normal;
			}
			.card__price {
				font-size: 0.6875rem;
				line-height: 0.875rem;
			}
		}
		.swiper-slide-active {
			.slide__wrap {
				.btn {
					.ico {
						left: 1.125rem;
					}
				}
			}
		}
		.card {
			padding: 0.25rem;
			width: 7.75rem;
			border-radius: 0.5rem;
			&:hover {
				border-radius: 0.5rem;
				.card__img {
					picture {
						img {
							border-radius: 0.25rem;
						}
					}
				}
			}
			.bot {
				display: none;
			}
		}
		.card__img {
			height: 7.5rem;
			picture {
				img {
					border-radius: 0.25rem;
				}
			}
		}
		.card__img-slider-dots {
			li {
				margin-right: 0.25rem;
			}
		}
	}
	.page-look__img.active-inst {
		.slide__wrap {
			.btn {
				right: 0.5rem;
				width: 7.25rem;
				.ico {
					left: 0.75rem;
					transform: translate(0, -50%);
				}
			}
		}
	}
	.page-look__img.scale {
		.slide__wrap {
			.btn {
				right: 0.5rem;
				width: 7.25rem;
				.ico {
					left: 0.75rem;
					transform: translate(0, -50%);
				}
			}
		}
		.swiper-slide-active {
			.slide__wrap {
				.btn {
					right: 0.5rem;
					width: 7.25rem;
				}
			}
		}
	}
	.page-look__list {
		margin-bottom: 1.5rem;
	}
	.page-look__wrap {
		margin-bottom: 1.5rem;
		&:last-child {
			margin-bottom: 0;
		}
		.order--1 {
			order: 1;
			margin-bottom: 0.75rem;
		}
		.order--2 {
			order: 2;
		}
	}
	.page-look__wrap.align-center {
		align-items: flex-start;
	}
	.page-look__wrap.align-start {
		align-items: flex-start;
		.page-look__text {
			padding-top: 0;
		}
		.page-look__text-title {
			margin-bottom: 0;
		}
	}
	.page-look__wrap.align-end {
		align-items: flex-start;
		margin-top: 0;
		.page-look__text {
			padding-bottom: 0;
		}
		.page-look__text-title {
			margin-bottom: 0;
		}
	}
	.page-look__text-title {
		font-size: 4.375rem;
		line-height: 3.0625rem;
	}
	.page-look__text {
		p {
			max-width: 100%;
			font-size: 0.875rem;
			line-height: 1.1875rem;
		}
	}

}