@media (max-width: 991px) {
	.information__block {
		flex-direction: column;
		margin-bottom: 0.75rem;
		height: auto;
		border-radius: 0.5rem;
	}

	.information__block.order .information__img {
		order: 1;
	}

	.information__block.order .information__text {
		order: 2;
	}

	.information__img {
		flex-shrink: 0;
		width: 100%;
		height: 12.5rem;
	}

	.information__text {
		padding: 0.75rem;
	}

	.information__text h5 {
		margin-bottom: 0.5rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.information__text p {
		margin-bottom: 0.5rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.information__link {
		font-size: 0.75rem;
		line-height: 1rem;
	}
}