.notification {
	position: fixed;
	top: 5.9375rem;
	padding: 1.5rem;
	width: 38.5rem;
	background: #fff;
	border-radius: 0.75rem;
	z-index: 101;
}

.notification > :last-child {
	margin-bottom: 0;
}

.notification--left {
	left: 0.75rem;
}

.notification--right {
	right: 0.75rem;
}

.notification--bottom {
	top: auto;
	bottom: 0.75rem;
}

.notification__head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
	width: 100%;
}

.notification__head h5 {
	margin: 0;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.notification__close {
	cursor: pointer;
	width: 1.5rem;
	height: 1.5rem;
}

.notification__body {
	margin-bottom: 1.5rem;
}

.notification__body p {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.notification__body p a {
	text-decoration: underline;
}

.notification__btns {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.notification__btns .btn {
	padding-left: 0;
	padding-right: 0;
	width: calc(50% - (0.75rem / 2));
}

.notification__btns.btns--full .btn {
	width: 100%;
}

.notification__product {
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.5rem;
}

.notification__product-img {
	position: relative;
	flex-shrink: 0;
	margin-right: 0.75rem;
	width: 8.25rem;
	height: 12.375rem;
}

.notification__product-img img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.5rem;
}

.notification__product-info {
	position: relative;
	height: 12.375rem;
}

.notification__product-info .wrap {
	display: flex;
	align-items: flex-start;
}

.notification__product-info .wrap .left {
	margin-right: auto;
	width: 20.5rem;
}

.notification__product-info .wrap .left h6 {
	display: block;
	display: -webkit-box;
	margin-bottom: 0.75rem;
	height: 3.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
}

.notification__product-info .wrap .left ul {
	display: flex;
	align-items: center;
	margin-bottom: 0.75rem;
}

.notification__product-info .wrap .left ul li {
	margin-right: 0.75rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.002em;
}

.notification__product-info .wrap .left p {
	margin: 0;
	color: #FF4E44;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.002em;
}

.notification__product-info .wrap .right h6 {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.notification__product-info .wrap .right p {
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
}

.notification__product-info .wrap .right .discount {
	color: #FF4E44;
}

.notification__product-info .wrap .right .old {
	color: #B3A8A1;
	text-decoration: line-through;
}

.notification__product-trash {
	position: absolute;
	bottom: 0;
	right: 0;
}