@media (max-width: 991px) {
	.modal-backdrop {
		background: var(--c-main);
		top: calc(1.75rem + 2.5rem);
		height: calc(100vh - 1.75rem - 2.5rem);
		height: calc(100dvh - 1.75rem - 2.5rem);
	}

	.modal-backdrop.show {
		opacity: 0.5;
	}

	.card__info-add-basket {
		display: flex !important;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 2.5rem;
		height: 2.5rem;
		background: #F8F5F1;
		border-radius: 0.5rem;
	}

	.modal-choose-size .modal-dialog {
		display: flex;
		align-items: flex-end;
		position: absolute;
		bottom: 0;
		left: 0;
		margin: 0;
		width: 100%;
		max-width: 100%;
	}

	.modal-choose-size .modal-content {
		height: auto;
		background: #F8F5F1;
		border: 0;
		border-radius: 1rem 1rem 0 0;
	}

	.modal-choose-size .modal-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 1.5rem 1.875rem;
		text-align: center;
	}

	.modal-choose-size h5 {
		margin-bottom: 1rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
	}

	.modal-choose-size ul {
		display: flex;
		margin-bottom: 1rem;
	}

	.modal-choose-size ul li {
		display: block;
		margin: 0 0.125rem;
	}

	.modal-choose-size ul li a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2.25rem;
		height: 2.25rem;
		color: var(--c-main);
		font-size: 0.875rem;
		text-decoration: none;
		background: #fff;
		border-radius: 0.5rem;
	}

	.modal-choose-size ul li a.disabled {
		color: #D9D9D9;
		border: 0.0625rem solid #D9D9D9;
		opacity: 1;
		pointer-events: none;
	}

	.modal-choose-size .link {
		color: var(--c-main);
		font-size: 0.75rem;
		line-height: 1rem;
		text-decoration: underline;
	}

	.modal {
		top: calc(1.75rem + 2.5rem);
		height: calc(100vh - 1.75rem - 2.5rem);
		height: calc(100dvh - 1.75rem - 2.5rem);
	}

	.modal .close {
		width: 1.5rem;
		height: 1.5rem;
	}

	.modal-dialog {
		margin: 0;
		max-width: 100%;
		height: 100%;
	}

	.modal-content {
		height: 100%;
		background: #fff;
		box-shadow: 0 0.625rem 1.875rem 0 rgba(38, 36, 34, 0.08);
		backdrop-filter: blur(1.875rem);
		-webkit-backdrop-filter: blur(1.875rem);
		border-radius: 0;
	}

	.modal-city .modal-dialog {
		max-width: 100%;
	}

	.modal-city .modal-body {
		padding: 1.25rem 0.75rem;
	}

	.modal-city form {
		margin-bottom: 0.75rem;
	}

	.modal-city form .form-control__search {
		left: 0.5rem;
		width: 1rem;
		height: 1rem;
	}

	.modal-city form .form-control {
		padding: 0 4.5rem 0 2rem;
		height: 2.1875rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		border-width: 0.0625rem;
		border-radius: 0.5rem;
	}

	.modal-city__title {
		margin-bottom: 0.75rem;
	}

	.modal-city__title h5 {
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.modal-city__list ul li a {
		padding: 0.5rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		letter-spacing: 0;
	}

	.modal-city__list-full {
		height: 72vh;
	}

	.modal-main .modal-dialog {
		max-width: 100%;
	}

	.modal-main .modal-content {
		border-radius: 0;
	}

	.modal-main .modal-body {
		padding: 1.25rem 0.75rem;
	}

	.modal-main .close {
		top: 1.25rem;
		right: 0.75rem;
	}

	.modal-main form .form-wrap {
		justify-content: space-between;
	}

	.modal-main form .form-text p {
		text-align: center;
	}

	.modal-main form .form-line p {
		margin-bottom: 0.75rem;
	}

	.modal-main-system .modal-form {
		padding-left: 0;
		padding-right: 0;
	}

	.modal__title {
		margin: 0 0 1.5rem;
		font-size: 1.25rem;
		line-height: 1.5rem;
		letter-spacing: 0;
	}

	.modal-basket {
		height: calc(100% - 1.5rem - 1.5rem);
	}

	.modal-basket__body {
		padding: 0 0 1.25rem;
		height: calc(100% - 4.375rem);
	}

	.modal-basket__body > .scrollbar-inner .scrollbar-inner {
		padding: 0;
	}

	.modal-basket__product {
		margin-bottom: 1.25rem;
	}

	.modal-basket__product-img {
		margin-right: 1rem;
		width: 2.5rem;
		height: 3.5rem;
	}

	.modal-basket__product-img img {
		border-radius: 0.25rem;
	}

	.modal-basket__product-info {
		padding-bottom: 2.25rem;
		width: calc(100% - 1rem - 2.5rem - 1rem);
		height: auto;
	}

	.modal-basket__product-info .wrap {
		flex-direction: column;
	}

	.modal-basket__product-info .wrap .left {
		margin: 0 0 0.5rem;
		width: 100%;
	}

	.modal-basket__product-info .wrap .left h6 {
		margin-bottom: 0.5rem;
		height: 2.375rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		letter-spacing: 0;
	}

	.modal-basket__product-info .wrap .left ul {
		margin: 0 0 0.5rem;
	}

	.modal-basket__product-info .wrap .left ul li {
		margin-right: 0.75rem;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.005em;
	}

	.modal-basket__product-info .wrap .left p {
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.005em;
	}

	.modal-basket__product-info .wrap .left :last-child {
		margin-bottom: 0;
	}

	.modal-basket__product-info .wrap .right {
		width: 100%;
	}

	.modal-basket__product-info .wrap .right .price {
		display: flex;
	}

	.modal-basket__product-info .wrap .right .price * {
		margin-right: 0.5rem;
	}

	.modal-basket__product-info .wrap .right h6 {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.modal-basket__product-info .wrap .right p {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.modal-basket__product-numbers {
		height: 1.5rem;
		border-radius: 0.5rem;
	}

	.modal-basket__product-numbers::before {
		border-width: 0.0625rem;
		border-radius: 0.5rem;
	}

	.modal-basket__product-numbers .option {
		width: 1.75rem;
	}

	.modal-basket__product-numbers .opt--number {
		font-size: 0.75rem;
	}

	.modal-basket__product-numbers .opt--value::before,
  .modal-basket__product-numbers .opt--value::after {
		background-size: 1rem;
	}

	.modal-basket__product-trash {
		width: 1.5rem;
		height: 1.5rem;
	}

	.modal-basket__foot h5 {
		margin-bottom: 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.modal-basket__foot .btns .btn {
		font-size: 0.75rem;
		line-height: 1rem;
		height: 2.5rem;
	}

	.modal-form {
		padding: 0;
	}

	.modal-form .nav-tabs {
		margin-bottom: 1rem;
		padding: 0.125rem;
		border-radius: 0.5rem;
	}

	.modal-form .nav-tabs li {
		width: calc(50% - (0.125rem / 2));
	}

	.modal-form .nav-tabs .nav-link {
		height: 2.5rem;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
		border-radius: 0.5rem;
	}

	.modal-form form .btns--logos .btn {
		height: 3rem;
	}

	.modal-form form .btns--logos .btn img {
		margin-right: 0.5rem;
		height: 1.5rem;
	}

	.modal-form form .form-checkbox .label-dot {
		margin-right: 0.5rem;
		width: 1.25rem;
		height: 1.25rem;
		border-width: 0.0625rem;
		border-radius: 0.25rem;
	}

	.modal-form form .form-checkbox .label-text {
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.modal-form__title {
		margin-bottom: 1.25rem;
		font-size: 1.25rem;
		line-height: 1.5rem;
		letter-spacing: 0;
		text-align: left;
	}

	.modal-product .modal-dialog {
		margin: 0 auto;
		max-width: 100%;
		height: 100%;
	}

	.modal-product .modal-content {
		height: 100%;
		background: #F8F5F1;
		border: 0;
		border-radius: 0;
	}

	.modal-product .modal-body {
		padding: 0;
		height: 100%;
	}

	.modal-product .close {
		display: block;
		position: absolute;
		top: 1.25rem;
		right: 0.75rem;
		width: 4rem;
		height: 4rem;
		border: 0;
		border-radius: 0;
		outline: 0;
		box-shadow: none;
		background: #fff url("../../img/svg/close.svg") no-repeat 50%/1.5rem;
		border: 0.0625rem solid #F8F5F1;
		border-radius: 0.5rem;
		opacity: 1;
		z-index: 1;
		cursor: pointer;
	}

	.modal-product-wrap {
		display: flex;
		width: 100%;
		height: 100%;
	}

	.modal-product__slider-add {
		margin-left: 0.75rem;
		margin-right: 5.25rem;
		width: 4.75rem;
		height: 100%;
	}

	.modal-product__slider-add .slick-list {
		padding: 1.375rem 0;
		height: 100% !important;
	}

	.modal-product__slider-add .slick-slide {
		margin-bottom: 0.25rem;
		width: 4.75rem;
		height: 7.25rem;
	}

	.modal-product__slider-add .slick-slide img {
		display: block;
		width: 100%;
		height: 100%;
		border: 0.0625rem solid transparent;
		border-radius: 0.5rem;
		object-fit: cover;
	}

	.modal-product__slider-add .slick-current img {
		background-color: var(--c-main);
	}

	.modal-product__slider-main {
		width: 98.5rem;
		height: 100%;
	}

	.modal-product__slider-main div {
		height: 100%;
	}

	.modal-product__slider-main .slick-slide {
		overflow-y: auto;
	}

	.modal-product__slider-main .slick-slide > div {
		height: 100%;
	}

	.modal-product__slider-main .slick-slide img {
		display: block;
		width: 100%;
		height: auto;
	}

	.modal-prod-feed .modal-dialog {
		max-width: 100%;
	}

	.modal-prod-feed .modal-body {
		padding: 4.625rem 0;
		height: 100%;
	}

	.modal-prod-feed .close {
		top: 1rem;
		right: 0.75rem;
		width: 2.25rem;
		height: 2.25rem;
		background-size: 1rem;
		border-width: 0.0625rem;
		border-radius: 0.5rem;
	}

	.modal-prod-feed__slider-main {
		width: 100%;
	}

	.modal-prod-feed__slider-main .slide {
		margin: 0;
		width: 100vw;
	}

	.modal-prod-feeback__slider-controls {
		padding: 0 0.75rem;
	}

	.modal-prod-feeback__slider-controls .slick-btn {
		width: 2.25rem;
		height: 2.25rem;
		border-radius: 0.5rem;
	}

	.modal-prod-feed__slider-add {
		bottom: 1.5rem;
		left: 0;
		width: 100vw;
		height: 5rem;
		transform: translate(0, 0);
	}

	.modal-prod-feed__slider-add .slick-list {
		padding: 0 0.75rem;
	}

	.modal-prod-feed__slider-add .slide {
		margin: 0 0.25rem;
		width: 3.75rem;
		height: 5rem;
		border-width: 0.0625rem;
		border-radius: 0.25rem;
	}

	.modal-prod-feed__slider-add .slide img {
		border-radius: 0.25rem;
	}

	.modal-prod-feed__slider-add .slick-current {
		border-radius: 0.25rem;
	}

	.modal-prod-feed__slider-add .slick-current img {
		border-radius: 0.25rem;
	}

	.modal-feedback .modal-dialog {
		max-width: 100%;
	}

	.modal-feedback .modal-content {
		background: transparent;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		backdrop-filter: none;
		-webkit-backdrop-filter: none;
	}

	.modal-feedback .modal-body {
		padding: 0 0.75rem 0.75rem;
		background: rgba(255, 255, 255, 0.95);
	}

	.modal-feedback__title {
		justify-content: flex-start;
		margin: 0 -0.75rem 0.75rem;
		padding: 0.5rem 0.75rem;
		background: #F8F5F1;
	}

	.modal-feedback__title .ico {
		margin-right: 0.5rem;
		width: 1.5rem;
		height: 1.5rem;
		background-color: transparent;
		border: 0;
	}

	.modal-feedback__title h5 {
		margin: 0;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.modal-feedback__title .close {
		margin-left: auto;
	}

	.modal-feedback__product {
		margin-bottom: 1.25rem;
	}

	.modal-feedback__product .img {
		margin-right: 0.75rem;
		width: 2.5rem;
		height: 3.5rem;
	}

	.modal-feedback__product .img img {
		border-radius: 0.25rem;
	}

	.modal-feedback__product .info h4 {
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.modal-feedback__product .info .opt {
		margin-bottom: 0.5rem;
	}

	.modal-feedback__product .info .opt li {
		margin-right: 0.5rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.modal-feedback__product .stars ul {
		margin-right: 0.75rem;
	}

	.modal-feedback__product .stars li {
		margin-right: 0.125rem;
	}

	.modal-feedback__product .stars li i {
		width: 1.25rem;
		height: 1.25rem;
	}

	.modal-feedback__product .stars h5 {
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.modal-feedback__form form .form-label {
		margin-bottom: 0.75rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.modal-feedback__form .form-radios {
		flex-wrap: wrap;
		margin-bottom: -0.75rem;
	}

	.modal-feedback__form .form-radio {
		margin-bottom: 0.75rem;
	}

	.modal-table-sizes .modal-dialog {
		max-width: 100%;
	}

	.modal-table-sizes .modal-content {
		height: auto;
		min-height: calc(100svh - 1.75rem - 2.5rem);
		background: #F8F5F1;
	}

	.modal-table-sizes .modal-body > .scrollbar-inner > .scrollbar-inner {
		padding-right: 0;
	}

	.modal-table-sizes .head {
		margin-bottom: 0.5rem;
	}

	.modal-table-sizes .head .modal-form__title {
		margin: 0;
		font-size: 1.0625rem;
		line-height: 1.375rem;
	}

	.modal-table-sizes .nav-tabs {
		flex-wrap: nowrap;
		margin-bottom: 1.5rem;
		width: 100%;
		border-bottom-width: 0.0625rem;
		overflow-x: auto;
	}

	.modal-table-sizes .nav-tabs li:not(:last-child) {
		margin-right: 1.5rem;
	}

	.modal-table-sizes .nav-tabs .nav-link {
		padding: 0.25rem 0 0.125rem;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.005em;
		border-bottom-width: 0.125rem;
		white-space: nowrap;
	}

	.modal-table-sizes .tab-pane h3 {
		margin-bottom: 1rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.005em;
	}

	.modal-table-sizes .calculates {
		justify-content: space-between;
		margin-bottom: 1.5rem;
	}

	.modal-table-sizes .calculates > li {
		max-width: calc(33.33% - (1.5rem / 2));
	}

	.modal-table-sizes .calculates > li:not(:last-child) {
		margin-right: 0;
	}

	.modal-table-sizes .calculates p {
		margin-bottom: 0.5rem;
		font-size: 0.6875rem;
		line-height: 0.875rem;
	}

	.modal-table-sizes .tables {
		margin-bottom: 1.75rem;
	}

	.modal-table-sizes .tables .form-selector {
		margin-bottom: 0.75rem;
	}

	.modal-table-sizes .tables .form-selector--btn {
		padding: 0 1.25rem;
	}

	.modal-table-sizes .tables__block h3 {
		margin-bottom: 0.75rem;
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.modal-table-sizes .tables__window-wrap {
		margin-bottom: 0.75rem;
		border-radius: 0.5rem;
		overflow-x: auto;
	}

	.modal-table-sizes .tables__window-wrap ul li {
		flex-shrink: 0;
		padding: 0.5rem 0.125rem;
		width: 3.375rem;
		height: 2.75rem;
		font-size: 0.6875rem;
		line-height: 0.875rem;
		border-bottom-width: 0.0625rem;
	}

	.modal-table-sizes .tables__window-wrap ul li:first-child {
		padding: 0.5rem 0.75rem;
		width: 8.875rem;
	}

	.modal-table-sizes .tables__window p {
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.modal-table-sizes .info h4 {
		margin-bottom: 1rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.modal-table-sizes .info .wrap {
		flex-direction: column;
		justify-content: flex-start;
	}

	.modal-table-sizes .info .wrap .text {
		margin-bottom: 1rem;
		width: 100%;
	}

	.modal-table-sizes .info .wrap .text ul li::before {
		margin-right: 0.5rem;
	}

	.modal-table-sizes .info .wrap .img {
		width: 100%;
	}

	.form-selector {
		max-width: 100%;
	}

	.form-selector.big .form-selector--btn {
		padding: 0 1rem;
	}

	.form-selector.small .form-selector--btn {
		padding: 0 0.5rem;
	}

	.form-selector--btn {
		height: 2.25rem;
		font-size: 0.75rem;
		line-height: 1rem;
		border-width: 0.0625rem;
		border-radius: 0.5rem;
	}

	.form-selector--btn::after {
		margin: 0 0.25rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	.form-selector--btn span {
		display: block;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.form-selector--list {
		position: absolute;
		bottom: -0.5rem;
		left: 0;
		margin: 0;
		padding: 0.5rem;
		width: 100%;
		height: 21rem;
		background: #fff;
		border-radius: 0.5rem;
		box-shadow: 0 0.625rem 1.875rem 0 rgba(38, 36, 34, 0.08);
		transform: translate(0, 100%);
		overflow: hidden;
		z-index: 10;
	}

	.form-selector--list .scrollbar-inner {
		height: 100%;
	}

	.form-selector--list .scrollbar-inner .scrollbar-inner {
		padding-right: 1rem;
	}

	.form-selector--list ul {
		width: 100%;
	}

	.form-selector--list ul li {
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.form-selector--list ul li a {
		display: block;
		padding: 0.5rem;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0.002em;
		white-space: nowrap;
		text-decoration: none;
		text-overflow: ellipsis;
		border-radius: 0.25rem;
	}

	.form-selector--list ul li a.selected {
		background: #F8F5F1;
	}

	.form-selector--list ul li a:hover {
		background: #F8F5F1;
	}

	.form-selector--list .form-checkbox {
		margin-bottom: 0.75rem;
	}

	.modal-table-sizes .info .wrap .text p,
  .modal-table-sizes .info .wrap .text ul li {
		margin-bottom: 1rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.modal-rezerv .modal-content {
		height: auto;
		min-height: calc(100svh - 1.75rem - 2.5rem);
	}

	.modal-rezerv__title {
		margin-bottom: 1.25rem;
		padding-top: 0;
		font-size: 1.25rem;
		line-height: 1.5rem;
		letter-spacing: 0;
		text-align: left;
	}

	.modal-rezerv__step-1 .form-group {
		margin-bottom: 1.25rem;
	}

	.modal-rezerv__step-1 .form-group .ico-search {
		left: 0.5rem;
		width: 1rem;
		height: 1rem;
	}

	.modal-rezerv__step-1 .form-group .link {
		right: 0.5rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.modal-rezerv__step-1 .form-control {
		padding: 0 4.5rem 0 2rem;
		height: 2.1875rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.modal-rezerv__step-1 .all-filters__title {
		margin-bottom: 0.5rem;
	}

	.modal-rezerv__step-1 .all-filters-sizes {
		margin-bottom: 1.25rem;
	}

	.modal-rezerv__step-1 .form-selector {
		margin-bottom: 1rem;
	}

	.modal-rezerv__step-1 .form-selector--colors .form-selector--list ul li a .label-dot {
		margin-right: 0.5rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	.modal-rezerv__step-1 .form-selector--colors .form-selector--list ul li a .label-dot::after {
		width: 0.75rem;
		height: 0.75rem;
	}

	.modal-rezerv__step-1 .choose-shop h3 {
		margin-bottom: 0;
		padding: 0.6875rem 0;
		font-size: 0.9375rem;
		line-height: 1.125rem;
	}

	.modal-rezerv__step-1 .choose-shop ul li {
		flex-direction: column;
		padding: 1rem 0;
	}

	.modal-rezerv__step-1 .choose-shop ul li .text h4 {
		margin-bottom: 0.5rem;
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.modal-rezerv__step-1 .choose-shop ul li .text p {
		margin-bottom: 0.5rem;
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.modal-rezerv__step-1 .choose-shop ul li .text p i {
		margin-right: 0.25rem;
		width: 0.75rem;
		height: 0.75rem;
	}

	.modal-rezerv__step-1 .choose-shop ul li .btns {
		padding: 0.75rem 0 0;
		width: 100%;
	}

	.modal-rezerv__step-1 .choose-shop ul li .btns .btn {
		width: 100%;
	}

	.form-selector--colors .form-selector--btn span {
		padding-left: 0.75rem;
	}

	.form-selector--colors .form-selector--btn span i {
		left: 0.75rem;
		width: 0.75rem;
		height: 0.75rem;
	}

	.form-selector--colors.choosed .form-selector--btn span {
		padding-left: 2rem;
	}

	.form-selector--colors .form-selector--list {
		height: 15rem;
	}

	.modal-rezerv__registrate .modal-basket__product {
		margin-bottom: 1.25rem;
	}

	.modal-rezerv__registrate .info {
		margin-bottom: 0.75rem;
		padding: 0.75rem 0ы;
	}

	.modal-rezerv__registrate .info h3 {
		margin-bottom: 0.5rem;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.modal-rezerv__registrate .info p {
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.002em;
	}

	.modal-rezerv__registrate .info :last-child {
		margin-bottom: 0;
	}

	.modal-rezerv__registrate form .form-btns .btn {
		width: 100%;
	}

	.modal-rezerv__finish {
		width: 100%;
	}

	.modal-system .modal-dialog {
		max-width: 100%;
	}

	.modal-system .modal-content {
		border-radius: 0.75rem;
		height: auto;
	}

	.modal-system .modal-body {
		padding: 1rem;
	}

	.modal-system .modal-body > :last-child {
		margin-bottom: 0 !important;
	}

	.modal-system .btn {
		padding: 0.75rem 1.125rem;
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.modal-system__head {
		margin-bottom: 0.75rem;
	}

	.modal-system__head * {
		margin-bottom: 0 !important;
	}

	.modal-system__title {
		margin: 0 0 0.75rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.modal-system__text {
		margin: 0 0 0.75rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.modal-map .modal-dialog {
		max-width: 100%;
		height: auto;
	}

	.modal-map .modal-content {
		height: auto;
		border-radius: 0;
	}

	.modal-map .close {
		right: auto;
		top: 1.25rem;
		left: 0.75rem;
		width: 2.25rem;
		height: 2.25rem;
		border-radius: 0.5rem;
	}

	.modal-map form {
		height: auto;
	}

	.modal-map form .form-btns {
		margin-top: 0;
	}

	.modal-map .close::before,
  .modal-map .close::after {
		width: 1.25rem;
		height: 1.25rem;
	}

	.modal-map__title {
		margin-bottom: 1rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.modal-map__wrap {
		flex-direction: column;
		height: auto;
	}

	.modal-map__iframe {
		flex-shrink: 0;
		height: 29rem;
	}

	.modal-map__info {
		order: 2;
		margin-top: -0.75rem;
		padding: 1.25rem 0.75rem;
		height: auto;
		background: #fff;
		border-radius: 0.75rem 0.75rem 0 0;
	}

	.modal-map__info .ico {
		width: 1.5rem;
		height: 1.5rem;
	}

	.modal--address .modal-map__info,
  .modal--points .modal-map__info {
		width: 100%;
	}

	.modal--points form {
		margin-bottom: 1rem;
	}

	.modal--points form .form-group {
		margin-bottom: 1rem;
	}

	.modal--points form .form-radios {
		margin-bottom: 1rem;
	}

	.modal--points .form-radios .form-radio:not(:last-child) {
		margin-right: auto;
	}

	.modal--points .form-radio .label-dot {
		margin-right: 0.5rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	.modal--points .form-radio .label-text {
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.modal-map__info-points {
		margin-left: -0.75rem;
		width: 100vw;
		height: auto;
		border-top-width: 0.0625rem;
	}

	.modal-map__info-points > .scrollbar-inner {
		height: auto;
	}

	.modal-map__info-points > .scrollbar-inner .scrollbar-inner {
		padding: 0;
	}

	.modal-map__info-points ul li a {
		padding: 0.75rem;
	}

	.modal-map__info-points ul li a h4 {
		margin-bottom: 0.5rem;
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.modal-map__info-points ul li a p {
		margin-bottom: 0.5rem;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
	}

	.modal-map__point {
		padding-top: 0.5rem;
		height: auto;
	}

	.modal-map__point h5 {
		margin-bottom: 1rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.modal-map__point ul {
		margin-bottom: 1rem;
	}

	.modal-map__point ul li:not(:last-child) {
		margin-bottom: 0.75rem;
	}

	.modal-map__point p {
		margin: 0 0 0.75rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.modal-map__point .form-btns {
		margin: 1.25rem 0 0;
	}

	.modal-stocks .modal-dialog {
		max-width: 100%;
	}

	.modal-stocks .modal-content {
		border-radius: 0;
	}

	.modal-stocks .modal-body {
		padding: 1.25rem 0.75rem;
	}

	.modal-stocks__head {
		justify-content: space-between;
		margin-bottom: 1rem;
	}

	.modal-stocks__head .info {
		margin: 0;
	}

	.modal-stocks__head .info h3 {
		margin-bottom: 0.25rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
		letter-spacing: 0;
	}

	.modal-stocks__head .info p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.modal-stocks__body h5 {
		margin-bottom: 0.5rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.modal-stocks__body p {
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.modal-stocks__body ul li {
		padding-left: 1.625rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.modal-stocks__body ul li::before {
		left: 0.5rem;
	}

	.modal-fashion {
	.modal-dialog {
		max-width: 100%;
	}
	.modal-content {
		height: auto;
	}
	.modal-basket__body {
		height: auto;
	}
	.modal-fashion .modal-feedback__title { margin: 0; }

	.modal-fashion .modal-feedback__title .close { top: 0.675rem; }
	.modal-basket__product-img {
		width: 5.375rem;
		height: 7.5rem;
	}
	.modal-basket__product-info {
		padding-bottom: 0.625rem;
		width: calc(100% - 5.375rem - 1rem);
		height: auto;
		.wrap {
			.left {
				margin-bottom: 0;
				width: 100%;
				.card__meta {
					margin-bottom: 0.5rem;
				}
				h6 {
					margin-bottom: 2rem;
					height: 2.375rem;
					font-size: 0.875rem;
					line-height: 1.1875rem;
				}
				.card__colors {
					padding: 0;
					li {
						margin-right: 0.25rem;
						width: auto;
						p {
							font-size: 0.75rem;
							line-height: 1;
						}
					}
				}
			}
			.right {
				position: absolute;
				bottom: 1.875rem;
				left: 0;
			}
		}
	}
	.btn {
		width: 2.25rem;
		height: 2.25rem;
	}
	}
	.modal-fashion__imgs {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
		transform: translate(0, 0);
		overflow: visible;
		.slick-list {
			width: 100%;
			height: auto;
		}
		.img {
			width: auto;
			height: auto;
		}
		.slick-dots {
			display: flex;
			align-items: center;
			position: absolute;
			bottom: 0.5rem;
			left: 0;
			padding: 0 0.75rem;
			width: 100%;
			li {
				margin-right: 0.75rem;
				width: 100%;
				height: 0.125rem;
				background: rgba(255, 255, 255, 0.25);
				border-radius: 6.25rem;
				&:last-child {
					margin: 0;
				}
				button {
					display: block;
					width: 0;
					height: 100%;
					color: rgba(0, 0, 0, 0);
					font-size: 0;
					line-height: 0;
					background: rgba(255, 255, 255, 1);
					border-radius: 6.25rem;
					border: 0;
					outline: 0;
					opacity: 0;
					box-shadow: none;
				}
				&.slick-active {
					button {
						width: 100%;
						opacity: 1;
						transition: width 5s;
					}
				}
			}
		}
	}

}