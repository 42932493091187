.blockSubscribe {
	@media screen and (max-width: 768px) {
		padding: 0 15px;
		label{
			font-weight: 400;
		}
	}
	

	.blockSubscribe_header {
		margin-bottom: 20px;
			font-size: 22px;
			text-align: center;
			font-weight: 600;
				@media screen and (max-width: 768px) {
				margin-bottom: 10px;
					}
	}

		.blockSubscribe_desc {
		font-size: 16px;
		text-align: center;

		@media screen and (max-width: 768px) {
			font-size: 14px;
		}
	}

	.blockSubscribe_email {
		width: 100%;
	}

	.formCheckbox{
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		a{
			color: #d0ac6c;
		}
		label{
			margin-left: 10px;
			margin-bottom: 0;
		}
		input{
			margin: 0;
			cursor: pointer;
		}
	}

	.formInput{
	margin-bottom: 15px;
		width: 100%;
		border: 1px solid;
		box-sizing: border-box;
		padding: 12px 14px;
		@media screen and (max-width: 768px) {
		margin-bottom: 20px;
			}
	}

	.formBtn{
		display: flex;
		justify-content: center;
	}

	.blockSubscribe_form {
		margin-top: 20px;





		button {
			text-align: center;
			margin-top: 20px;
			background-color: #000;
			color: white;
			padding: 14px 14px;
			text-transform: uppercase;
			font-size: 80%;
			letter-spacing: 1px;
			text-decoration: none;
			cursor: pointer;
			white-space: nowrap;
			border: navajowhite;
		}
	}

}

.validate {
	color: #ff0000;
}

.successMessage {
	margin-top: 15px;
}

