.centeredSlider {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: absolute;
	width: 100%;
	left: 0;
	overflow: hidden;

	@media (max-width: 991px) {
		position: relative;
		display: block;
	}
}

picture {
	img {
		width: unset;

		@media (max-width: 991px) {
			width: 100%;
		}
	}
}

.imageHeight {
	@media (max-width: 991px) {
		display: none;
	}
}