.shops__map-title {
	margin-bottom: 1.5rem;
	font-size: 3.75rem;
	line-height: 4.25rem;
	letter-spacing: -0.002em;
}
.shops__map-info {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	height: 41rem;
	form {
		margin-bottom: 1.5rem;
		.form-wrap {
			justify-content: flex-start;
			.form-selector {
				margin-right: 0.75rem;
			}
		}
		.form-selector--list {
			width: 296px;
			ul {
				li {
					a {
						display: flex;
					}
				}
			}
		}
	}
}
.shops__map-info-points {
	height: 37rem;
	& > .scrollbar-inner {
		height: 100%;
		.scrollbar-inner {
			padding-right: 0.75rem;
		}
	}
	ul {
		li {
			a {
				display: block;
				padding: 0.75rem 1.5rem;
				h4 {
					margin-bottom: 0.5rem;
					font-size: 1rem;
					line-height: 1.5rem;
				}
				p {
					margin-bottom: 0.5rem;
					font-size: 0.875rem;
					line-height: 1.25rem;
					letter-spacing: 0.0002em;
				}
				:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.shops__map-iframe {
	height: 41rem;
	iframe {
		width: 100%;
		height: 100%;
	}
}
.shops__store-head {
	display: flex;
	align-items: center;
	margin-bottom: 2.5rem;
}
.shops__store {
	.ico {
		width: 1.5rem;
		height: 1.5rem;
	}
}
.shops__store-head--title {
	margin: 0 0 0 1.25rem;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
}
.shops__store-info-title {
	display: flex;
	align-items: center;
	margin-bottom: 1.25rem;
	h5 {
		margin-bottom: 0;
		font-size: 1.5rem;
		line-height: 1.75rem;
		letter-spacing: -0.001em;
	}
	p {
		display: inline-block;
		margin: 0 0 0 0.75rem;
		padding: 0.25rem 0.375rem;
		font-size: 0.875rem;
		line-height: 1;
		letter-spacing: 0.0002em;
		background: #F5FF78;
		border-radius: 0.25rem;
	}
}
.shops__store-body {
	margin-bottom: 2.5rem;
}
.shops__store-body--address {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	p {
		margin-bottom: 1rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
		&:last-child {
			margin-bottom: 0;
		}
		i {
			border-radius: 50%;
		}
	}
}
.shops__store-body--address-get {
	margin-bottom: 1rem;
	h6 {
		margin: 0;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
	p {
		margin: 0;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
}
.shops__store-body--data {
	margin-bottom: 1.75rem;
	ul {
		display: flex;
		flex-direction: column;
		li {
			margin: 0 0 1rem;
			font-size: 1.125rem;
			line-height: 1.75rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.shops__store-body--collections {
	display: flex;
	align-items: center;
	margin-bottom: 1.75rem;
	h6 {
		margin: 0 0.75rem 0 0;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
	p {
		margin: 0 0.5rem 0 0;
		padding: 0.25rem 0.375rem;
		font-size: 0.875rem;
		line-height: 1;
		letter-spacing: 0.0002em;
		background: #DCD6D1;
		border-radius: 0.25rem;
	}
}
.shops__store-body--opportunities {
	display: flex;
	margin-bottom: 1.25rem;
	.block {
		margin-right: 1.75rem;
		h6 {
			margin-bottom: 0.25rem;
			font-size: 1.125rem;
			line-height: 1.75rem;
		}
	}
	ul {
		li {
			font-size: 1.125rem;
			line-height: 1.75rem;
			&::before {
				content: '·';
				padding: 0 0.5rem;
			}
		}
	}
}
.shops__store-body--rezerv {
	p {
		margin: 0;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
}
.shops__store-img {
	width: 100%;
	height: 43.625rem;
	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 0.5rem;
	}
}
.shops__store-iframe {
	margin-bottom: 2.5rem;
	width: 100%;
	height: 17.5rem;
	iframe {
		width: 100%;
		height: 100%;
	}
}
