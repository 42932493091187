@media (max-width: 991px) {
	.search-board {
		position: fixed;
		top: 1.75rem;
		left: 0;
		width: 100%;
		z-index: 101;
	}

	.search-board__form {
		padding: 0.625rem 0;
		-webkit-backdrop-filter: blur(0.9375rem);
		backdrop-filter: blur(0.9375rem);
	}

	.search-board__form form .form-control {
		padding: 0 4.5rem 0 2rem;
		height: 2.1875rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		border-width: 0.0625rem;
		border-radius: 0.5rem;
	}

	.form-control__search {
		left: 0.5rem;
		width: 1rem;
		height: 1rem;
	}

	.form-control__clear {
		right: 0.5rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.search-board__close {
		margin-left: 0.75rem;
		width: 1.5rem;
		height: 1.5rem;
	}

	.search-board__cont {
		padding: 0.75rem 0 5.5rem;
		height: calc(100vh - 1.75rem  - 2.5rem - 2.1875rem - (0.625rem * 2));
		height: calc(100dvh - 1.75rem  - 2.5rem - 2.1875rem - (0.625rem * 2));
		backdrop-filter: none;
		overflow-y: auto;
	}

	.search-board__cont > div {
		overflow: hidden;
	}

	.search-board__cont h5 {
		margin-bottom: 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.search-board__cont .btns {
		padding-top: 0;
	}

	.search-board__cont-menu .menu--empty {
		margin-bottom: 0.75rem;
	}

	.search-board__cont-menu .menu--empty li {
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.search-board__cont-menu .menu--empty li a {
		padding: 0.3125rem 0.625rem;
		font-size: 0.6875rem;
		line-height: 0.8125rem;
		letter-spacing: 0.005em;
	}

	.search-board__cont-menu .menu--searching {
		margin-bottom: 1.25rem;
	}

	.search-board__cont-menu .menu--searching li a {
		padding: 0.5rem 0;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.search-board__cont-menu .menu--searching li a::after {
		margin-left: 0.75rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	.search-board__cont--catalog .row {
		padding: 0 0.75rem 1rem;
		flex-wrap: nowrap;
		overflow-x: auto;
	}

	.search-board__cont--catalog .row > div {
		margin-right: 0.4375rem;
		padding: 0;
		width: 10.75rem;
	}

	.search-board__cont--catalog .row > div:last-child {
		margin-right: 0;
	}
}