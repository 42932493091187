.stocks .nav-tabs {
	border: 0;
	border-bottom: 0.0625rem solid #DCD6D1;
}

.stocks .nav-tabs li {
	margin-right: 1.5rem;
}

.stocks .nav-tabs li .nav-link {
	position: relative;
	padding: 0.75rem 0;
	color: #B3A8A1;
	font-weight: 600;
	font-size: 1.125rem;
	line-height: 1.75rem;
	border: 0;
}

.stocks .nav-tabs li .nav-link:hover {
	color: var(--c-main);
}

.stocks .nav-tabs li .nav-link:hover::after {
	display: block;
}

.stocks .nav-tabs li .nav-link.active {
	color: var(--c-main);
}

.stocks .nav-tabs li .nav-link.active::after {
	display: block;
}

.stocks .nav-tabs li .nav-link::after {
	content: '';
	display: none;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.125rem;
	background: var(--c-main);
}

.stocks__title {
	margin-bottom: 0.75rem;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
	text-align: center;
}

.stocks__tabs {
	display: flex;
	justify-content: center;
	margin: 0 auto 1.5rem;
}

.stocks__tabs ul {
	width: auto;
}

.stocks__list {
	margin-bottom: -1.5rem;
}

.stocks__block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	position: relative;
	margin-bottom: 1.5rem;
	padding: 1.25rem;
	width: 100%;
	height: 22.5rem;
	border-radius: 0.5rem;
	z-index: 0;
}

.stocks__block::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(38, 36, 34, 0.5);
	border-radius: 0.5rem;
	opacity: 0.4;
	z-index: -1;
}

.stocks__block h3 {
	margin-bottom: 0.25rem;
	color: #fff;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
}

.stocks__block p {
	margin-bottom: 0.75rem;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.stocks__block picture,
.stocks__block picture img {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	object-fit: cover;
	z-index: -2;
}