.spacer {
	width: 100%;
	height: 60px;
}

@media (max-width: 768px) {
	.spacer {
		height: 20px;
	}
}
