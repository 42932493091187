@media (max-width: 991px) {
.blogs-list__title {
	margin-bottom: 0.75rem;
	font-size: 1.1875rem;
	line-height: 1.25rem;
}
.blogs-list__tabs {
	display: block;
	margin: 0 0 1.25rem -0.75rem;
	padding: 0 0.75rem;
	width: 100vw;
}
.blogs-list {
	.nav-tabs {
		flex-shrink: 0;
		flex-wrap: nowrap;
		border-bottom-width: 0.0625rem;
		width: 100%;
		overflow-x: auto;
		li {
			margin-right: 1.5rem;
			&:last-child {
				margin-right: 0;
			}
			.nav-link {
				padding: 0.25rem 0;
				font-size: 0.75rem;
				line-height: 1rem;
				letter-spacing: 0.0005em;
				white-space: nowrap;
				&::after {
					height: 0.125rem;
				}
			}
		}
	}
	.form-btns {
		margin-top: 0.75rem;
		.btn {
			width: 100%;
			height: 2.125rem;
		}
	}
}
.blogs-list__block {
	margin-bottom: 0.75rem;
	.info {
		padding: 1rem 1rem 0.75rem;
		ul {
			margin: -0.125rem -0.125rem 0.375rem;
			li {
				margin: 0.125rem;
				padding: 0 0.25rem;
				font-size: 0.6875rem;
				line-height: 1rem;
				border-right: 0.25rem;
			}
		}
		h5 {
			margin-bottom: 2rem;
			font-size: 0.9375rem;
			line-height: 1.125rem;
			letter-spacing: 0;
		}
		a {
			font-size: 0.6875rem;
			line-height: 0.875rem;
			letter-spacing: 0.0005em;
		}
	}
}
.blogs-list__block picture,
	.blogs-list__block picture img {
	height: 10.625rem;
	border-radius: 0.5rem 0.5rem 0 0;
}
.blog__aside {
	position: relative;
	top: 0;
	margin-bottom: 0.875rem;
	.meta {
		justify-content: space-between;
		p {
			margin: 0 0.75rem 0 0;
			padding: 0.3125rem 0.625rem;
			font-size: 0.6875rem;
			line-height: 0.8125rem;
			letter-spacing: 0.0005em;
			border-width: 0.0625rem;
		}
		.information__link {
			font-size: 0.75rem;
			line-height: 1rem;
		}
		.information__collapse {
			bottom: -0.5rem;
			left: auto;
			right: 0;
			padding: 0.375rem;
			box-shadow: 0 0.625rem 1.875rem 0 rgba(38, 36, 34, 0.08);
			border-radius: 0.5rem;
			transform: translate(0, 100%);
			.social {
				li {
					margin-left: 0;
					padding: 0.375rem;
					a {
						font-size: 0.6875rem;
						line-height: 0.8125rem;
						.soc {
							margin-right: 0.5rem;
							width: 1rem;
							height: 1rem;
						}
					}
				}
			}
		}
	}
}
.blog__aside-back {
	margin-bottom: 0.75rem;
	.ico {
		width: 1.5rem;
		height: 1.5rem;
	}
}
.blog__aside-title {
	margin-bottom: 1.125rem;
	font-size: 1.1875rem;
	line-height: 1.25rem;
	letter-spacing: 0;
}
.blog__main-section {
	margin-bottom: 1.25rem;
}
.blog__main {
	.imgs {
		justify-content: flex-start;
		margin-bottom: 1.25rem;
		margin-left: -0.75rem;
		padding: 0 0.75rem;
		width: 100vw;
		overflow-x: auto;
		.img {
			flex-shrink: 0;
			margin: 0;
			width: 100%;
			img {
				border-radius: 0.5rem;
			}
			a {
				margin: 0.25rem 0 0;
				font-size: 0.6875rem;
				line-height: 0.875rem;
			}
		}
	}
	.imgs--full {
		.img {
			margin: 0;
			width: 100%;
			img {
				height: auto;
			}
		}
	}
	.imgs-1 {
		.img {
			img {
				height: 12.625rem;
			}
		}
	}
	.imgs-2 {
		.img {
			img {
				height: 16.4375rem;
			}
		}
	}
	.imgs-3 {
		.img {
			img {
				height: 16.4375rem;
			}
		}
	}
	.imgs-4 {
		.img {
			img {
				height: 16.4375rem;
			}
		}
	}
	.text {
		margin-bottom: 0.75rem;
		h3 {
			margin-bottom: 0.75rem;
			font-size: 0.875rem;
			line-height: 1.25rem;
			letter-spacing: -0.0005em;
		}
		h4 {
			margin-bottom: 0.75rem;
			font-size: 0.875rem;
			line-height: 1.25rem;
		}
		p {
			margin-bottom: 0.75rem;
			font-size: 0.8125rem;
			line-height: 1.125rem;
		}
		small {
			margin-bottom: 0.75rem;
			font-size: 0.875rem;
			line-height: 1.25rem;
			letter-spacing: 0.0002em;
		}
		:last-child {
			margin-bottom: 0;
		}
	}
}
.blog__main .imgs-2 .img,
	.blog__main .imgs-3 .img,
	.blog__main .imgs-4 .img {
	margin-right: 0.25rem;
	padding: 0;
	width: 10.75rem;
}
.blog__main-banner {
	margin: 0 0 1.25rem;
	padding: 0.5rem;
	height: 11.25rem;
	img {
		border-radius: 0.25rem;
	}
	.text {
		h2 {
			margin-bottom: 0;
			font-size: 1.5625rem;
			line-height: 2rem;
			letter-spacing: -0.001em;
		}
		p {
			margin-bottom: 0.5rem;
			font-size: 0.9375rem;
			line-height: 1.3125rem;
		}
	}
}
.blog__main-comments--login {
	h4 {
		margin-bottom: 0.5rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}
	p {
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: -0.0002em;
		a {
			text-decoration: underline;
		}
	}
}
.blog__main-comments--authorize {
	.top {
		margin-bottom: 1.25rem;
		h4 {
			margin-bottom: 0.5rem;
			font-size: 1.0625rem;
			line-height: 1.375rem;
			letter-spacing: 0;
		}
		form {
			.form-control {
				width: 100%;
				height: 2.5rem;
			}
			.form-btns {
				margin: 0;
				width: 100%;
				.btn {
					width: 100%;
					height: 2.5rem;
					font-size: 0.75rem;
				}
			}
		}
	}
	.container-btn {
		padding: 0.25rem 0;
		font-size: 0.875rem;
		text-align: center;
	}
}
.blog__main-comments-block {
	padding: 0.75rem 0 1.25rem;
	.information__link {
		.ico-circle-profile {
			margin-right: 0.75rem;
			width: 2.6875rem;
			height: 2.6875rem;
		}
		.text {
			padding-right: 0.75rem;
			h6 {
				font-size: 0.9375rem;
				line-height: 1.3125rem;
			}
			p {
				font-size: 0.8125rem;
				line-height: 1.125rem;
			}
		}
		.ico-chevron-down-b {
			width: 1rem;
			height: 1rem;
		}
	}
	.information__collapse {
		.text {
			padding-top: 0.75rem;
			padding-left: 0;
			p {
				font-size: 0.875rem;
				line-height: 1.1875rem;
			}
		}
	}
}
.blog__aside-banner {
	position: relative;
	bottom: 0;
	padding: 0.5rem;
	height: 11.25rem;
	transform: translate(0, 0);
	opacity: 1;
	img {
		border-radius: 0.25rem;
	}
	.text {
		h3 {
			margin-bottom: 0;
			font-size: 1.5625rem;
			line-height: 2rem;
		}
		p {
			margin-bottom: 0.5rem;
			font-size: 0.9375rem;
			line-height: 1.3125rem;
		}
	}
}

}