.textEl:not(:first-child) {
	margin-top: 30px;
}
@media (max-width: 768px) {
	.textEl:not(:first-child) {
		margin-top: 20px;
	}
}

.textBl {
	width: 90%;
	margin-left: 5%;
}

.textEl p {
	margin-bottom: 0;
}
