@media (max-width: 991px) {
	.section-who__wrap {
		flex-direction: column;
		padding: 0.75rem 0;
		border-radius: 0.75rem;
	}

	.section-who__wrap h4 {
		margin: 0 0 0.75rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
		text-align: center;
	}

	.section-who__wrap .nav-tabs {
		margin: 0;
		padding: 0.25rem;
		border-radius: 0.5rem;
	}

	.section-who__wrap .nav-tabs li:not(:last-child) {
		margin-right: 0.25rem;
	}

	.section-who__wrap .nav-tabs li .nav-link {
		padding: 0.75rem 1.25rem;
		font-size: 0.75rem;
		line-height: 1rem;
		border-radius: 0.5rem;
	}
}