.text-page__title {
	margin-bottom: 1.75rem;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
}
.text-page__title-small {
	margin-bottom: 44px;
	font-size: 28px;
	line-height: 32px;
	letter-spacing: -0.001em;
}
.text-page__main-banner {
	position: relative;
	margin-bottom: 2.75rem;
	width: 100%;
	text-align: center;
	border-radius: 0.5rem;
	.text {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		z-index: 1;
	}
	h3 {
		margin: 0 0 0.75rem;
		color: #fff;
		font-size: 3rem;
		line-height: 3.25rem;
		letter-spacing: -0.001em;
	}
	h4 {
		margin: 0 0 0.75rem;
		color: #fff;
		font-size: 1.75rem;
		line-height: 2rem;
		letter-spacing: -0.001em;
	}
	h5 {
		margin: 0 0 0.75rem;
		color: #fff;
		font-size: 1.5rem;
		line-height: 2rem;
		letter-spacing: -0.001em;
	}
	p {
		margin: 0 0 0.75rem;
		color: #fff;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
	:last-child {
		margin: 0;
	}
}
.text-page__main-banner picture,
	.text-page__main-banner picture img {
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	z-index: -1;
}
.text-page {
	.form-btns {
		justify-content: center;
		margin-bottom: 2.75rem;
		.btn {
			margin: 0 0.75rem;
		}
	}
	blockquote {
		display: inline-flex;
		margin: 0 0 0 auto;
		padding: 1.5rem;
		font-weight: 600;
		font-size: 1.125rem;
		line-height: 1.75rem;
		background: #F8F5F1;
		border-radius: 0.5rem;
		b {
			flex-shrink: 0;
			margin-right: auto;
			width: 14rem;
		}
		ul {
			margin-left: 0.625rem;
			li {
				display: flex;
				font-size: 1.125rem;
				font-weight: 500;
				line-height: 1.75rem;
				&::before {
					content: '·';
					padding: 0 0.5rem;
				}
			}
		}
	}
	h5 {
		margin-bottom: 0;
		font-size: 1.5rem;
		line-height: 1.75rem;
	}
	.faq__list {
		margin-bottom: 28px;
	}
	.faq {
		.text-page__title-small {
			margin-bottom: 24px;
		}
	}
}
.text-page__advantages {
	.text-page__title-small {
		margin-bottom: 32px;
	}
	&.advantages--comfortable {
		.text-page__advantages-foot {
			margin: 12px 0 0;
		}
	}
}
.text-page__advantages-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -0.75rem;
}
.text-page__advantages-col {
	display: flex;
	padding: 0 0.75rem 1.25rem;
	width: 33.3333%;
}
.text-page__advantages-block {
	padding: 1.5rem;
	width: 100%;
	.img {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0.75rem;
		width: 3rem;
		height: 3rem;
		background: #F8F5F1;
		border-radius: 50%;
		img {
			width: 2rem;
			height: 2rem;
			object-fit: contain;
		}
	}
	h6 {
		margin-bottom: 0.25rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
	p {
		margin-bottom: 0;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0.0002em;
	}
}
.text-page__advantages-foot {
	margin-bottom: 2.75rem;
	text-align: center;
	p {
		margin-bottom: 1.75rem;
		color: #B3A8A1;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.0002em;
	}
}
.text-page__wrap {
	align-items: flex-start;
	margin-bottom: 2.625rem;
}
.text-page__left {
	position: sticky;
	top: 9.375rem;
	left: 0;
}
.text-page__list-block {
	margin-bottom: 0.75rem;
	&:last-child {
		margin-bottom: 0;
	}
	.information__link {
		display: flex;
		align-items: center;
		padding: 1.5rem;
		text-decoration: none;
		border-radius: 0.5rem;
		.circle {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			margin-right: 1rem;
			width: 3rem;
			height: 3rem;
			background: #F8F5F1;
			border-radius: 50%;
			.ico {
				width: 2rem;
				height: 2rem;
				background-size: 1.9375rem;
			}
		}
		p {
			margin: 0 auto 0 0;
			font-size: 1.75rem;
			line-height: 2rem;
			letter-spacing: -0.001em;
			text-decoration: none;
		}
		.ico-chevron-down-b {
			flex-shrink: 0;
			margin-left: 1rem;
			width: 1.5rem;
			height: 1.5rem;
			transition: 0.3s;
		}
	}
	.information__link--common {
		display: inline-block;
		padding: 0;
		&:hover {
			background: rgba(0, 0, 0, 0);
			text-decoration: none;
		}
	}
	&.show {
		.information__link {
			.ico-chevron-down-b {
				transform: rotate(180deg);
			}
		}
	}
	.text-page__col-wrap {
		padding: 1.5rem;
		.text-page__col-wrap {
			display: flex;
			flex-direction: column;
			margin-bottom: 1.5rem;
		}
		h5 {
			margin: 0 0 1rem;
			font-size: 1.5rem;
			line-height: 1.75rem;
			letter-spacing: -0.001em;
		}
		p {
			margin: 0 0 1rem;
			font-size: 1.5rem;
			line-height: 2rem;
		}
		a {
			text-decoration: underline;
			word-wrap: break-word;
		}
		ul {
			margin-bottom: 1rem;
		}
		li {
			display: flex;
			margin: 0;
			font-size: 1.5rem;
			line-height: 2rem;
			&::before {
				content: '·';
				padding: 0 0.5rem;
			}
		}
		small {
			display: inline-block;
			margin-bottom: 1rem;
			font-size: 1rem;
			line-height: 1.5rem;
			letter-spacing: 0.0002em;
		}
		.ico-cont {
			align-items: flex-start;
			margin-bottom: 1.25rem;
		}
		.ico {
			flex-shrink: 0;
			margin-right: 0.75rem;
			width: 2rem;
			height: 2rem;
		}
		:last-child {
			margin-bottom: 0;
		}
	}
	&.information {
		.text-page__col-wrap {
			padding: 0 1.5rem 1.5rem;
		}
	}
}
.desk {
	.text-page__list-block {
		margin-bottom: 1.75rem;
	}
}
.text-page__common {
	margin-bottom: 1.25rem;
	&:last-child {
		margin-bottom: 0;
	}
	hr {
		display: block;
		margin: 0 0 20px;
		width: 100%;
		height: 1px;
		background: #DCD6D1;
	}
	h6 {
		margin: 0 0 12px;
		font-weight: 600;
		font-size: 18px;
		line-height: 28px;
	}
	p {
		margin: 0 0 12px;
		font-size: 16px;
		line-height: 24px;
		b {
			span {
				color: #B3A8A1;
			}
		}
	}
	small {
		display: block;
		margin: 0 0 4px;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.0005em;
	}
	:last-child {
		margin-bottom: 0;
	}
}
.text-page__list-block .text-page__col-wrap picture,
	.text-page__list-block .text-page__col-wrap picture img {
	display: block;
	width: 100%;
	height: auto;
}
.text-page__banner {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	position: relative;
	padding: 1.75rem;
	width: 100%;
	height: 29.75rem;
	h2 {
		margin-bottom: 0.25rem;
		color: #fff;
		font-size: 5rem;
		line-height: 5.25rem;
		letter-spacing: -0.002em;
	}
	p {
		margin-bottom: 1.25rem;
		color: #fff;
		font-size: 1.5rem;
		line-height: 2rem;
	}
}
.text-page__banner picture,
	.text-page__banner picture img {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	z-index: -1;
}
.reserve__head {
	margin-bottom: 1.25rem;
}
.reserve__body {
	margin-bottom: 2.625rem;
}
.reserve__foot {
	margin-bottom: 3.25rem;
	ul {
		li {
			display: flex;
			align-items: flex-start;
			font-size: 1.125rem;
			line-height: 1.75rem;
			&::before {
				content: '·';
				padding: 0 0.5rem;
			}
		}
	}
	h3 {
		margin: 0;
		font-size: 3rem;
		line-height: 3.25rem;
		letter-spacing: -0.001em;
		text-align: center;
	}
}
.reserve__foot-wrap {
	display: flex;
	justify-content: space-between;
	margin-bottom: 2.75rem;
	padding: 1.5rem;
	.block {
		width: calc(50% - (1.5rem / 2));
	}
}
.how-work {
	padding: 3.25rem 0;
	background: #F8F5F1;
	h3 {
		margin-bottom: 2.5rem;
		font-size: 2.25rem;
		line-height: 2.5rem;
		letter-spacing: -0.001em;
		text-align: center;
	}
	ul {
		display: flex;
		justify-content: space-between;
		margin-bottom: 2.5rem;
		li {
			width: 23.125rem;
			img {
				display: block;
				margin-bottom: 1rem;
				width: 4rem;
			}
			h6 {
				margin-bottom: 0.75rem;
				font-size: 1.125rem;
				line-height: 1.75rem;
			}
			p {
				margin-bottom: 0;
				font-size: 1.125rem;
				line-height: 1.75rem;
			}
		}
	}
}
.text-page__card {
	.img {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 28px;
		padding: 28px;
		width: 100%;
		height: 373px;
		background: #ccc;
		border-radius: 8px;
		div {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}
		h2 {
			margin: 0;
			font-size: 80px;
			line-height: 84px;
			letter-spacing: -0.002em;
			text-transform: uppercase;
		}
		h5 {
			margin: 0 0 0 auto;
			font-size: 24px;
			line-height: 28px;
			letter-spacing: -0.001em;
		}
	}
	&.basic {
		.img {
			background: #F8F5F1;
			* {
				color: var(--c-main);
			}
		}
	}
	&.silver {
		.img {
			background: #B3A8A1;
			* {
				color: var(--c-main);
			}
		}
	}
	&.gold {
		.img {
			background: var(--c-main);
			* {
				color: #fff;
			}
		}
	}
	.information__link {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
		padding: 24px 0;
		font-size: 24px;
		line-height: 28px;
		letter-spacing: -0.001em;
		text-decoration: none;
		cursor: pointer;
		.ico {
			transition: 0.3s;
		}
	}
	.information {
		&.show {
			.information__link {
				.ico {
					transform: rotate(180deg);
				}
			}
		}
	}
	.information__collapse {
		ul {
			& > li {
				&:not(:last-child) {
					margin-bottom: 24px;
				}
				p {
					margin-bottom: 8px;
					font-size: 18px;
					line-height: 28px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				ol {
					padding: 0;
					li {
						display: flex;
						align-items: center;
						margin-bottom: 0;
						padding: 0;
						list-style: none;
						&::before {
							content: '·';
							padding: 0 8px;
						}
					}
				}
			}
		}
	}
}
.text-page__cards {
	.form-btns {
		margin-top: 28px;
	}
}
.text-page__link {
	font-size: 18px;
	line-height: 28px;
	text-decoration: underline;
}
.text-page__how-work {
	.text-page__title-small {
		margin-bottom: 24px;
	}
	ul {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 32px;
		li {
			width: 23.5rem;
			.img {
				display: block;
				margin-bottom: 24px;
				width: 100%;
				height: 439px;
				img {
					display: block;
					width: 100%;
					height: 100%;
					border-radius: 8px;
					object-fit: cover;
				}
			}
			.text {
				padding: 0 20px;
				h6 {
					margin-bottom: 8px;
					font-size: 18px;
					line-height: 28px;
				}
				p {
					margin: 0;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: 0.0002em;
				}
			}
		}
	}
}
