@media (max-width: 991px) {
.page-company__title {
	margin-bottom: 1.125rem;
	font-size: 1.5625rem;
	line-height: 2rem;
	letter-spacing: -0.001em;
}
.page-company__tabs {
	justify-content: flex-start;
	margin-bottom: 1.75rem;
	margin-left: -0.75rem;
	padding: 0 0.75rem;
	width: 100vw;
	overflow-x: auto;
	.nav-tabs {
		flex-wrap: nowrap;
		padding: 0.125rem;
		border-radius: 0.5rem;
		li {
			margin: 0 0.0625rem;
			.nav-link {
				width: 10.8125rem;
				height: 2.5rem;
				font-size: 0.75rem;
				border-radius: 0.5rem;
			}
		}
	}
}
.page-company__coop-text {
	margin-bottom: 1.25rem;
	.img {
		order: 1;
		margin-bottom: 0.5rem;
	}
	.text {
		order: 2;
		padding: 0.75rem 1.375rem;
	}
	h3 {
		margin-bottom: 0.75rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
	}
	p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}
	ol {
		padding-left: 1.25rem;
		li {
			&::marker {
				font-size: 0.875rem;
				line-height: 1.1875rem;
			}
		}
	}
}
.page-company__coop picture,
	.page-company__coop picture img {
	border-radius: 0.25rem;
}
.page-company__coop-scheme {
	margin-bottom: 1.25rem;
	padding: 1.5rem 0;
	h3 {
		margin-bottom: 1.25rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
	}
	ul {
		flex-direction: column;
		justify-content: flex-start;
		li {
			position: relative;
			margin-bottom: 1rem;
			padding-left: 2.5rem;
			width: 100%;
			&:last-child {
				margin-bottom: 0;
			}
			img {
				position: absolute;
				top: 0;
				left: 0;
				margin: 0;
				width: 1.75rem;
			}
			p {
				margin-bottom: 0.25rem;
				font-size: 0.875rem;
				line-height: 1.25rem;
				letter-spacing: 0.0005em;
			}
		}
	}
}
.page-company__coop-app {
	flex-direction: column;
	margin-bottom: 1.25rem;
	form {
		padding: 1.5rem 0.75rem;
		width: 100%;
		border: 0.0625rem solid #DCD6D1;
		border-right: 0.0625rem solid #DCD6D1;
		border-bottom: 0;
		border-radius: 0.5rem 0.5rem 0 0;
		.form-group {
			width: 100%;
		}
	}
	picture {
		width: 100%;
	}
}
.page-company__coop-menu {
	margin-bottom: 1.5rem;
	.banner {
		margin-bottom: 0.5rem;
		height: 8.375rem;
	}
	& > :last-child {
		.banner {
			margin-bottom: 0;
		}
	}
}
.page-company__coop-menu picture,
	.page-company__coop-menu picture img {
	width: 100%;
	height: 100%;
}
.page-company__coop-enter {
	flex-direction: column;
	picture {
		width: 100%;
	}
	form {
		padding: 1.5rem 0.75rem;
		width: 100%;
		border: 0.0625rem solid #DCD6D1;
		border-left: 0.0625rem solid #DCD6D1;
		border-top: 0;
		border-radius: 0 0 0.5rem 0.5rem;
		.form-foot {
			.form-text {
				margin-bottom: 0.75rem;
			}
			.links {
				justify-content: space-between;
				a {
					margin: 0;
					font-size: 0.8125rem;
					line-height: 1.125rem;
				}
			}
		}
	}
}
.page-company__vacancies-tabs {
	margin-bottom: 1.25rem;
	.nav-tabs {
		border-bottom-width: 0.0625rem;
		li {
			margin: 0 0.75rem;
			.nav-link {
				padding: 0.25rem 0;
				font-size: 0.75rem;
				line-height: 1rem;
				&::after {
					height: 0.125rem;
				}
			}
		}
	}
}
.page-company__vacancies {
	.tab-content {
		padding-bottom: 0.25rem;
	}
	.block {
		margin-bottom: 0.5rem;
		padding: 1rem;
		h5 {
			margin-bottom: 0.25rem;
			font-size: 0.75rem;
			line-height: 1rem;
			letter-spacing: 0.0005em;
		}
		h6 {
			margin-bottom: 0.25rem;
			font-size: 0.75rem;
			line-height: 1rem;
		}
		p {
			font-size: 0.75rem;
			line-height: 1rem;
		}
	}
	.btn {
		padding-top: 0.625rem;
		padding-bottom: 0.625rem;
	}
}
.page-company__vacancy-main {
	padding-bottom: 1.25rem;
	order: 2;
	h6 {
		margin-bottom: 0.75rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.0005em;
	}
	ul {
		margin-bottom: 1rem;
		li {
			font-size: 0.8125rem;
			line-height: 1.125rem;
			letter-spacing: 0.0002em;
			&::before {
				margin: 0 0.375rem;
			}
		}
	}
}
.page-company__vacancy {
	.back {
		margin: 0 0 0.25rem;
		width: 1.5rem;
		height: 1.5rem;
	}
}
.page-company__vacancy-title {
	margin: 0 0 0.875rem;
	font-size: 0.9375rem;
	line-height: 1.125rem;
}
.page-company__vacancy-aside {
	order: 1;
	margin-bottom: 1.5rem;
	.bd {
		margin-bottom: 0.9375rem;
		padding: 0.75rem 1rem;
	}
	ul {
		li {
			margin-bottom: 0.5rem;
			h6 {
				margin: 0;
				font-size: 0.75rem;
				line-height: 1rem;
				letter-spacing: 0.0005em;
			}
			p {
				margin: 0;
				font-size: 0.75rem;
				line-height: 1rem;
				letter-spacing: 0.0005em;
			}
		}
	}
}
.page-company__vacancies-banner {
	margin-top: 1.75rem;
	height: 7.375rem;
	border-radius: 0.5rem;
	h4 {
		margin-bottom: 0.5rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
	}
	.btn {
		width: 14.6875rem;
		height: 2.125rem;
		font-size: 0.6875rem;
	}
}
.page-company__vacancies-banner picture,
	.page-company__vacancies-banner picture img {
	border-radius: 0.5rem;
}
.page-company__contacts {
	.order--1 {
		order: 1;
	}
	.order--2 {
		order: 2;
	}
}
.page-company__contacts-map {
	margin-bottom: 1.25rem;
	h5 {
		margin-bottom: 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
	}
	p {
		margin-bottom: 1rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}
	.iframe {
		border-radius: 0.5rem;
	}
}
.page-company__contacts-map-tabs {
	margin-bottom: 0.75rem;
	.nav-tabs {
		border-bottom-width: 0.0625rem;
		li {
			margin: 0 1.5rem 0 0;
			.nav-link {
				padding: 0.25rem 0;
				font-size: 0.75rem;
				line-height: 1rem;
				&::after {
					height: 0.125rem;
				}
			}
		}
	}
}
.page-company__contacts-data {
	margin-bottom: 0.5rem;
	padding: 1rem 1.25rem;
	ul {
		li {
			margin-bottom: 0.75rem;
			h6 {
				margin-bottom: 0.25rem;
				font-size: 0.75rem;
				line-height: 1rem;
				letter-spacing: 0.0005em;
			}
			p {
				margin: 0 0 0.25rem;
				font-size: 0.8125rem;
				line-height: 1.125rem;
				letter-spacing: 0.0002em;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.page-company__contacts-payer {
	padding: 1rem 1.25rem;
	ul {
		li {
			margin-bottom: 0.5rem;
			font-size: 0.875rem;
			line-height: 1.25rem;
		}
	}
}

}