@media (max-width: 991px) {
.collapse__menu {
	position: relative;
	top: 0;
	padding: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0);
	backdrop-filter: none;
	-webkit-backdrop-filter: none;
}
.collapse__menu-wrap {
	position: relative;
	padding: 0;
	height: auto;
	div {
		width: 100%;
		height: auto;
	}
	.container-fluid {
		position: relative;
		padding: 4.5rem 0.75rem 6rem;
	}
	.row {
		margin: 0;
		& > div {
			padding: 0;
			&:last-child {
				& > .collapse__menu-list--big {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	h4 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 0.5rem;
		width: 100%;
		font-weight: 600;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
		transition: 0.3s;
		&::after {
			content: '';
			width: 1.5rem;
			height: 1.5rem;
			background: url('../../img/svg/chevron-right-black.svg') no-repeat 50%/contain;
		}
	}
}
.collapse__menu--block {
	width: 100%;
	height: auto;
}
.collapse__menu-list--big {
	margin-bottom: 1.25rem;
	width: 100%;
	&:last-child {
		margin-bottom: 1.25rem;
	}
	li {
		display: block;
		position: relative;
		z-index: 1;
		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}
		h3 {
			margin-bottom: 0.25rem;
			font-size: 0.8125rem;
			line-height: 1.125rem;
			letter-spacing: 0;
		}
		a {
			font-size: 1.0625rem;
			line-height: 1.375rem;
			letter-spacing: 0;
		}
		p {
			margin: 0;
			padding-bottom: 0.25rem;
			font-size: 1rem;
			line-height: 1.5rem;
			letter-spacing: -0.001em;
		}
		&:last-child {
			.collapse__menu-slider {
				padding-bottom: 0;
			}
		}
	}
}
header {
	.navbar-collapse {
		&.active {
			.collapse__menu-list--big {
				opacity: 0;
				pointer-events: none;
			}
			.collapse__menu-wrap {
				h4 {
					opacity: 0;
					pointer-events: none;
				}
			}
		}
	}
}
.collapse__menu-slider {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	padding-bottom: 0.75rem;
	width: 100%;
	overflow-x: auto;
	.slide {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		margin-right: 0.5rem;
		padding: 0.5rem;
		position: relative;
		color: #fff;
		&:last-child {
			margin-right: 0;
		}
		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 0.25rem;
			z-index: -1;
		}
		h3 {
			margin-bottom: 0;
			color: #fff;
			font-size: 1.5625rem;
			line-height: 2rem;
			letter-spacing: -0.001em;
			text-transform: none;
		}
		p {
			margin: 0;
			font-size: 0.9375rem;
			line-height: 1.3125rem;
		}
	}
}
.collapse__menu--lines {
	.slide {
		width: 16.875rem;
		height: 16.875rem;
	}
}
.collapse__menu--collections {
	.slide {
		width: 14.1875rem;
		height: 22.5rem;
	}
}
.collapse__menu-list--small-wrap {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0 0.75rem;
	width: 100%;
	transform: translate(100%, 0);
	transition: 0.3s;
	z-index: 1;
	&.active {
		right: 0;
		transform: translate(0, 0);
	}
	&.outer {
		&.opacity {
			opacity: 0;
		}
	}
	.back {
		display: flex;
		align-items: center;
		margin: 0;
		width: 100%;
		height: 2.5rem;
		font-size: 1.0625rem;
	}
}
.collapse__menu-list--small {
	margin: 0;
	padding: 0.5rem;
	width: 100%;
	max-height: calc(100vh - 13rem);
	max-height: calc(100dvh - 13rem);
	background: #fff;
	border-radius: 0.5rem;
	transition: 0.3s;
	overflow-y: auto;
	z-index: 1;
	li {
		&:not(:last-child) {
			margin-bottom: 0;
		}
		&.last {
			padding-top: 0;
		}
	}
	a {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.5rem 0.5rem;
		width: 100%;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		&::after {
			content: '';
			width: 1.5rem;
			height: 1.5rem;
			background: url('../../img/svg/chevron-right-black.svg') no-repeat 50%/contain;
			opacity: 0;
		}
		&.next {
			&::after {
				opacity: 1;
			}
		}
		&.f-exp {
			font-size: 0.875rem;
			line-height: 1.1875rem;
		}
	}
}
.collapse__menu-list--small.mb--big,
	.collapse__menu-list--small.mb--medium,
	.collapse__menu-list--small.mb--small {
	margin-bottom: 0;
}
.navbar-city-cont {
	position: fixed;
	bottom: 2.4375rem;
	left: 0;
	width: 100%;
	.wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.5rem 0.75rem;
		width: 100%;
		border-radius: 0.5rem 0.5rem 0 0;
		background: rgba(255, 255, 255, 0.85);
		-webkit-backdrop-filter: blur(0.9375rem);
		backdrop-filter: blur(0.9375rem);
	}
}

}