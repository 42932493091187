header {
	position: fixed;
	top: 1.75rem;
	left: 0;
	width: 100%;
	z-index: 100;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.95);
		backdrop-filter: blur(0.25rem);
		z-index: -1;
	}
	.navbar {
		justify-content: space-between;
		padding: 0;
		height: 3.75rem;
	}
	.navbar-links {
		display: flex;
		align-items: center;
		li {
			display: flex;
			margin-right: 1rem;
		}
	}
	.navbar-brand {
		margin: 0;
		padding: 0;
		width: 6.375rem;
		height: 1.75rem;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.navbar-city {
		width: 4.9375rem;
		font-size: 1rem;
		line-height: 1;
		white-space: nowrap;
		.ico {
			flex-shrink: 0;
			position: relative;
			margin-right: 0.25rem;
			width: 1.25rem;
			height: 1.25rem;
		}
	}
	.navbar-expand-lg {
		.navbar-collapse {
			justify-content: center;
		}
		.navbar-nav {
			.nav-item {
				margin: 0 0.375rem;
			}
			.nav-link {
				position: relative;
				padding: 0.5625rem 0.75rem 0.4375rem;
				color: var(--c-main);
				font-weight: 600;
				font-size: 0.875rem;
				line-height: 1.25rem;
				letter-spacing: 0.005em;
				text-transform: uppercase;
				border-radius: 0.5rem;
				transition: 0.15s;
				color: #fff;
				background: var(--c-main);
				&.collapsed {
					color: var(--c-main);
					background: rgba(0, 0, 0, 0);
				}
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.navbar-icons {
		display: flex;
		align-items: center;
		padding: 0.5rem;
		border-radius: 0.5rem;
		li {
			&:not(:first-child) {
				margin-left: 0.75rem;
			}
			a {
				display: block;
				position: relative;
				.ico {
					display: block;
					position: relative;
					width: 1.5rem;
					height: 1.5rem;
				}
				.nubmer {
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					top: -0.3125rem;
					right: -0.3125rem;
					width: 1rem;
					height: 1rem;
					color: #fff;
					font-size: 0.5625rem;
					line-height: 1;
					letter-spacing: 0.005em;
					text-align: center;
					background: var(--c-main);
					border-radius: 50%;
					&.active {
						background: #FF4E44;
					}
				}
			}
		}
	}
}
