.advantages-list .block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5rem 1rem;
  border: 0.0625rem solid #DCD6D1;
  border-radius: 0.5rem;
}

.advantages-list .block:hover { text-decoration: none; }

.advantages-list .block .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
  width: 3.25rem;
  height: 3.25rem;
  background: #F8F5F1;
  border-radius: 50%;
}

.advantages-list .block .circle .ico { width: 2.25rem; height: 2.25rem; }

.advantages-list .block p {
  margin: 0;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
}