.product-numbers {
	display: inline-flex;
	align-items: center;
	position: relative;
	height: 2rem;
	border-radius: 0.5rem;
}

.product-numbers::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0.0625rem solid #DCD6D1;
	border-radius: 0.5rem;
	pointer-events: none;
}

.product-numbers .option {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 0;
	width: 2rem;
	height: 100%;
	background-color: transparent;
	border: 0;
	outline: 0;
}

.product-numbers .opt--number {
	font-size: 1rem;
	text-align: center;
}

.product-numbers .opt--value {
	cursor: pointer;
}

.product-numbers.min-value .opt--menus {
	pointer-events: none;
}

.product-numbers.min-value .opt--menus::before {
	opacity: 0;
}

.product-numbers.min-value .opt--menus::after {
	opacity: 1;
}

.product-numbers.max-value .opt--plus {
	pointer-events: none;
}

.product-numbers.max-value .opt--plus::before {
	opacity: 0;
}

.product-numbers.max-value .opt--plus::after {
	opacity: 1;
}

.product-numbers .opt--menus::before {
	background-image: url("../../img/svg/menus-black.svg");
	opacity: 1;
}

.product-numbers .opt--menus::after {
	background-image: url("../../img/svg/menus-grey.svg");
	opacity: 0;
}

.product-numbers .opt--plus::before {
	background-image: url("../../img/svg/plus-black.svg");
	opacity: 1;
}

.product-numbers .opt--plus::after {
	background-image: url("../../img/svg/plus-grey.svg");
	opacity: 0;
}

.product-numbers .opt--value::before,
.product-numbers .opt--value::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 1rem;
}