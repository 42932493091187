.product {
	.stars {
		display: flex;
		margin-right: 0.5rem;
		li {
			margin-right: 0.125rem;
			&:last-child {
				margin-right: 0;
			}
			i {
				display: block;
				position: relative;
				width: 1.25rem;
				height: 1.25rem;
				background: url('../../img/svg/star-empty.svg') no-repeat 50%/contain;
				&.active {
					background-image: url('../../img/svg/star.svg');
				}
			}
		}
	}
}
.product__kv {
	display: flex;
	align-items: flex-start;
	position: relative;
	margin-bottom: 0.625rem;
	width: 100%;
}
.product__kv-all-image {
	position: absolute;
	top: 1.5rem;
	left: 1.5rem;
	padding: 0;
	width: 11.375rem;
	height: 3.5rem;
	font-weight: 600;
	z-index: 1;
}
.product__kv-slider {
	position: sticky;
	top: calc(1.75rem + 3.75rem);
	left: 0;
	width: 66.6667%;
	height: calc(100vh - 1.75rem - 3.75rem);
	padding: 0;
}
.product__kv-slider__wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.product__kv-slider-main {
	position: relative;
	width: 100%;
	height: 100%;
	div {
		height: 100%;
	}
	.slide {
		height: 100%;
		&:focus-visible {
			outline: 0;
		}
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			cursor: zoom-in;
		}
	}
}
.product__kv-slider-controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	left: 0;
	padding: 0 0.75rem;
	width: 100%;
	transform: translate(0, -50%);
	pointer-events: none;
	z-index: 2;
	.slick-btn {
		padding: 0;
		width: 1.5rem;
		height: 1.5rem;
		color: rgba(0, 0, 0, 0);
		font-size: 0;
		line-height: 0;
		background-color: rgba(0, 0, 0, 0);
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		border: 0;
		border-radius: 0;
		outline: 0;
		box-shadow: none;
		cursor: pointer;
		pointer-events: auto;
	}
	.slick-btn-prev {
		background-image: url('../../img/svg/arrow-left-black.svg');
	}
	.slick-btn-next {
		background-image: url('../../img/svg/arrow-right-black.svg');
	}
}
.product__kv-slider-add {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 1.5rem 16.625rem;
	width: 100%;
	height: 10.25rem;
	z-index: 1;
	.slide {
		position: relative;
		margin: 0 0.25rem;
		width: 4.75rem;
		height: 7.25rem;
		border: 0.0625rem solid rgba(0, 0, 0, 0);
		border-radius: 0.25rem;
		.ico-play {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 2.25rem;
			height: 2.25rem;
			transform: translate(-50%, -50%);
			z-index: 1;
			cursor: pointer;
		}
		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 0.25rem;
		}
	}
	.slick-current {
		border-color: var(--c-main);
		border-radius: 0.5rem;
		img {
			border-radius: 0.5rem;
		}
	}
}
.product__kv-info {
	position: relative;
	padding: 1.5rem;
	width: 33.33333333%;
	min-height: 62rem;
	background: rgba(248, 245, 241, 0.95);
	backdrop-filter: blur(0.9375rem);
	z-index: 1;
	.breadcrumb {
		margin-bottom: 0.75rem;
	}
}
.product__kv-info-title {
	margin-bottom: 0.75rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.01em;
}
.product__kv-info-meta {
	display: flex;
	align-items: center;
	margin-bottom: 1.25rem;
	.articulus {
		margin-left: auto;
	}
	p {
		margin: 0;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.002em;
	}
}
.product__kv-info-meta-feedback {
	cursor: pointer;
}
.product__kv-info-price {
	margin-bottom: 0.5rem;
	padding: 0.5rem 0.75rem;
	background: #fff;
	border-radius: 0.5rem;
	h6 {
		margin: 0;
		padding: 0.125rem 0;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.002em;
	}
}
.product__kv-info-price-btn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	.ico {
		transition: 0.3s;
	}
	&.active {
		.ico {
			transform: rotate(180deg);
		}
	}
}
.product__kv-info-price-title {
	display: flex;
	align-items: center;
	margin-bottom: 0.25rem;
	h5 {
		margin-bottom: 0;
		margin-right: 0.5rem;
		color: #FF4E44;
		font-size: 1.5rem;
		line-height: 1.75rem;
		letter-spacing: -0.01em;
	}
	span {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;
		padding: 0.125rem 0.4375rem 0;
		color: #fff;
		font-weight: 500;
		font-size: 0.875rem;
		line-height: 1.25rem;
		text-align: center;
		background: rgba(255, 78, 68, 0.90);
		border-radius: 0.25rem;
	}
	p {
		margin: 0;
		color: #B3A8A1;
		font-size: 1.125rem;
		line-height: 1.75rem;
		text-decoration: line-through;
	}
}
.product__kv-info-price-col {
	ul {
		li {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			padding: 0.25rem 0;
			width: 100%;
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 0.0625rem;
				background: #DCD6D1;
			}
			p {
				margin: 0;
				font-size: 0.875rem;
				line-height: 1.25rem;
				letter-spacing: 0.002em;
				b {
					color: #FF4E44;
					letter-spacing: 0.002em;
				}
			}
		}
	}
}
.product__kv-info-split {
	display: flex;
	align-items: center;
	margin-bottom: 1.25rem;
	p {
		margin: 0;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.002em;
		.ico {
			position: relative;
			bottom: 0.0625rem;
			margin-left: 0.25rem;
			width: 1rem;
			height: 1rem;
			cursor: pointer;
		}
	}
	.hint__text {
		top: 0;
		right: -0.5rem;
		width: 12.5rem;
		transform: translate(100%, 0);
		z-index: 2;
	}
}
.product__kv-info-split-title {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.5rem;
	padding: 0.5625rem;
	background: #fff;
	border-radius: 0.25rem;
	img {
		display: block;
		width: 5.3125rem;
	}
}
.product__kv-info-model {
	margin-bottom: 0.75rem;
	p {
		margin-bottom: 0.25rem;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0.002em;
	}
}
.product__kv-info-colors {
	display: flex;
	position: relative;
	width: 100%;
	overflow: hidden;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 7rem;
		height: 100%;
		// background: linear-gradient(-90deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%);
		border-radius: 0.5rem;
		z-index: 1;
		pointer-events: none;
	}
	&.swiper-backface-hidden {
		&::after {
			display: none;
		}
	}
	.swiper-wrapper {
		.swiper-slide {
			display: block;
			margin-right: 0.5rem;
			width: 3.25rem;
			&:last-child {
				margin-right: 0;
			}
			img {
				display: block;
				width: 3.25rem;
				height: 5rem;
				border: 0.0625rem solid rgba(0, 0, 0, 0);
				border-radius: 0.5rem;
			}
			&.active {
				img {
					border-color: var(--c-main);
				}
			}
		}
	}
}
.product__kv-info-sizes {
	margin-bottom: 1.25rem;
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.25rem;
		width: 100%;
		p {
			margin: 0;
			font-size: 1rem;
			line-height: 1.5rem;
			letter-spacing: 0.002em;
		}
		a {
			font-size: 0.875rem;
			line-height: 1rem;
			letter-spacing: 0.005em;
			text-decoration: underline;
		}
	}
	ul {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: flex-start;
		margin: -0.125rem -0.125rem 0.375rem;
		li {
			display: block;
			margin: 0.125rem;
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 2.25rem;
				height: 2.25rem;
				color: var(--c-main);
				font-size: 0.875rem;
				text-decoration: none;
				background: #fff;
				border-radius: 0.5rem;
				&.active {
					color: #fff;
					background: var(--c-main);
				}
				&.disabled {
					color: #D9D9D9;
					border: 0.0625rem solid #D9D9D9;
					opacity: 1;
					pointer-events: none;
				}
			}
		}
	}
	.bot {
		p {
			margin: 0;
			font-size: 0.75rem;
			line-height: 1rem;
			letter-spacing: 0.005em;
		}
	}
}
.product__kv-info-btns {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;
	margin-bottom: 1.25rem;
	.wrap {
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		width: 100%;
	}
	.btn-one {
		margin-right: 0.25rem;
		padding: 0;
		width: calc(100% - 0.25rem - 4rem);
		height: 4rem;
	}
	.btn-two {
		margin-left: auto;
		padding: 0;
		width: 4rem;
		height: 4rem;
		.ico {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
	.product__kv-info--hint {
		justify-content: center;
		margin: 0.5rem auto 0;
	}
	.link {
		color: var(--c-main);
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
	.hint__text {
		bottom: -0.25rem;
		left: 50%;
		width: 14.25rem;
		transform: translate(-50%, 100%);
	}
}
.product__kv-info--hint {
	display: flex;
	align-items: center;
}
.hint {
	display: flex;
	position: relative;
	.ico {
		cursor: pointer;
	}
}
.hint__text {
	position: absolute;
	padding: 0.5rem;
	background: #F8F5F1;
	z-index: 1;
	p {
		margin: 0;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
	}
}
.product__kv-info-cols {
	margin-bottom: 0.75rem;
	padding: 0.75rem;
	border: 0.0625rem solid #DCD6D1;
	border-radius: 0.5rem;
}
.product__kv-info-cols-link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0;
	width: 100%;
	font-size: 1rem;
	line-height: 1.5rem;
	cursor: pointer;
	.ico {
		transition: 0.3s;
	}
	&.active {
		.ico {
			transform: rotate(180deg);
		}
	}
}
.product__kv-info-cols-list {
	padding-top: 0.5rem;
	ul {
		li {
			display: flex;
			align-items: flex-start;
			padding: 0.25rem 0;
			border-bottom: 0.0625rem dashed #DCD6D1;
			.icons {
				display: block;
				position: relative;
				top: 0.25rem;
				margin-right: 0.5rem;
				width: 1.5rem;
				height: 1.5rem;
				.ico {
					width: 100%;
					height: 100%;
				}
			}
			p {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: 0;
				width: 100%;
				font-size: 1rem;
				line-height: 1.5rem;
				b {
					display: block;
					width: 100%;
					font-weight: 600;
					font-size: 1.125rem;
					line-height: 1.75rem;
				}
			}
		}
	}
}
.product__tabs {
	position: relative;
	padding: 1.5rem;
}
.product__tabs-nav {
	position: relative;
	z-index: 1;
	.nav-tabs {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		border: 0;
		border-bottom: 0.0625rem solid #DCD6D1;
		li {
			margin-right: 1.5rem;
			.nav-link {
				position: relative;
				padding: 0.75rem 0;
				color: #B3A8A1;
				font-weight: 600;
				font-size: 1.125rem;
				line-height: 1.75rem;
				border: 0;
				&:hover {
					color: var(--c-main);
					&::after {
						display: block;
					}
				}
				&.active {
					color: var(--c-main);
					&::after {
						display: block;
					}
				}
				&::after {
					content: '';
					display: none;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 0.125rem;
					background: var(--c-main);
				}
				sup {
					font-weight: 500;
					font-size: 0.875rem;
					line-height: 1.25rem;
				}
			}
		}
	}
}
.product__tabs-cont {
	position: relative;
	.left {
		padding-top: 5.5rem;
		& > .scrollbar-inner {
			height: 100%;
			.scrollbar-inner {
				padding-right: 1.75rem;
			}
		}
	}
	.right {
		padding: 1.25rem;
		background: #F8F5F1;
		border-radius: 0.5rem;
	}
}
.product__description {
	.left {
		p {
			margin-bottom: 1.25rem;
			font-size: 1.125rem;
			line-height: 1.75rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.right {
		position: relative;
		.top {
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			position: relative;
			width: 100%;
			height: 2rem;
			i {
				display: block;
				width: 0.75rem;
				height: 0.75rem;
				background: #fff;
				border-radius: 50%;
			}
		}
		ul {
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0.75rem 0;
				border-bottom: 0.0625rem dotted #DCD6D1;
				p {
					margin: 0;
					font-size: 1.125rem;
					line-height: 1.75rem;
				}
			}
		}
	}
}
.product__feedback {
	.left {
		height: 55.625rem;
	}
	.link-more {
		display: inline-block;
		margin-top: 1rem;
		color: var(--c-main);
		font-size: 1.25rem;
		letter-spacing: 0.02em;
		text-decoration: underline;
		span {
			&:nth-child(1) {
				display: block;
			}
			&:nth-child(2) {
				display: none;
			}
		}
		&.active {
			span {
				&:nth-child(1) {
					display: none;
				}
				&:nth-child(2) {
					display: block;
				}
			}
		}
	}
	.right {
		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 1.25rem;
			width: 100%;
			h5 {
				margin-bottom: 0;
				font-size: 1.5rem;
				line-height: 1.75rem;
			}
		}
		.mid {
			margin-bottom: 1.25rem;
			ul {
				li {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 1rem;
					width: 100%;
					&:last-child {
						margin-bottom: 0;
					}
					p {
						margin: 0;
						font-size: 1.125rem;
						line-height: 1.75rem;
					}
					.line {
						position: relative;
						width: 27.25rem;
						height: 0.25rem;
						background: #fff;
						border-radius: 6.25rem;
						i {
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							background: #DCD6D1;
							border-radius: 6.25rem;
						}
					}
				}
			}
		}
		.bot {
			.btn {
				width: 100%;
			}
		}
	}
}
.product__feedback-block {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 1rem;
	padding: 1.25rem;
	border-radius: 0.5rem;
	//&:nth-child(2n {
	//	& + 1) {
	//		background: #F8F5F1;
	//	}
	//	& + 2) {
	//		background: #ffffff;
	//	}
	//}
	&:last-child {
		margin-bottom: 0;
	}
	.img {
		flex-shrink: 0;
		margin-right: 0.75rem;
		width: 3.75rem;
		height: 3.75rem;
		border-radius: 50%;
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}
	.info {
		width: 100%;
		.top {
			display: flex;
			align-items: center;
			margin-bottom: 0.25rem;
			h6 {
				margin: 0 0.75rem 0 0;
				font-weight: 500;
				font-size: 1.5rem;
				line-height: 2rem;
			}
			p {
				margin: 0;
				font-size: 1rem;
				line-height: 1.5rem;
				letter-spacing: 0.002em;
			}
			:last-child {
				margin-left: auto;
			}
		}
		.meta {
			display: flex;
			align-items: center;
			margin-bottom: 0.75rem;
			h6 {
				display: inline-block;
				margin: 0;
				padding: 0.125rem 0.375rem;
				font-weight: 500;
				font-size: 0.75rem;
				line-height: 1rem;
				letter-spacing: 0.005em;
				background: #DCD6D1;
				border-radius: 0.25rem;
			}
			p {
				margin: 0 0.5rem 0 0;
				font-size: 1rem;
				line-height: 1.5rem;
				letter-spacing: 0.002em;
			}
		}
		.body {
			margin-bottom: 1rem;
			p {
				margin: 0;
				font-size: 1.125rem;
				line-height: 1.75rem;
			}
		}
		.imgs {
			display: flex;
			align-items: center;
			.img {
				display: block;
				position: relative;
				margin-right: 0.5rem;
				width: 2.5rem;
				height: 3.75rem;
				border-radius: 0.25rem;
				img {
					display: block;
					width: 100%;
					height: 100%;
					border-radius: 0.25rem;
					object-fit: cover;
				}
				.ico-play {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 2.25rem;
					height: 2.25rem;
					transform: translate(-50%, -50%);
					z-index: 1;
					cursor: pointer;
				}
			}
		}
	}
}
.product__text {
	.left {
		height: 55.625rem;
	}
	.right {
		h3 {
			margin-bottom: 0.75rem;
			font-size: 1.5rem;
			line-height: 2rem;
		}
		p {
			margin-bottom: 0.75rem;
			font-size: 1.125rem;
			line-height: 1.75rem;
			letter-spacing: 0.002em;
			span {
				display: block;
				padding: 0.5rem;
				background: #fff;
				border-radius: 0.5rem;
			}
		}
	}
}
.product__text-block {
	margin-bottom: 1rem;
	padding: 1.25rem;
	background: #F8F5F1;
	border-radius: 0.5rem;
	&:last-child {
		margin-bottom: 0;
	}
	h3 {
		display: inline-flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin-bottom: 0;
		width: 100%;
		font-weight: 500;
		font-size: 1.5rem;
		line-height: 2rem;
		cursor: pointer;
		span {
			display: block;
			margin-right: 0.75rem;
			padding: 0 0.5rem;
			font-size: 1rem;
			line-height: 1.375rem;
			letter-spacing: -0.0025em;
			background: #DCD6D1;
			border-radius: 0.25rem;
		}
		.ico {
			flex-shrink: 0;
			margin: auto 0 auto auto;
			transition: 0.3s;
		}
		&.active {
			.ico {
				transform: rotate(180deg);
			}
		}
	}
}
.product__text-block-col {
	padding-top: 0.75rem;
	& > p {
		margin-bottom: 0.75rem;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0.002em;
	}
	& > ul {
		margin-bottom: 0.75rem;
		li {
			display: flex;
			align-items: flex-start;
			position: relative;
			margin-bottom: 0;
			font-size: 1.125rem;
			line-height: 1.75rem;
			letter-spacing: 0.002em;
			&::before {
				content: '•';
				padding: 0 0.5rem;
			}
		}
	}
	blockquote {
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: flex-start;
		padding: 0.5rem;
		background: #fff;
		border-radius: 0.5rem;
		.ico {
			flex-shrink: 0;
			width: 1.5rem;
			height: 1.5rem;
		}
		span {
			margin: 0;
			font-size: 1.125rem;
			line-height: 1.75rem;
		}
	}
	& > :last-child {
		margin-bottom: 0;
	}
}
.state-preload {
	.main-product {
		overflow-y: scroll;
	}
}
.main-product {
	.now-buy--big {
		position: relative;
		.point {
			position: absolute;
			top: -16rem;
		}
	}
}
.product__page {
	padding: 3.25rem 0 0;
	.row {
		align-items: center;
		margin-bottom: 3.25rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	h3 {
		margin-bottom: 1.25rem;
		font-size: 3rem;
		line-height: 3.25rem;
		letter-spacing: -0.01em;
	}
	p {
		margin: 0;
		color: #131313;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
	img {
		display: block;
		width: 100%;
		height: auto;
		border-radius: 0.25rem;
	}
}
