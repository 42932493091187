.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	max-height: 100svh;
	background: #fff;
	z-index: 999999;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 6.25rem;
		height: 6.25rem;
		background: url('../../img/svg/preloader.svg') no-repeat 50%/contain;
		animation: infinite preload_anim 1s linear;
		transform: translate(-50%, -50%);
	}
}
//@keyframes {
//	preload_anim {
//		0% { transform: translate(-50%, -50%) rotate(0deg);
//	}
//	pre_fade-1 {
//		0% { width: 100%;
//	}
//}
//100% {
//	transform: translate(-50%, -50%) rotate(360deg);
//	width: 0;
//	width: 100%;
//	width: 100%;
//	width: 0;
//}
//} {
//	.preloader__local {
//		position: relative;
//		margin: 2.25rem auto;
//		width: 12.25rem;
//		height: 3rem;
//		i {
//			&::before {
//				content: '';
//				position: absolute;
//				top: 0;
//				width: 12.25rem;
//				height: 3rem;
//				background-repeat: no-repeat;
//				background-position: 100% 50%;
//				background-size: cover;
//			}
//		}
//	}
//	@keyframes {
//		pre_fade-2 {
//			0% { width: 0;
//		}
//		pre_fade-3 {
//			0% { width: 0;
//		}
//		pre_fade-4 {
//			0% { width: 100%;
//		}
//	}
//}
.preloader__local {
	i {
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		&:nth-child(1) {
			right: 0;
			width: 100%;
		}
		&:nth-child(2) {
			left: 0;
			width: 0;
		}
		&:nth-child(3) {
			left: 0;
			width: 0;
		}
		&:nth-child(4) {
			right: 0;
			width: 100%;
		}
		&:nth-child(1)::before {
			right: 0;
			background-image: url('../../img/svg/logo.svg');
		}
		&:nth-child(2)::before {
			left: 0;
			background-image: url('../../img/svg/logo-2.svg');
		}
		&:nth-child(3)::before {
			left: 0;
			background-image: url('../../img/svg/logo.svg');
		}
		&:nth-child(4)::before {
			right: 0;
			background-image: url('../../img/svg/logo-2.svg');
		}
	}
	&.active-1 {
		i {
			&:nth-child(1) {
				opacity: 1;
				animation: infinite pre_fade-1 2s linear;
			}
			&:nth-child(2) {
				opacity: 1;
				animation: infinite pre_fade-2 2s linear;
			}
			&:nth-child(3) {
				opacity: 0;
			}
			&:nth-child(4) {
				opacity: 0;
			}
		}
	}
	&.active-2 {
		i {
			&:nth-child(1) {
				opacity: 0;
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				opacity: 1;
				animation: infinite pre_fade-3 2s linear;
			}
			&:nth-child(4) {
				opacity: 1;
				animation: infinite pre_fade-4 2s linear;
			}
		}
	}
}
section {
	margin-bottom: 3.25rem;
	&.mb {
		margin-bottom: 1.5rem;
	}
	&.pt {
		padding-top: 1.75rem;
	}
}
.subscribe {
	&.mb {
		margin-bottom: 3.25rem;
	}
}
.banner {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	position: relative;
	padding: 1.75rem;
	color: #fff;
	border-radius: 0.5rem;
	z-index: 0;
	:last-child {
		margin-bottom: 0;
	}
}
.banner--justify {
	align-items: center;
	justify-content: space-between;
}
.banner__img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.5rem;
	z-index: -1;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 0.5rem;
	}
}
.banner__title {
	margin-bottom: 0;
	color: #fff;
	font-size: 7rem;
	line-height: 7.25rem;
	letter-spacing: -0.02em;
}
.banner__title-img {
	width: 40rem;
	height: auto;
}
.banner__medium-title {
	margin-bottom: 0.375rem;
	color: #fff;
	font-size: 5rem;
	line-height: 5.25rem;
	letter-spacing: -0.02em;
}
.banner__small-title {
	margin-bottom: 0.375rem;
	color: #fff;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.01em;
}
.banner__text {
	margin-bottom: 1.25rem;
	font-size: 1.5rem;
	line-height: 2rem;
}
.page-title {
	position: relative;
	overflow: hidden;
}
.page-title__back {
	display: flex;
	align-items: center;
	width: 100%;
	height: 10.625rem;
	p {
		display: flex;
		justify-content: center;
		margin: 0;
		color: var(--c-add);
		font-size: 15.25rem;
		line-height: 1;
		letter-spacing: -0.002em;
		text-align: center;
		text-transform: uppercase;
		white-space: nowrap;
		&.fz-big {
			font-size: 15.25rem;
		}
		&.fz-mid {
			font-size: 12rem;
		}
		&.fz-small {
			font-size: 8.25rem;
		}
	}
}
.page-title__front {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 10.625rem;
	z-index: 1;
	h2 {
		margin: 0;
		font-size: 5rem;
		line-height: 5.25rem;
		letter-spacing: -0.02em;
	}
	p {
		margin: 0;
		font-size: 1.5rem;
		line-height: 2rem;
	}
}
.title {
	margin-bottom: 1.75rem;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.01em;
}
.site-title {
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.25rem;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.02em;
	sup {
		top: 0.75rem;
		left: 0.25rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
}
.breadcrumb {
	align-items: center;
	margin-bottom: 1.25rem;
	li {
		.ico {
			display: block;
			position: relative;
			bottom: 0.0625rem;
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}
.breadcrumb-item {
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.002em;
	& + .breadcrumb-item {
		padding-left: 0.25rem;
		&::before {
			content: '';
			position: relative;
			bottom: 0.0625rem;
			margin-right: 0.25rem;
			padding: 0;
			width: 1rem;
			height: 1rem;
			color: rgba(0, 0, 0, 0);
			background: url('../../img/svg/chevron-right-grey.svg') no-repeat 50%/contain;
		}
	}
}
.bd {
	background: #fff;
	border: 0.0625rem solid #DCD6D1;
	border-radius: 0.5rem;
}
