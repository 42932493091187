.information__block {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 0.75rem;
	height: 22rem;
	background: #F8F5F1;
	border-radius: 0.5rem;
	overflow: hidden;
}

.information__block:last-child {
	margin-bottom: 0;
}

.information__block.order .information__text {
	order: 1;
}

.information__block.order .information__img {
	order: 2;
}

.information__img {
	flex-shrink: 0;
	width: 29.875rem;
	height: 100%;
}

.information__img img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.information__text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 1.5rem;
}

.information__text h5 {
	margin-bottom: 0.75rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.01em;
}

.information__text p {
	margin-bottom: 0.75rem;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.002em;
}

.information__link {
	font-size: 1.125rem;
	line-height: 1.75rem;
	text-decoration: underline;
}

.information__link span:nth-child(1) {
	display: block;
}

.information__link span:nth-child(2) {
	display: none;
}

.information__link.active span:nth-child(1) {
	display: none;
}

.information__link.active span:nth-child(2) {
	display: block;
}

.btn.information__link {
	text-decoration: none;
}