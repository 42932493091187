@media (max-width: 991px) {
.product {
	.stars {
		margin-right: 0.5rem;
		li {
			margin-right: 0.25rem;
			&:last-child {
				margin-right: 0;
			}
			i {
				width: 1rem;
				height: 1rem;
				background-image: url('../../img/svg/star.svg');
			}
			p {
				margin: 0;
				font-size: 0.75rem;
				line-height: 1rem;
				letter-spacing: 0.005em;
			}
		}
	}
}
.product__kv-back-page {
	display: block;
	position: absolute;
	top: 0.5rem;
	left: 0.75rem;
	width: 1.5rem;
	height: 1.5rem;
	i {
		width: 100%;
		height: 100%;
	}
}
.product__btns-add {
	position: fixed;
	bottom: 2.5rem;
	left: 0;
	width: 100%;
	background: #F8F5F1;
	z-index: 10;
	.wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0.75rem;
		width: 100%;
		height: 4rem;
	}
	.btn {
		padding: 0;
		height: 2rem;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.005em;
	}
	.btn-one {
		width: 19.125rem;
		height: 2.5rem;
	}
	.btn-two {
		width: 2.5rem;
		height: 2.5rem;
	}
}
.product__kv {
	flex-direction: column;
	align-items: center;
	margin-bottom: 0;
}
.product__kv-all-image {
	top: 31.125rem;
	left: auto;
	right: 0.75rem;
	padding: 0.4375rem 0.75rem;
	width: auto;
	height: 2.25rem;
	background: rgba(255, 255, 255, 0.85);
}
.product__kv-slider {
	position: relative;
	top: 0;
	width: 100%;
	height: 35rem;
}
.product__kv-slider-main {
	div {
		height: auto;
	}
	.slide {
		height: auto;
		img {
			height: auto;
		}
	}
	.slick-dots {
		display: flex;
		align-items: center;
		position: absolute;
		bottom: 0.5rem;
		left: 0;
		padding: 0 0.75rem;
		width: 100%;
		li {
			margin-right: 0.75rem;
			width: 100%;
			height: 0.125rem;
			background: rgba(255, 255, 255, 0.25);
			border-radius: 6.25rem;
			&:last-child {
				margin: 0;
			}
			button {
				display: block;
				width: 0;
				height: 100%;
				color: rgba(0, 0, 0, 0);
				font-size: 0;
				line-height: 0;
				background: rgba(255, 255, 255, 1);
				border-radius: 6.25rem;
				border: 0;
				outline: 0;
				opacity: 0;
				box-shadow: none;
			}
		}
	}
}
.body-loaded {
	.product__kv-slider-main {
		.slick-dots {
			li {
				&.slick-active {
					button {
						width: 100%;
						opacity: 1;
						transition: width 5s;
					}
				}
			}
		}
	}
}
.product__kv-slider-controls {
	top: 0.5rem;
	padding: 0 0.75rem;
	transform: translate(0, 0);
	.slick-btn {
		width: 1.5rem;
		height: 1.5rem;
	}
}
.product__kv-info {
	padding: 0.75rem;
	width: 100%;
	min-height: 0;
	background: #F8F5F1;
	border-radius: 0;
	backdrop-filter: none;
	-webkit-backdrop-filter: none;
}
.product__kv-info-title {
	margin-bottom: 0.75rem;
	font-size: 0.9375rem;
	line-height: 1.125rem;
	letter-spacing: 0;
}
.product__kv-info-meta {
	margin-bottom: 0.75rem;
	p {
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.005em;
	}
	.ico-left {
		margin-right: 0.25rem;
		width: 1rem;
		height: 1rem;
	}
}
.product__kv-info-price {
	margin-bottom: 0.5rem;
	padding: 0.25rem 0.75rem;
	border-radius: 0.5rem;
	h6 {
		padding: 0.125rem 0;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.005em;
	}
}
.product__kv-info-price-title {
	margin-bottom: 0;
	h5 {
		margin-right: 0.5rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}
	span {
		margin-right: 0.5rem;
		padding: 0.125rem 0.25rem 0.0625rem;
		font-size: 0.6875rem;
		line-height: 0.8125rem;
		border-radius: 0.25rem;
	}
	p {
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.005em;
	}
}
.product__kv-info-price-col {
	ul {
		li {
			padding: 0.25rem 0;
			&::after {
				height: 0.0625rem;
			}
			p {
				font-size: 0.75rem;
				line-height: 1rem;
				letter-spacing: 0.005em;
				b {
					letter-spacing: 0.005em;
				}
			}
		}
	}
}
.product__kv-info-split {
	margin-bottom: 0.75rem;
	p {
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.005em;
		.ico {
			bottom: 0;
			margin-left: 0.25rem;
			width: 1rem;
			height: 1rem;
		}
	}
	.hint__text {
		top: auto;
		right: auto;
		bottom: -0.125rem;
		left: -0.75rem;
		width: 10.1875rem;
		transform: translate(0, 100%);
	}
}
.product__kv-info-split-title {
	margin-right: 0.5rem;
	padding: 0.3125rem 0.375rem;
	border-radius: 0.25rem;
	img {
		width: 3.375rem;
	}
}
.product__kv-info-model {
	margin-bottom: 0.5rem;
	p {
		margin-bottom: 0.25rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}
}
.product__kv-info-colors {
	&::after {
		width: 3.9375rem;
		border-radius: 0.25rem;
	}
	.swiper-slide {
		margin-right: 0.5rem;
		width: 2.5rem;
		img {
			width: 2.5rem;
			height: 3.75rem;
			border-width: 0.0625rem;
			border-radius: 0.25rem;
		}
	}
}
.product__kv-info-sizes {
	margin-bottom: 0.75rem;
	.top {
		margin-bottom: 0.25rem;
		p {
			margin: 0;
			font-size: 0.8125rem;
			line-height: 1.125rem;
		}
		a {
			font-size: 0.75rem;
			line-height: 1rem;
		}
	}
	ul {
		margin: -0.125rem -0.125rem 0.375rem;
		li {
			margin: 0.125rem;
			a {
				width: 2.25rem;
				height: 2.25rem;
				font-size: 0.875rem;
				border-radius: 0.5rem;
				&.disabled {
					border-width: 0.0625rem;
				}
			}
		}
	}
	ul.modal-mode {
		margin: -0.125rem -0.125rem 0.375rem;
		li {
			margin: 0.125rem;
			a {
				width: 1.4rem;
				height: 1.4rem;
				font-size: 0.6rem;
				border-radius: 0.3rem;
				&.disabled {
					border-width: 0.0625rem;
				}
			}
		}
	}
	.bot {
		p {
			font-size: 0.75rem;
			line-height: 1rem;
		}
	}
}
.product__kv-info-btns {
	margin-bottom: 0.75rem;
	.btn-one {
		margin-right: auto;
		width: 19.1875rem;
		height: 2.5rem;
	}
	.btn-two {
		width: 2.5rem;
		height: 2.5rem;
		.ico {
			width: 1rem;
			height: 1rem;
		}
	}
	.link {
		margin: 0 auto;
		font-size: 0.75rem;
		line-height: 1rem;
	}
	.hint__text {
		bottom: -0.25rem;
		padding: 0.5rem;
		bottom: -0.25rem;
		left: 0;
		width: 22rem;
		transform: translate(-68.5%, 100%);
		p {
			font-size: 0.75rem;
			line-height: 1rem;
		}
	}
}
.product__kv-info-reserv {
	margin: 0;
}
.product__kv-info-cols {
	margin-bottom: 0.75rem;
	padding: 0.5rem 0.75rem;
	border-width: 0.0625rem;
	border-radius: 0.5rem;
}
.product__kv-info-cols-link {
	padding: 0.25rem 0;
	font-size: 0.75rem;
	line-height: 1rem;
	letter-spacing: 0.005em;
}
.product__kv-info-cols-list {
	padding-top: 0.25rem;
	ul {
		li {
			padding: 0.25rem 0;
			border-bottom-width: 0.0625rem;
			.icons {
				top: 0.25rem;
				margin-right: 0.5rem;
				width: 1.25rem;
				height: 1.25rem;
			}
			p {
				font-size: 0.8125rem;
				line-height: 1.125rem;
				b {
					font-size: 0.875rem;
					line-height: 1.1875rem;
				}
			}
		}
	}
}
.product__tabs {
	padding: 0.75rem;
}
.product__tabs-nav {
	.nav-tabs {
		flex-wrap: nowrap;
		margin: 0 -0.75rem 0.75rem;
		position: relative;
		border-bottom-width: 0.0625rem;
		overflow-x: auto;
		li {
			margin-right: 1.5rem;
			&:last-child {
				margin-right: 0;
			}
			.nav-link {
				padding: 0.5rem 0;
				font-size: 1rem;
				line-height: 1.5rem;
			}
		}
	}
}
.product__tabs-cont {
	.row {
		& > div {
			padding: 0;
		}
	}
	.left {
		padding-top: 0;
		height: auto;
		& > .scroll-wrapper {
			height: auto;
			.scrollbar-inner {
				padding-right: 0;
				height: auto !important;
			}
		}
	}
	.right {
		padding: 1.25rem;
		background: #F8F5F1;
		border-radius: 0.5rem;
	}
}
.product__tabs-mob {
	margin-bottom: 1.25rem;
	li {
		border-bottom: 0.0625rem dotted #DCD6D1;
		a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.375rem;
			width: 100%;
			h5 {
				margin: 0;
				font-size: 0.9375rem;
				line-height: 1.125rem;
				span {
					margin-left: 0.25rem;
					font-weight: 500;
					font-size: 0.875rem;
					line-height: 1.1875rem;
				}
			}
		}
	}
}
.product__tabs-mob-collapse {
	position: fixed;
	top: 0;
	right: 0;
	width: 0;
	height: 100%;
	background: #F8F5F1;
	overflow-y: auto;
	transition: 0.3s;
	z-index: 100;
	&.show {
		width: 100%;
	}
	.head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		top: 0;
		left: 0;
		padding: 0.625rem 0.75rem;
		width: 100vw;
		height: 2.5rem;
		background: #F8F5F1;
		z-index: 1;
		.ico {
			flex-shrink: 0;
			width: 1.5rem;
			height: 1.5rem;
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 200%;
				height: 200%;
				opacity: 0;
				transform: translate(-50%, -50%);
			}
		}
		h4 {
			margin: 0;
			padding: 0 0.5rem;
			width: 100%;
			font-size: 1.0625rem;
			line-height: 1.375rem;
			text-align: left;
			span {
				font-weight: 500;
				font-size: 0.875rem;
				line-height: 1.1875rem;
			}
		}
	}
	.product__feedback-wrap {
		position: relative;
		top: 2.5rem;
		padding: 0 0 0.75rem;
		width: 100vw;
		height: calc(100% - 2.5rem);
		& > .scrollbar-inner {
			height: 100%;
		}
	}
	.product__text {
		width: 100vw;
	}
}
.product__description {
	.col-lg-4 {
		order: 1;
		margin-bottom: 0.75rem;
		padding: 0;
		h5 {
			margin-bottom: 0.75rem;
			font-size: 0.9375rem;
			line-height: 1.125rem;
		}
	}
	.right {
		.top {
			height: 0.5rem;
			i {
				width: 0.5rem;
				height: 0.5rem;
			}
		}
		ul {
			li {
				padding: 0.375rem 0;
				border-bottom-width: 0.0625rem;
				p {
					font-size: 0.8125rem;
					line-height: 1.125rem;
					letter-spacing: 0.002em;
				}
			}
		}
	}
	.col-lg-8 {
		order: 2;
		padding: 0;
	}
	.left {
		p {
			margin-bottom: 0;
			margin-top: 0.75rem;
			font-size: 0.875rem;
			line-height: 1.1875rem;
			&:first-child {
				margin-top: 0;
			}
		}
	}
}
.product__description-link {
	display: inline-block !important;
	margin-top: 0.25rem;
	color: var(--c-main);
	font-size: 0.75rem;
	line-height: 1rem;
	letter-spacing: 0.005em;
	span {
		&:nth-child(1) {
			display: block;
		}
		&:nth-child(2) {
			display: none;
		}
	}
	&.active {
		span {
			&:nth-child(1) {
				display: none;
			}
			&:nth-child(2) {
				display: block;
			}
		}
	}
}
.product__feedback-block {
	position: relative;
	margin-bottom: 0.375rem;
	padding: 0.75rem;
	border-radius: 0.5rem;
	.img {
		position: absolute;
		top: 0.75rem;
		left: 0.75rem;
		margin: 0;
		width: 2.6875rem;
		height: 2.6875rem;
	}
	.info {
		width: 100%;
		.top {
			justify-content: flex-start;
			margin-bottom: 0.25rem;
			padding: 0 0 0 3.4375rem;
			height: auto;
			h6 {
				margin: 0 0.75rem 0 0;
				font-size: 0.9375rem;
				line-height: 1.3125rem;
			}
			p {
				margin: 0;
				font-size: 1rem;
				line-height: 1.3125rem;
				letter-spacing: 0.002em;
			}
			:last-child {
				margin-left: 0;
			}
			.btn-col {
				display: block;
				margin-left: auto;
				width: 1rem;
				height: 1rem;
				transform: rotate(180deg);
				transition: 0.3s;
			}
		}
		&.show {
			.top {
				.btn-col {
					transform: rotate(0deg);
				}
			}
		}
		.meta {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-bottom: 0.75rem;
			padding: 0 0 0 3.4375rem;
			.stars {
				li {
					p {
						margin: 0;
						padding-left: 0.625rem;
						font-size: 0.8125rem;
						line-height: 1;
					}
				}
			}
			h6 {
				padding: 0.125rem 0.25rem 0.0625rem;
				font-size: 0.6875rem;
				line-height: 0.8125rem;
				border-radius: 0.25rem;
			}
			p {
				margin: 0 0.75rem 0 0;
				font-size: 0.8125rem;
				line-height: 1.125rem;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.meta__wrap {
			display: flex;
			margin-top: 0.75rem;
			width: 100%;
		}
		.bot {
			display: flex;
			p {
				margin: 0 0.75rem 0 0;
				font-size: 0.8125rem;
				line-height: 1.125rem;
				letter-spacing: 0.002em;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.body {
			margin-bottom: 0.75rem;
			padding-top: 0.75rem;
			p {
				font-size: 0.875rem;
				line-height: 1.1875rem;
			}
		}
		.imgs {
			.img {
				top: 0;
				left: 0;
				margin-right: 0.5rem;
				width: 2.5rem;
				height: 3.75rem;
				border-radius: 0.25rem;
				img {
					border-radius: 0.25rem;
				}
				.ico-play {
					width: 1.5rem;
					height: 1.5rem;
				}
			}
		}
	}
	.meta-pl {
		padding-left: 3.4375rem;
	}
	.stars {
		display: flex;
		align-items: center;
		li {
			display: flex !important;
			align-items: center;
			margin-right: 0.125rem;
			height: 1rem;
			&:last-child {
				margin-right: 0;
			}
			i {
				display: block;
				position: relative;
				width: 1rem;
				height: 1rem;
				background: url('../../img/svg/star-empty.svg') no-repeat 50%/contain;
				&.active {
					background-image: url('../../img/svg/star.svg');
				}
			}
		}
	}
}
.product__feedback {
	.link-more {
		display: flex;
		justify-content: center;
		margin: 0.75rem auto 1.625rem;
		font-size: 0.875rem;
		letter-spacing: 0.002em;
		text-align: center;
	}
	.btn {
		width: 100%;
		height: 2.5rem;
		font-size: 0.75rem;
	}
	.right {
		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 1.25rem;
			width: 100%;
			h5 {
				margin-bottom: 0;
				font-size: 1.5rem;
				line-height: 1.75rem;
			}
		}
		.mid {
			margin-bottom: 1.25rem;
			ul {
				li {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 1rem;
					width: 100%;
					&:last-child {
						margin-bottom: 0;
					}
					p {
						margin: 0;
						font-size: 1.125rem;
						line-height: 1.75rem;
					}
					.line {
						position: relative;
						width: 27.25rem;
						height: 0.25rem;
						background: #fff;
						border-radius: 6.25rem;
						i {
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							background: #DCD6D1;
							border-radius: 6.25rem;
						}
					}
				}
			}
		}
		.bot {
			.btn {
				width: 100%;
			}
		}
	}
}
.product__feedback-wrap {
	.link-more {
		padding: 0 0.75rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		text-decoration: underline;
	}
}
.product__text {
	padding: 0;
}
.product__text-block {
	margin-bottom: 0.375rem;
	padding: 0.75rem;
	background: #F8F5F1;
	border-bottom: 0.0625rem dotted #DCD6D1;
	border-radius: 0;
	&:last-child {
		margin-bottom: 0;
	}
	h3 {
		display: inline-flex;
		align-items: flex-start;
		font-weight: 500;
		font-size: 0.9375rem;
		line-height: 1.3125rem;
		span {
			display: block;
			margin-right: 0.75rem;
			padding: 0;
			width: 1.375rem;
			font-size: 0.75rem;
			line-height: 1.25rem;
			letter-spacing: -0.0025em;
			text-align: center;
			background: #DCD6D1;
			border-radius: 0.25rem;
		}
	}
}
.product__text-block-col {
	padding-top: 0.75rem;
	& > p {
		margin-bottom: 0.75rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}
	& > ul {
		margin-bottom: 0.75rem;
		li {
			display: flex;
			align-items: flex-start;
			position: relative;
			margin-bottom: 0;
			font-size: 0.875rem;
			line-height: 1.1875rem;
			&::before {
				content: '•';
				padding: 0 0.5rem;
			}
		}
	}
	blockquote {
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: flex-start;
		padding: 0.5rem;
		background: #fff;
		border-radius: 0.5rem;
		.ico {
			flex-shrink: 0;
			width: 1.25rem;
			height: 1.25rem;
		}
		span {
			margin: 0;
			font-size: 0.875rem;
			line-height: 1.1875rem;
		}
	}
	& > :last-child {
		margin-bottom: 0;
	}
}
.main-product {
	.now-buy--big {
		.point {
			top: -16rem;
		}
	}
}
.product__page {
	padding: 0;
	.row {
		align-items: flex-end;
		margin-bottom: 1.5rem;
	}
	.col-lg-6 {
		order: 1;
		margin-bottom: 0.5rem;
	}
	.col-lg-4 {
		order: 2;
	}
	img {
		height: 11.25rem;
		object-fit: cover;
		border-radius: 0.25rem;
	}
	h3 {
		margin-bottom: 0.5rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
		letter-spacing: 0;
	}
	p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}
}

}