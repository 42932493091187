@media (max-width: 991px) {
	.kv {
		padding: 0;
	}

	.kv .kv__wrap .kv__slider-dots {
		bottom: 0;
		right: 0;
		padding: 0.5rem 0;
		width: 100%;
	}

	.kv .kv__wrap .kv__slider-dots li {
		margin-left: auto;
	}

	.kv .kv__wrap .kv__slider-dots li button {
		padding: 0;
		width: 3.125rem;
		height: 0.125rem;
		background: rgba(255, 255, 255, 0.25);
		border: 0;
		border-radius: 6.25rem;
	}

	.kv .kv__wrap .kv__slider-dots li .progress-circle {
		display: block;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background: #fff;
		border-radius: 0;
		transform: translate(0%, 0%);
		opacity: 0;
		transition: 0;
	}

	.kv__slider {
		margin-left: -0.75rem;
		width: 100vw;
	}

	.kv__slider .slide-wrap {
		padding: 1.75rem 0.75rem;
		height: 30rem;
	}

	.body-loaded .kv .kv__wrap .kv__slider-dots li.swiper-pagination-bullet-active .progress-circle {
		opacity: 1;
		width: 100%;
		transition: width 7s;
	}
}