@import "scss/desk/addittional.scss";
@import "scss/desk/advantages-list.scss";
@import "scss/desk/basket.scss";
@import "scss/desk/blog.scss";
@import "scss/desk/catalog.scss";
@import "scss/desk/choose.scss";
@import "scss/desk/company.scss";
@import "scss/desk/collapse-menu.scss";
@import "scss/desk/common.scss";
@import "scss/desk/delivery.scss";
@import "scss/desk/footer.scss";
@import "scss/desk/head-line.scss";
@import "scss/desk/header.scss";
@import "scss/desk/information.scss";
@import "scss/desk/kv-slider.scss";
@import "scss/desk/line-page.scss";
@import "scss/desk/lk.scss";
@import "scss/desk/look.scss";
@import "scss/desk/modals.scss";
@import "scss/desk/notification.scss";
@import "scss/desk/now-buy.scss";
@import "scss/desk/product.scss";
@import "scss/desk/product-numbers.scss";
@import "scss/desk/search-board.scss";
@import "scss/desk/section-who.scss";
@import "scss/desk/shops.scss";
@import "scss/desk/stocks.scss";
@import "scss/desk/submenu-product.scss";
@import "scss/desk/subscribe.scss";
@import "scss/desk/text-page.scss";
@import "scss/desk/custom.scss";

@import "scss/mob/addittional.scss";
@import "scss/mob/advantages-list.scss";
@import "scss/mob/basket.scss";
@import "scss/mob/blog.scss";
@import "scss/mob/catalog.scss";
@import "scss/mob/choose.scss";
@import "scss/mob/collapse-menu.scss";
@import "scss/mob/common.scss";
@import "scss/mob/company.scss";
@import "scss/mob/delivery.scss";
@import "scss/mob/footer.scss";
@import "scss/mob/head-line.scss";
@import "scss/mob/header.scss";
@import "scss/mob/information.scss";
@import "scss/mob/kv-slider.scss";
@import "scss/mob/line-page.scss";
@import "scss/mob/lk.scss";
@import "scss/mob/look.scss";
@import "scss/mob/mobile-menu.scss";
@import "scss/mob/modals.scss";
@import "scss/mob/notification.scss";
@import "scss/mob/now-buy.scss";
@import "scss/mob/product.scss";
@import "scss/mob/product-numbers.scss";
@import "scss/mob/search-board.scss";
@import "scss/mob/section-who.scss";
@import "scss/mob/shops.scss";
@import "scss/mob/stocks.scss";
@import "scss/mob/submenu-product.scss";
@import "scss/mob/subscribe.scss";
@import "scss/mob/text-page.scss";
@import "scss/mob/custom.scss";

@import "react-datepicker/dist/react-datepicker.css";

.pointer {
  cursor: pointer;
}

.dashed {
  text-decoration: underline;
  text-decoration-style: dashed;
}

.card__basket span.basket-size {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.25rem;
  width: 2.25rem;
  height: 2.25rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.002em;
  background: #fff;
  border-radius: 0.5rem;
}

.btn-block {
  width: 100%;
}

.card__basket span.basket-size:hover {
  color: #fff;
  background: var(--c-main);
  text-decoration: none;
}

.filters {
  margin-bottom: 5rem !important;
}

.form-checkbox.active .label-dot, .form-radio.active .label-dot {
  border-color: var(--c-main);
}

.form-checkbox.active .label-dot::after, .form-radio.active .label-dot::after {
  opacity: 1;
}

.catalog__tags-list a.active, .product__kv-info-sizes a.active, .card__basket .basket-size.active {
  background-color: var(--c-main);
  color: white;
}

.modal-main-system.show, .modal-main.show {
  display: block;
}

// .head-line {
//   max-height: 30px;
// }

.modal-backdrop {
  z-index: 50;
}

.progress, .progress-stacked {
  height: 0.35rem;
}

.catalog__list {
  margin-top: 30px;
}

.model-size-disabled {
  color: #D9D9D9 !important;
  //border: 0.0625rem solid #D9D9D9;
  opacity: 1;
  pointer-events: none;
  cursor: default;
}

.advantages-list__slider .swiper-wrapper {
  overflow: scroll;
}

.shops__map-info {
  min-height: 400px;
}

.all-filters-sizes .form-checkbox.disabled {
  cursor: default;
  color: #c9c9c9;
  background: #f1f1f1;
  border-radius: 5px;

  .label-dot {
    cursor: default;
    cursor: default;
  }
}

.all-filters-minda {
  width: 90rem;
  z-index: 120;
}


.nobr {
  white-space: nowrap;
}

.dealer-size-control {
  width: 40px;
  margin: 0 auto;
}

.dealer-color {
  width: 100px;
  height: 50px;
  border: 1px solid #cccccc;

  margin: 0 auto;

  background-size: cover !important;
}

.dealer-color-2 {
  border: 1px solid #cccccc;
  display: inline-block;
  margin-right: 5px;
  width: 50px;
  height: 50px;
  background-size: cover !important;
}

.dealer-color-order-position {
  width: 40px;
  height: 20px;
  border: 1px solid #cccccc;
  float: left;
  margin-right: 10px;

  background-size: cover !important;
}

.bg-success-lighter {
  background-color: #d4edda !important;
}

.bg-warning-lighter {
  background-color: #fff3cd !important;
}

.bg-danger-lighter {
  background-color: #f8d7da !important;
}

.print-body {
  padding: 40px 20px;

  h1, .h1 {
    font-size: 80%;
  }

  h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
    font-size: 60%;
  }

  table.table-bordered > thead > tr > th {
    border: 1px solid black;
  }

  .d-md-block {
    margin-top: 0px !important;
  }

 b, span {
   font-size: 40%;
 }

  table {
    border-color: black;

    font-size: 40%;

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      font-size: 110%;
    }

    td, th {
      padding: 0.3rem .5rem;
    }
  }
}

.btn.btn-small {
  padding: 0.5rem 1.875rem;
}

.size-edit-table {
  padding: 0.5rem 0.3rem;
}

// .scrollbar-inner {
//   overflow: scroll;
// }