.gridImages {
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 15px;
	@media screen and (max-width: 768px) {
		grid-template-rows: 1fr;
		grid-template-columns: 1fr;
	}
	.gridImage {
		width: 100%;
		height: auto;
		object-fit: cover;
		@media screen and (max-width: 768px) {
			width: 100%;
			height: unset;
		}
	}
}
