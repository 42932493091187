.banners {
	.banner {
		height: 29.75rem;
	}
}
.collections {
	.banner {
		height: 28.5rem;
	}
	.banner--big {
		height: 58.5rem;
	}
}
.advantages {
	.banner {
		align-items: center;
		justify-content: center;
		height: 12.5rem;
		text-align: center;
		&:hover {
			color: #fff;
			text-decoration: none;
		}
		h5 {
			margin: 0;
			color: #fff;
			font-size: 1.5rem;
			line-height: 1.75rem;
			letter-spacing: -0.01em;
		}
	}
}
.shops {
	.banner {
		height: 60.25rem;
	}
}
