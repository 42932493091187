.rowBlock {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		gap: 0;
	}
}

.reverse {
	flex-direction: row-reverse;
	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
}

.rowBlockText {
	.title {
		text-align: center;
		margin-top: 30px;
		margin-bottom: 0px;
		font-size: 25px;
		line-height: 1.6;
		@media screen and (max-width: 768px) {
			font-size: 21px;
			line-height: 1.4;
		}
	}
	.text {
		margin: 30px 10%;
		width: 80%;
		font-size: 17px;
		line-height: 1.6;
		@media screen and (max-width: 768px) {
			margin: 20px 10%;
			font-size: 18px;
		}
	}
	.btn {
		// color: #d0ac6c;
		// background-color: white;
		// text-transform: uppercase;
		// min-width: 145px;
		// padding: 11px 0px;
		// min-width: 145px;
		// box-shadow: none;
		// border: 1px solid #d0ac6c;
		// margin: 30px 0;
		// @media screen and (max-width: 768px) {
		// 	margin: 20px 0;
		// }
	}
}
