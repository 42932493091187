@media (max-width: 991px) {
	.basket {
		padding-top: 0.75rem;
	}

	.basket .site-title {
		margin-bottom: 0.75rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
	}

	.basket__main {
		margin-bottom: 1.25rem;
		padding: 0;
	}

	.basket__products {
		margin-bottom: 0.75rem;
		border: 0;
		border-bottom: 0.0625rem solid #DCD6D1;
		border-radius: 0;
	}

	.basket__products .top {
		margin-bottom: 0.75rem;
		padding: 0.75rem;
		border-top: 0.0625rem solid #DCD6D1;
		border-bottom: 0.0625rem solid #DCD6D1;
		border-radius: 0.5rem 0.5rem 0 0;
	}

	.basket__products .top .basket-all-checks {
		order: 2;
		margin-left: auto;
		margin-right: 0;
	}

	.basket__products .top .ico {
		width: 1.5rem;
		height: 1.5rem;
	}

	.basket__products .top .form-checkbox {
		align-items: center;
	}

	.basket__products .top .form-checkbox .label-dot {
		width: 1.5rem;
		height: 1.5rem;
	}

	.basket__products-block {
		align-items: flex-start;
		position: relative;
		padding: 0.75rem;
	}

	.basket__products-block .form-checkbox {
		position: absolute;
		top: 0.75rem;
		right: 0.75rem;
		margin: 0;
	}

	.basket__products-block .img {
		position: absolute;
		top: 0.75rem;
		left: 0.75rem;
		margin: 0;
		width: 2.5rem;
		height: 3.5rem;
		border-radius: 0.25rem;
	}

	.basket__products-block .img img {
		border-radius: 0.25rem;
	}

	.basket__products-block .info {
		padding: 0 0 0 3.25rem;
		width: 100%;
	}

	.basket__products-block .info .text {
		flex-direction: column;
		justify-content: flex-start;
		margin-bottom: 0.75rem;
	}

	.basket__products-block .info .text .left {
		margin-bottom: 0.5rem;
		padding: 0;
	}

	.basket__products-block .info .text .left h4 {
		display: block;
		display: -webkit-box;
		margin-bottom: 0.5rem;
		padding-right: 2.25rem;
		height: 2.375rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.basket__products-block .info .text .left ul {
		margin-bottom: 0.5rem;
	}

	.basket__products-block .info .text .left ul li {
		margin-right: 0.75rem;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
	}

	.basket__products-block .info .text .left ul li:last-child {
		margin-right: 0;
	}

	.basket__products-block .info .text .left p {
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
	}

	.basket__products-block .info .text .right {
		display: flex;
		padding: 0;
	}

	.basket__products-block .info .text .right p {
		margin: 0 0.5rem 0 0;
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.basket__products-block .info .text .right p:first-child {
		margin: 0 0.5rem 0 0;
	}

	.basket__products-block .info .meta {
		align-items: center;
	}

	.basket__products-block .info .meta .left a {
		margin-right: 0.75rem;
	}

	.basket__products-block .info .meta .left a .ico {
		width: 1.5rem;
		height: 1.5rem;
	}

	.basket__form {
		margin-bottom: 0.75rem;
		padding: 1rem 0.75rem;
	}

	.basket__form:last-child {
		margin-bottom: 0;
	}

	.basket__form .tab-content {
		margin-bottom: 0.75rem;
	}

	.basket__form form .form-annotation {
		margin: 0;
	}

	.basket__form form .form-selector {
		max-width: 100%;
	}

	.basket__form form .form-selector.big .form-selector--btn {
		padding: 0 0.75rem;
	}

	.basket__form form .form-selector.small .form-selector--btn {
		padding: 0 0.5rem;
	}

	.basket__form form .form-selector--btn {
		height: 2.5rem;
		font-size: 0.8125rem;
		line-height: 1.125rem;
		letter-spacing: 0.0002em;
	}

	.basket__form form .form-selector--btn::after {
		margin: 0 0.25rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	.basket__form form .form-btns .btn {
		width: 100%;
	}

	.basket__form .nav-tabs {
		margin-bottom: 0.75rem;
		padding: 0.125rem;
		width: 100%;
		border-radius: 0.5rem;
	}

	.basket__form .nav-tabs li {
		width: calc(50% - (0.125rem / 2));
	}

	.basket__form .nav-tabs li:not(:last-child) {
		margin-right: auto;
	}

	.basket__form .nav-tabs .nav-link {
		width: 100%;
		height: 2.5rem;
		font-size: 0.75rem;
		line-height: 1rem;
		border-radius: 0.5rem;
	}

	.basket__form-title {
		margin-bottom: 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.basket__date-time ul {
		margin: 0 0 0.75rem -0.75rem;
		padding: 0 0.75rem;
		width: 100vw;
		overflow-x: auto;
	}

	.basket__date-time ul li {
		margin-right: 0.25rem;
	}

	.basket__date-time ul li a {
		padding: 0.5rem;
		width: 7rem;
		height: 3.5rem;
	}

	.basket__date-time ul li a p {
		margin-bottom: 0;
		font-size: 0.875rem;
		line-height: 1.25rem;
		letter-spacing: 0.0005em;
	}

	.basket__date-time ul li a small {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.punkt-vidachi {
		padding: 1.25rem 0 0;
	}

	.punkt-vidachi h6 {
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.punkt-vidachi p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.punkt-vidachi ul {
		margin-bottom: 1rem;
	}

	.punkt-vidachi ul li {
		margin-bottom: 0.5rem;
	}

	.punkt-vidachi ul li .ico {
		margin-top: -0.125rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	.punkt-vidachi .btn {
		margin-top: 1rem;
	}

	.basket__aside {
		position: sticky;
		top: 6.875rem;
		right: 0;
	}

	.basket__promocode {
		margin-bottom: 1.5rem;
	}

	.basket__promocode .form-control {
		height: 3.25rem;
	}

	.basket__promocode .form-group .btn {
		right: 0.375rem;
		width: 2.5rem;
		height: 2.5rem;
	}

	.basket__order {
		margin-left: -0.75rem;
		padding: 1rem 0.75rem;
		width: 100vw;
		border-radius: 0.5rem;
	}

	.basket__order .form-btns {
		margin-bottom: 0.75rem;
	}

	.basket__order .form-btns .btn {
		height: 2.5rem;
		font-size: 0.75rem;
	}

	.basket__order-all {
		padding: 0 0 0.5rem;
		border-bottom-width: 0.0625rem;
	}

	.basket__order-all p {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.basket__order-discount {
		margin-bottom: 0.5rem;
	}

	.basket__order-discount h6 {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.basket__order-discount a {
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.basket__order-discount li {
		margin-top: 0.25rem;
	}

	.basket__order-discount p {
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
	}

	.basket__order-delivery {
		margin-bottom: 0.75rem;
		padding: 0.25rem 0;
	}

	.basket__order-delivery p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.basket__order-total {
		margin-bottom: 1rem;
	}

	.basket__order-total li:not(:last-child) {
		margin-bottom: 0.25rem;
	}

	.basket__order-total h6 {
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.basket__order-total p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.basket__order-iframe {
		margin-bottom: 1rem;
	}

	.basket__order-iframe img,
	.basket__order-iframe iframe {
		height: 7.625rem;
		border-radius: 0.5rem;
	}

	.basket__order-annotation p {
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.order__confirmed-top {
		margin-bottom: 1rem;
	}

	.order__confirmed-top div {
		margin-bottom: 1rem;
	}

	.order__confirmed-top div h6 {
		margin-bottom: 0.25rem;
	}

	.order__confirmed-top ul {
		display: flex;
		align-items: center;
	}

	.order__confirmed-top ul li {
		display: flex;
		align-items: center;
		margin-right: 1rem;
	}

	.order__confirmed-top ul li > * {
		margin: 0;
	}

	.order__confirmed-top h6 {
		margin-bottom: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.order__confirmed-top p {
		margin: 0.5rem 0;
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.order__confirmed-top small {
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.0005em;
	}

	.order__confirmed-products {
		margin-bottom: 1rem;
	}

	.order__confirmed-products .basket__products-block {
		margin-bottom: 0.25rem;
	}

	.order__confirmed-products .basket__products-block .img {
		width: 2.5rem;
		height: 3.5rem;
	}

	.order__confirmed-bonuses {
		margin-bottom: 1.25rem;
		padding: 1rem;
		border-radius: 0.5rem;
	}

	.order__confirmed-bonuses h4 {
		margin-bottom: 0.25rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.order__confirmed-bonuses p {
		margin: 0;
		font-size: 0.8125rem;
		line-height: 1.125rem;
	}

	.order__confirmed-btns {
		flex-direction: column;
		justify-content: flex-start;
		margin: 0;
		padding: 1rem 0;
	}

	.order__confirmed-btns .btn {
		margin-bottom: 0.5rem;
		width: 100%;
		height: 2.5rem;
		font-size: 0.75rem;
	}

	.order__confirmed-btns .btn:last-child {
		margin-bottom: 0;
	}
}