@media (max-width: 991px) {
	.banners .banner {
		margin-bottom: 0.75rem;
		height: 11.25rem;
	}

	.banners .row > :last-child .banner {
		margin-bottom: 0;
	}

	.collections .banner--big {
		margin-bottom: 0.5rem;
		height: 21.875rem;
	}

	.collections .banner--big .banner__title {
		font-size: 4rem;
		line-height: 1;
	}

	.collections .banner__slider .banner {
		margin-right: 0.75rem;
		width: 10.25rem;
		height: 10.25rem;
	}

	.collections .banner__slider .banner:last-child {
		margin-right: 0;
	}

	.collections--pb .page-title {
		margin-bottom: 0.5rem;
		padding-bottom: 2.625rem;
	}

	.advantages .banner {
		margin-right: 0.5rem;
		width: 14.5rem;
		height: 6.25rem;
	}

	.advantages .banner:last-child {
		margin-right: 0;
	}

	.advantages .banner h5 {
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.shops .banner {
		height: 21.875rem;
	}
}