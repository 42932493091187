.blockSubscribe{
	@media screen and (max-width: 768px) {
			padding: 0 15px;
		}
	.blockSubscribe_header{
		margin-bottom: 20px;
		font-size: 18px;
	}
	p{
 font-size: 16px;
 width: 40%;
	@media screen and (max-width: 768px) {
		width: 100%;
		font-size: 14px;
		}
	}
	.blockSubscribe_email{
		width: 100%;
	}
	.blockSubscribe_form{
		margin-top: 30px;
			
				display: flex;
				justify-content: space-between;
				input{
					width: 100%;
					height: 100%;
				}
	// button {
	// 	background-color: #d0ac6c;
	// 	color: white;
	// 	padding: 14px 14px;
	// 	text-transform: uppercase;
	// 	font-size: 80%;
	// 	letter-spacing: 1px;
	// 	text-decoration: none;
	// 	cursor: pointer;
	// 	white-space: nowrap;
	// 	border: navajowhite;
	// }
	}

}

.successMessage{
	margin-top: 15px;
}

			