.rowBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		gap: 0;
	}
}

.rowBlockElem {
	img {
		width: 100%;
		object-fit: cover;

		@media screen and (max-width: 768px) {
			height: unset;
		}
	}
	.title {
		text-align: center;
		margin-top: 30px;
		margin-bottom: 0px;
		font-size: 25px;
		@media screen and (max-width: 768px) {
			line-height: 1.4;
		}
	}
	.text {
		margin: 30px 0px;
		line-height: 1.6;
		font-size: 17px;
		@media screen and (max-width: 768px) {
			margin: 20px 0;
			font-size: 18px;
		}
	}
}
