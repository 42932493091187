.landing {
    min-width: 969px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1%;

    @media (max-width: 991px) {
        min-width: unset;
        max-width: unset;
        padding: 0;
    }

    .model-label {
        width: 100%;
        background: #0a0a0a;
        color: white;
        cursor: pointer;
        padding: 15px 0;
        text-align: center;
    }

    .w70 {
        margin-left: 15%;
        width: 70%;
        @media (max-width: 991px) {
            margin-left: 5%;
            width: 90%;
        }
    }

    .small-text,
    .small-text p {
        font-size: 15px;
        line-height: 1.6;
    }

    @media (max-width: 991px) {
        .small-text {
            width: 90%;
            margin-left: 5%;
            text-align: center;
        }
    }

    .carousel-container {
        // margin: 0px 82px;
        display: flex;
        align-items: center;
        justify-content: center;
        .swiper {
            img {
                width: 100%;
            }
        }
        .swiper-button:first-child {
            left: 0;
        }
        .swiper-button:last-child {
            right: 0;
        }
    }
    @media (max-width: 641px) {
        .carousel-container {
            margin: 0;
            display: block;
            width: 100%;
            img {
                width: 100%;
            }
        }
    }

    .slider-swipe-pic {
        display: none;
        img {
            width: 100%;
        }
        @media (max-width: 768px) {
            display: block;
        }
    }

    .carousel-container.sliderBanner {
        position: relative;
        margin: 0;
    }

    .carousel-container .sliderBanner_left {
        position: absolute;
        z-index: 2;
        left: 60px;
        margin: 0;
        padding: 0;
    }

    .carousel-container .sliderBanner_right {
        position: absolute;
        z-index: 2;
        right: 60px;
        margin: 0;
        padding: 0;
    }

    .swiper-button {
        position: absolute;
        z-index: 2;
        margin: 8px 40px;
        padding: 6px 15px;
        cursor: pointer;
    }
    @media (max-width: 991px) {
        .swiper-button {
            display: none;
        }
    }

    .slLabel a {
        color: #fff !important;
        text-decoration: none !important;
    }
    .slLabel a:hover {
        text-decoration: underline !important;
    }

    .swiper-pagination .swiper-pagination-bullet:focus {
        outline: none;
    }

    .swiper-pagination-clickable .swiper-pagination-bullet {
        cursor: auto;
    }

    .swiper-button-prev:focus,
    .swiper-button-next:focus {
        outline: none;
    }

    .swiper-button-prev,
    .swiper-button-next {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .btn {
        text-transform: uppercase;
        min-width: 145px;
        // padding: 11px 0px;
        min-width: 145px;
        margin: 0;
    }

    .btn.btn-gold {
        color: #d0ac6c;
        border-color: #d0ac6c;
        background-color: white;
    }

    .btn.btn-gold-fill {
        color: white;
        border-color: #d0ac6c;
        background-color: #d0ac6c;
    }

    .btn.btn-red {
        color: #bf0036;
        border-color: #bf0036;
        background-color: white;
    }

    .btn.btn-red-fill {
        color: white;
        border-color: #bf0036;
        background-color: #bf0036;
    }

    .btn.btn-grey {
        color: #87888c;
        border-color: #87888c;
        background-color: white;
    }

    .btn.btn-grey-fill {
        color: white;
        border-color: #87888c;
        background-color: #87888c;
    }

    .btn.btn-purple {
        color: #582867;
        border-color: #582867;
        background-color: white;
    }

    .btn.btn-purple-fill {
        color: white;
        border-color: #582867;
        background-color: #582867;
    }

    .btn-long {
        min-width: 215px;
    }

    .pag {
        position: absolute;
        z-index: 2;
        bottom: 30px;
        left: 50%;
        right: -50%;
        font-size: 28px;
    }

    .sliderBanner__sm .sliderBanner_left {
        left: 10px;
    }

    .sliderBanner__sm .sliderBanner_right {
        right: 10px;
    }
    .landingVideo {
        width: 100%;
        &.videoDesc {
            display: block;
            @media (max-width: 768px) {
                display: none;
            }
        }
        &.videoMob {
            display: none;
            @media (max-width: 991px) {
                display: block;
            }
        }
    }
    .landingVideoFull {
        width: 100%;

        &.videoDesc {
            @media (max-width: 991px) {
                display: none;
            }
        }

        &.videoMob {
            display: none;

            @media (max-width: 991px) {
                display: block;
            }
        }
    }

    .w100 {
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .timerBLock .blue {
        background-color: #1f243a;
    }

    .timerBLock .yellow {
        background-color: #d0ac6c;
    }

    .timerBLock .red {
        background-color: #bf0036;
    }

    .timerBLock .grey {
        background-color: #87888c;
    }

    .timerBLock .purple {
        background-color: #582867;
    }

    .timerBLock .white {
        background-color: #fff;
        .digit {
            color: #000;
        }
    }

    .subscribeCst.black {
        .subscribe-button {
            background-color: #000;
        }
    }

    .subscribeCst.grey {
        .subscribe-button {
            background-color: #87888c;
        }
    }
}

.landing-img-btn {
    @media (max-width: 991px) {
        max-width: 290px;
    }
}
