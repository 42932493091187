@media (max-width: 991px) {
	header {
		top: 1.75rem;
	}

	header .navbar {
		position: relative;
		padding: 0;
		height: 2.5rem;
		border-radius: 0;
		z-index: 20;
	}

	header .navbar-toggler {
		position: relative;
		padding: 0;
		width: 1.5rem;
		height: 1.5rem;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		z-index: 11;
	}

	header .navbar-toggler:focus {
		box-shadow: none;
	}

	header .navbar-toggler i {
		display: block;
		position: absolute;
		left: 50%;
		height: 0.0625rem;
		background: var(--c-main);
		border-radius: 6.25rem;
		transition: 0.3s;
	}

	header .navbar-toggler i:nth-child(1) {
		width: 100%;
		top: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	header .navbar-toggler i:nth-child(2) {
		width: 100%;
		top: 50%;
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	header .navbar-toggler.collapsed i:nth-child(1) {
		width: 1rem;
		top: calc(50% - 0.25rem);
		transform: translate(-50%, -50%);
	}

	header .navbar-toggler.collapsed i:nth-child(2) {
		width: 1rem;
		top: calc(50% + 0.25rem);
		transform: translate(-50%, -50%);
	}

	header .navbar-links {
		position: relative;
		z-index: 11;
	}

	header .navbar-links li {
		display: flex;
		margin: 0;
	}

	header .navbar-brand {
		width: 4.875rem;
		height: 1.375rem;
	}

	header .navbar-expand-lg .navbar-collapse {
		position: absolute;
		top: 2.5rem;
		left: -0.75rem;
		width: 100vw;
		background: rgba(255, 255, 255, 0.85);
		backdrop-filter: none;
		-webkit-backdrop-filter: none;
	}

	header .navbar-expand-lg .collapse__wrap {
		height: calc(100vh - 1.75rem - 2.5rem);
		height: calc(100dvh - 1.75rem - 2.5rem);
		transition: 0.3s;
		overflow-y: auto;
	}

	header .navbar-expand-lg .collapse__inner {
		position: relative;
		overflow: hidden;
	}

	header .navbar-expand-lg .navbar-nav {
		position: absolute;
		top: 0.75rem;
		left: 0.75rem;
		margin: 0 auto;
		width: calc(100% - (0.75rem * 2));
		flex-direction: row;
		background: #fff;
		border-radius: 0.5rem;
		transition: 0.3s;
		z-index: 11;
	}

	header .navbar-expand-lg .navbar-nav .nav-item {
		margin: 0;
		width: 33.33%;
	}

	header .navbar-expand-lg .navbar-nav .nav-link {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.75rem 0;
		width: 100%;
		font-size: 0.6875rem;
		line-height: 1.2;
		text-align: center;
		pointer-events: none;
	}

	header .navbar-expand-lg .navbar-nav .nav-link.collapsed {
		pointer-events: auto;
	}

	header .navbar-collapse.active .collapse__wrap {
		overflow: hidden;
	}

	header .navbar-collapse.active .navbar-nav {
		opacity: 0;
		pointer-events: none;
	}

	header .navbar-icons {
		position: relative;
		padding: 0;
		z-index: 11;
	}

	header .navbar-icons li {
		margin-left: 0;
	}

	header .navbar-icons li a .ico {
		width: 1.5rem;
		height: 1.5rem;
	}
}