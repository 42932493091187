@media (max-width: 991px) {
	.product-numbers {
		height: 1.5rem;
		border-radius: 0.5rem;
	}

	.product-numbers::before {
		border-width: 0.0625rem;
		border-radius: 0.5rem;
	}

	.product-numbers .option {
		width: 1.5rem;
	}

	.product-numbers .opt--number {
		font-size: 0.75rem;
	}

	.product-numbers .opt--value::before,
  .product-numbers .opt--value::after {
		background-size: 1rem;
	}
}