.head-line {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	color: #fff;
	background: var(--c-main);
	z-index: 101;
}

.head-line__slider {
	display: flex;
	align-items: center;
	align-content: center;
	position: relative;
}

.head-line__slider .swiper-pagination {
	display: flex;
	position: absolute;
	bottom: auto;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	z-index: 1;
	pointer-events: none;
}

.head-line__slider .swiper-pagination .swiper-pagination-bullet {
	display: block;
	margin: 0 0.25rem 0 0;
	padding: 0;
	width: 0.75rem;
	height: 0.75rem;
	color: transparent;
	font-size: 0;
	line-height: 0;
	background: transparent;
	border: 0.0625rem solid #fff;
	border-radius: 50%;
	opacity: 1;
	cursor: pointer;
	pointer-events: auto;
}

.head-line__slider .swiper-pagination .swiper-pagination-bullet-active {
	background: #fff;
}

.head-line__slider .head-line-bullets .dots .swiper-pagination-bullet {
	display: block;
	margin: 0 0.25rem 0 0;
	padding: 0;
	width: 0.75rem;
	height: 0.75rem;
	color: transparent;
	font-size: 0;
	line-height: 0;
	background: transparent;
	border: 0.0625rem solid #fff;
	border-radius: 50%;
	opacity: 1;
	cursor: pointer;
	pointer-events: auto;
}

.head-line__slider .head-line-bullets .dots .swiper-pagination-bullet-active {
	background: #fff;
}

.head-line__slider .swiper-slide {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1.75rem;
	text-align: center;
}

.head-line__slider .swiper-slide p {
	margin-bottom: 0;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.005em;
	white-space: nowrap;
}

.head-line__slider .head-line-bullets {
	position: absolute;
	bottom: auto;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	z-index: 1;
	pointer-events: none;
}

.head-line__slider .dots {
	display: flex;
}

.head-line__slider .head-line-bullets .swiper-pagination-bullet {
	display: block;
	margin: 0 0.25rem 0 0;
	padding: 0;
	width: 0.75rem;
	height: 0.75rem;
	color: rgba(0, 0, 0, 0);
	font-size: 0;
	line-height: 0;
	background: rgba(0, 0, 0, 0);
	border: 0.0625rem solid #fff;
	border-radius: 50%;
	opacity: 1;
	cursor: pointer;
	pointer-events: auto;
}

.head-line__slider .head-line-bullets .swiper-pagination-bullet-active {
	background: #fff;
}

.head-line__slider .swiper-slide {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1.75rem;
	text-align: center;
}

.head-line__slider .swiper-slide p {
	margin-bottom: 0;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.005em;
	white-space: nowrap;
}