@media (max-width: 991px) {
	.now-buy__slider {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
	}

	.main-product .now-buy__slider {
		padding: 0 0.75rem;
	}

	.now-buy__slide {
		flex-shrink: 0;
		margin-right: 0.5rem;
		padding: 0;
		width: 10.75rem;
	}

	.card__img {
		margin-bottom: 0.5rem;
		height: 15.625rem;
	}

	.card__img picture img {
		border-radius: 0.5rem;
	}

	.card__img .note {
		padding: 0.125rem 0.25rem;
		font-size: 0.6875rem;
		line-height: 0.8125rem;
		letter-spacing: 0.05em;
		border-radius: 0.25rem;
	}

	.card__img .top ul li:not(:last-child) {
		margin-bottom: 0.25rem;
	}

	.card__img .top .like {
		width: 1.5rem;
		height: 1.5rem;
	}

	.card__img .bot__notes > ul {
		display: flex;
		overflow-x: auto;
		pointer-events: auto;
	}

	.card__img .bot__notes > ul li {
		margin-top: 0;
		margin-right: 0.25rem;
	}

	.now-buy--big .card__img {
		height: 15.625rem;
	}

	.card__meta {
		margin-bottom: 0.5rem;
	}

	.card__meta li {
		margin-right: 0.5rem;
	}

	.card__meta li .ico {
		margin-right: 0.25rem;
		width: 1rem;
		height: 1rem;
	}

	.card__meta li p {
		top: 0;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.05em;
	}

	.card__text {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		margin: 0 0 0.5rem;
		max-height: 2.375rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.card__price {
		margin: 0 0 0.5rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.card__price span {
		margin-right: 0.25rem;
	}

	.card__colors li {
		margin-right: 0.25rem;
		width: 0.75rem;
		height: 0.75rem;
		border-width: 0.0625rem;
	}

	.card__colors li i {
		width: 0.5rem;
		height: 0.5rem;
	}

	.card__colors .card__colors-last {
		margin-bottom: 0;
		width: auto;
		height: auto;
	}

	.card__colors .card__colors-last p {
		margin-bottom: 0;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.005em;
	}
}