@media (max-width: 991px) {
	.head-line__slider .swiper-pagination .swiper-pagination-bullet {
			margin: 0 0.25rem 0 0;
			width: 0.5rem;
			height: 0.5rem;
			border-width: 0.0625rem;
		}
	
		.head-line__slider .head-line-bullets .dots .swiper-pagination-bullet {
			margin: 0 0.25rem 0 0;
			width: 0.5rem;
			height: 0.5rem;
			border-width: 0.0625rem;
		}
	
		.head-line__slider .swiper-slide {
			height: 1.75rem;
		}
	
		.head-line__slider .swiper-slide p {
			font-size: 0.6875rem;
			line-height: 0.875rem;
			text-align: right;
		}
}