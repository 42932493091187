@media (max-width: 991px) {
.shops {
	&.pt {
		padding: 0;
	}
}
.shops__map-title {
	margin-bottom: 0.75rem;
	font-size: 1.0625rem;
	line-height: 1.375rem;
}
.shops__map-iframe {
	order: 1;
	padding: 0;
	width: 100%;
	height: 40vh;
}
.shops__map-info {
	order: 2;
	padding-top: 1.25rem;
	padding-bottom: 1rem;
	height: auto;
	form {
		margin-bottom: 0.75rem;
		.form-wrap {
			flex-wrap: nowrap;
			margin-top: -0.5rem;
			margin-left: -0.75rem;
			margin-bottom: -14.625rem !important;
			padding: 0.5rem 0.75rem 14.625rem;
			width: 100vw;
			overflow-x: auto;
			.form-selector {
				margin-right: 0.5rem;
				&:last-child {
					margin-right: 0;
				}
				&:nth-child(2) {
					.form-selector--list {
						left: auto;
						right: 0;
						width: auto;
					}
				}
				&:nth-child(3) {
					.form-selector--list {
						left: auto;
						right: 0;
						width: 100%;
					}
				}
			}
		}
		.form-selector--list {
			height: 12.125rem;
		}
	}
}
.shops__map-info-points {
	height: auto;
	& > .scrollbar-inner {
		height: auto;
		.scrollbar-inner {
			padding-right: 0;
		}
	}
	ul {
		li {
			a {
				padding: 0.75rem 0;
				h4 {
					margin-bottom: 0.5rem;
					font-size: 0.75rem;
					line-height: 1rem;
					letter-spacing: 0.0005em;
				}
				p {
					margin-bottom: 0.5rem;
					font-size: 0.75rem;
					line-height: 1rem;
					letter-spacing: 0.0005em;
				}
			}
		}
	}
}
.shops__store-body {
	margin-bottom: 1.5rem;
}
.shops__store-text {
	order: 2;
}
.shops__store-map {
	order: 1;
	padding: 0;
}
.shops__store-img {
	position: relative;
	height: 14.875rem;
	img {
		border-radius: 0;
	}
	.back {
		display: flex !important;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 1.25rem;
		left: 0.75rem;
		width: 2.25rem;
		height: 2.25rem;
		background: #fff;
		border-radius: 0.5rem;
		.ico {
			width: 1rem;
			height: 1rem;
		}
	}
}
.shops__store-head {
	display: flex;
	align-items: center;
	margin-bottom: 2.5rem;
}
.shops__store {
	.ico {
		width: 0.8125rem;
		height: 0.8125rem;
	}
}
.shops__store-info {
	padding: 1rem 0 0;
}
.shops__store-head--title {
	margin: 0 0 1.25rem;
	font-size: 1.0625rem;
	line-height: 1.375rem;
}
.shops__store-info-title {
	margin-bottom: 1.25rem;
	h5 {
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}
	p {
		margin: 0 0 0 0.5rem;
		padding: 0.125rem 0.25rem;
		font-size: 0.6875rem;
		letter-spacing: 0.0005em;
		border-radius: 0.25rem;
	}
}
.shops__store-body--address {
	margin-bottom: 1.25rem;
	p {
		align-items: flex-start;
		margin-bottom: 0.75rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
		.ico {
			margin-top: 0.1875rem;
		}
	}
}
.shops__store-body--address-get {
	margin-bottom: 1.25rem;
	h6 {
		font-size: 1rem;
		line-height: 1.5rem;
	}
	p {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}
.shops__store-body--data {
	margin-bottom: 1.25rem;
	ul {
		li {
			margin: 0 0 0.75rem;
			font-size: 0.875rem;
			line-height: 1.1875rem;
		}
	}
}
.shops__store-body--collections {
	align-items: flex-start;
	flex-wrap: wrap;
	margin-bottom: 1.25rem;
	h6 {
		display: block;
		margin: 0 0 0.75rem;
		width: 100%;
		font-size: 0.9375rem;
		line-height: 1.125rem;
	}
	p {
		margin: 0 0.5rem 0 0;
		padding: 0.25rem 0.375rem;
		font-size: 0.75rem;
		border-radius: 0.25rem;
	}
}
.shops__store-body--opportunities {
	flex-direction: column;
	margin-bottom: 1.25rem;
	.block {
		margin: 0 0 1.25rem;
		&:last-child {
			margin-bottom: 0;
		}
		h6 {
			margin-bottom: 0.25rem;
			font-size: 0.875rem;
			line-height: 1.25rem;
		}
	}
	ul {
		li {
			font-size: 0.875rem;
			line-height: 1.1875rem;
			&::before {
				padding: 0 0.5rem 0 0.25rem;
			}
		}
	}
}
.shops__store-body--rezerv {
	p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}
}
.shops__store-iframe {
	margin-bottom: 1.5rem;
	margin-left: -0.75rem;
	width: 100vw;
	height: 17.5rem;
}

}