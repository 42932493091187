@charset "UTF-8";

.modal {
	top: 5.5rem;
	height: calc(100vh - 5.5rem);
}

.modal .close {
	display: block;
	position: relative;
	width: 1.5rem;
	height: 1.5rem;
	border: 0;
	border-radius: 0;
	outline: 0;
	box-shadow: none;
	background: url("../../img/svg/close.svg") no-repeat 50%/contain;
	opacity: 1;
	z-index: 1;
	cursor: pointer;
}

.modal-backdrop {
	top: 5.5rem;
	height: calc(100vh - 5.5rem);
}

.modal-city .modal-dialog {
	max-width: 38.5rem;
}

.modal-city .modal-content {
	background: rgba(255, 255, 255, 0.95);
	border: 0;
	border-radius: 0.75rem;
}

.modal-city .modal-body {
	padding: 1.5rem;
}

.modal-city form {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
}

.modal-city form .form-group {
	position: relative;
	margin: 0;
	width: 100%;
}

.modal-city form .form-control__search {
	position: absolute;
	top: 50%;
	left: 0.5rem;
	width: 1.5rem;
	height: 1.5rem;
	transform: translate(0, -50%);
}

.modal-city form .form-control {
	padding: 0 6.25rem 0 2.5rem;
	color: var(--c-main);
	height: 2.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
	border: 0.0625rem solid #DCD6D1;
	border-radius: 0.5rem;
}

.modal-city form .form-control::-webkit-input-placeholder {
	color: #DCD6D1;
}

.modal-city form .form-control::-moz-placeholder {
	color: #DCD6D1;
}

.modal-city form .form-control:-moz-placeholder {
	color: #DCD6D1;
}

.modal-city form .form-control:-ms-input-placeholder {
	color: #DCD6D1;
}

.modal-city form .form-control:focus {
	border-color: var(--c-main);
}

.modal-city__title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	width: 100%;
}

.modal-city__title h5 {
	margin: 0;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.modal-city__list {
	display: flex;
	align-items: flex-start;
}

.modal-city__list ul {
	width: 50%;
}

.modal-city__list ul li {
	width: 100%;
}

.modal-city__list ul li a {
	display: block;
	padding: 0.5rem;
	width: 100%;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.modal-city__list-full {
	height: 25rem;
}

.modal-city__list-full div {
	width: 100%;
}

.modal-city__list-full > .scrollbar-inner {
	height: 100%;
}

.modal-city__list-full ul {
	width: 100%;
}

.modal-main .modal-dialog {
	margin: 0 0 0 auto;
	max-width: 49.25rem;
	height: 100%;
	&.ts{
		max-width: 53rem;
	}
}

.modal-main .modal-content {
	height: 100%;
	border: 0;
	border-radius: 0.75rem 0 0 0.75rem;
}

.modal-main .modal-body {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1.5rem;
	height: 100%;
	overflow-y: auto;
}

.modal-main .close {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	z-index: 1;
}

.modal-main form .form-line {
	justify-content: space-between;
}

.modal-main form .form-line p {
	margin-bottom: 0;
}

.modal-main-system .modal-form {
	padding-left: 10rem;
	padding-right: 10rem;
}

.modal__title {
	margin: 0 0 1.5rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.modal-basket {
	height: calc(100% - 1.75rem - 1.5rem);
}

.modal-basket__body {
	padding: 1.5rem 0;
	height: calc(100% - 7rem);
}

.modal-basket__body > .scrollbar-inner {
	height: 100%;
}

.modal-basket__body > .scrollbar-inner .scrollbar-inner {
	padding-right: 1rem;
}

.modal-basket__product {
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.5rem;
}

.modal-basket__product:last-child {
	margin-bottom: 0;
}

.modal-basket__product-img {
	position: relative;
	flex-shrink: 0;
	margin-right: 0.75rem;
	width: 5.75rem;
	height: 8.75rem;
}

.modal-basket__product-img img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.3125rem;
}

.modal-basket__product-info {
	position: relative;
	width: calc(100% - 0.75rem - 5.75rem);
	height: 8.75rem;
}

.modal-basket__product-info .wrap {
	display: flex;
	align-items: flex-start;
	width: 100%;
}

.modal-basket__product-info .wrap .left {
	margin-right: auto;
	width: 32.5rem;
}

.modal-basket__product-info .wrap .left h6 {
	display: block;
	display: -webkit-box;
	position: relative;
	margin-bottom: 0.25rem;
	height: 3rem;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.002em;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
}

.modal-basket__product-info .wrap .left ul {
	display: flex;
	align-items: center;
	margin-bottom: 0.25rem;
}

.modal-basket__product-info .wrap .left ul li {
	margin-right: 0.75rem;
	color: #B3A8A1;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.002em;
}

.modal-basket__product-info .wrap .left p {
	margin: 0;
	color: #FF4E44;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.002em;
}

.modal-basket__product-info .wrap .right {
	margin-left: auto;
}

.modal-basket__product-info .wrap .right h6 {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-basket__product-info .wrap .right p {
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
}

.modal-basket__product-info .wrap .right .discount {
	color: #FF4E44;
}

.modal-basket__product-info .wrap .right .old {
	color: #B3A8A1;
	text-decoration: line-through;
}

.modal-basket__product-info .product-numbers {
	position: absolute;
	bottom: 0;
	left: 0;
}

.modal-basket__product-trash {
	position: absolute;
	bottom: 0.25rem;
	right: 0;
	width: 1.5rem;
	height: 1.5rem;
}

.modal-basket__foot h5 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.25rem;
	width: 100%;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.modal-basket__foot .btns {
	width: 100%;
}

.modal-basket__foot .btns .btn {
	width: 100%;
}

.modal-form {
	padding: 3.5rem 8.625rem 0;
}

.modal-form .nav-tabs {
	justify-content: space-between;
	margin-bottom: 1.25rem;
	padding: 0.125rem;
	background: #F8F5F1;
	border: 0;
	border-radius: 0.5rem;
}

.modal-form .nav-tabs li {
	width: calc(50% - (0.125rem / 2));
}

.modal-form .nav-tabs .nav-link {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 3.5rem;
	color: var(--c-main);
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: center;
	background: transparent;
	border: 0;
	border-radius: 0.5rem;
}

.modal-form .nav-tabs .nav-link.active {
	color: #fff;
	background: var(--c-main);
}

.modal-form form .form-btns .btn {
	width: 100%;
}

.modal-form form .btns--logos .btn img {
	display: block;
	margin-right: 0.5rem;
	width: auto;
	height: 1.5rem;
}

.modal-form form .form-checkbox {
	display: flex;
	align-items: center;
}

.modal-form form .form-checkbox:last-child {
	margin-bottom: 0;
}

.modal-form form .form-checkbox .label-dot {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	position: relative;
	top: 0;
	margin-bottom: 0;
	margin-right: 0.5rem;
	width: 1.75rem;
	height: 1.75rem;
	background: transparent;
	border: 0.0625rem solid #DCD6D1;
	border-radius: 0.25rem;
	cursor: pointer;
}

.modal-form form .form-checkbox .label-dot:hover {
	border-color: #B3A8A1;
}

.modal-form form .form-checkbox .label-dot::after {
	content: '';
	flex-shrink: 0;
	position: relative;
	top: 2%;
	right: 2%;
	width: 94%;
	height: 94%;
	background: url("../../img/svg/check-2.svg") no-repeat 50%/contain;
	opacity: 0;
}

.modal-form form .form-checkbox input:checked + .label-dot {
	border-color: var(--c-main);
}

.modal-form form .form-checkbox input:checked + .label-dot:hover {
	border-color: #B3A8A1;
}

.modal-form form .form-checkbox input:checked + .label-dot::after {
	opacity: 1;
}

.modal-form form .form-checkbox .label-text {
	display: inline-flex;
	align-items: center;
	margin: 0;
	color: var(--c-main);
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.002em;
	cursor: pointer;
}

.modal-form form .form-checkbox .label-text a {
	color: var(--c-main);
	text-decoration: underline;
}

.modal-form__title {
	margin-bottom: 1.5rem;
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.001em;
	text-align: center;
}

.modal-product .modal-dialog {
	margin: 0 auto;
	max-width: 100%;
	height: 100%;
}

.modal-product .modal-content {
	height: 100%;
	background: #F8F5F1;
	border: 0;
	border-radius: 0;
}

.modal-product .modal-body {
	padding: 0;
	height: 100%;
}

.modal-product .close {
	display: block;
	position: absolute;
	top: 1.25rem;
	right: 0.75rem;
	width: 4rem;
	height: 4rem;
	border: 0;
	border-radius: 0;
	outline: 0;
	box-shadow: none;
	background: #fff;
	border: 0.0625rem solid #F8F5F1;
	border-radius: 0.5rem;
	opacity: 1;
	z-index: 1;
	cursor: pointer;
}

.modal-product .close:hover {
	background: #131211;
	border-radius: 0.75rem;
}

.modal-product .close:hover::before {
	opacity: 0;
}

.modal-product .close:hover::after {
	opacity: 1;
}

.modal-product .close::before {
	background-image: url("../../img/svg/close.svg");
	opacity: 1;
}

.modal-product .close::after {
	background-image: url("../../img/svg/close-white.svg");
	opacity: 0;
}

.modal-product .close::before,
.modal-product .close::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 1.5rem;
}

.modal-product-wrap {
	display: flex;
	width: 100%;
	height: 100%;
}

.modal-product__slider-add {
	margin-left: 0.75rem;
	margin-right: 5.25rem;
	width: 4.75rem;
	height: 100%;
}

.modal-product__slider-add .slick-list {
	padding: 1.375rem 0;
	height: 100% !important;
}

.modal-product__slider-add .slick-slide {
	margin-bottom: 0.25rem;
	width: 4.75rem;
	height: 7.25rem;
}

.modal-product__slider-add .slick-slide img {
	display: block;
	width: 100%;
	height: 100%;
	border: 0.0625rem solid transparent;
	border-radius: 0.5rem;
	object-fit: cover;
}

.modal-product__slider-add .slick-current img {
	background-color: var(--c-main);
}

.modal-product__slider-main {
	width: 98.5rem;
	height: 100%;
}

.modal-product__slider-main div {
	height: 100%;
}

.modal-product__slider-main .slick-slide {
	overflow-y: auto;
}

.modal-product__slider-main .slick-slide > div {
	height: 100%;
}

.modal-product__slider-main .slick-slide img {
	display: block;
	width: 100%;
	height: auto;
}

.modal-prod-feed .modal-dialog {
	margin: 0 auto;
	max-width: 58.5rem;
	height: 100%;
}

.modal-prod-feed .modal-content {
	height: 100%;
	background: transparent;
	border: 0;
	border-radius: 0;
}

.modal-prod-feed .modal-body {
	padding: 1.25rem 0;
	height: 100%;
}

.modal-prod-feed .close {
	display: block;
	position: absolute;
	top: 1.25rem;
	right: 0.75rem;
	width: 4rem;
	height: 4rem;
	border: 0;
	border-radius: 0;
	outline: 0;
	box-shadow: none;
	background: #F8F5F1 url("../../img/svg/close.svg") no-repeat 50%/1.5rem;
	border: 0.0625rem solid #F8F5F1;
	border-radius: 0.5rem;
	opacity: 1;
	z-index: 1;
	cursor: pointer;
}

.modal-prod-feeback-wrap {
	height: 100%;
}

.modal-prod-feed__slider-main {
	position: relative;
	margin: 0 auto;
	width: 60vh;
	height: 100%;
}

.modal-prod-feed__slider-main div {
	height: 100%;
}

.modal-prod-feed__slider-main .slide {
	margin: 0 1.5rem;
	width: auto;
	height: 100%;
}

.modal-prod-feed__slider-main .slide img {
	display: block;
	margin: 0 auto;
	width: auto;
	height: 100%;
	object-fit: cover;
}

.modal-prod-feeback__slider-controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	transform: translate(0, -50%);
	pointer-events: none;
	z-index: 2;
}

.modal-prod-feeback__slider-controls .slick-btn {
	padding: 0;
	width: 4rem;
	height: 4rem;
	color: transparent;
	font-size: 0;
	line-height: 0;
	background-color: #F8F5F1;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 1.5rem;
	border: 0;
	border-radius: 0.5rem;
	outline: 0;
	box-shadow: none;
	cursor: pointer;
	pointer-events: auto;
}

.modal-prod-feeback__slider-controls .slick-btn-prev {
	background-image: url("../../img/svg/arrow-left-black.svg");
}

.modal-prod-feeback__slider-controls .slick-btn-next {
	background-image: url("../../img/svg/arrow-right-black.svg");
}

.modal-prod-feed__slider-add {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 46.75rem;
	height: 10.25rem;
	transform: translate(-50%, 0);
	z-index: 1;
}

.modal-prod-feed__slider-add .slide {
	position: relative;
	margin: 0 0.25rem;
	width: 4.75rem;
	height: 7.25rem;
	border: 0.0625rem solid transparent;
	border-radius: 0.25rem;
}

.modal-prod-feed__slider-add .slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.25rem;
}

.modal-prod-feed__slider-add .slick-current {
	border-color: var(--c-main);
	border-radius: 0.5rem;
}

.modal-prod-feed__slider-add .slick-current img {
	border-radius: 0.5rem;
}

.modal-feedback .modal-dialog {
	max-width: 38.5rem;
}

.modal-feedback .modal-content {
	background: rgba(255, 255, 255, 0.95);
	border-radius: 0.75rem;
	box-shadow: 0 0.625rem 1.875rem 0 rgba(38, 36, 34, 0.08);
	backdrop-filter: blur(1.875rem);
}

.modal-feedback .modal-body {
	padding: 1.5rem;
}

.modal-feedback__title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}

.modal-feedback__title h5 {
	margin: 0;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.modal-feedback__product {
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.5rem;
}

.modal-feedback__product .img {
	flex-shrink: 0;
	margin-right: 0.75rem;
	width: 4.75rem;
	height: 7.25rem;
}

.modal-feedback__product .img img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.25rem;
}

.modal-feedback__product .info h4 {
	margin-bottom: 0.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-feedback__product .info .opt {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}

.modal-feedback__product .info .opt li {
	margin-right: 0.375rem;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.modal-feedback__product .stars {
	display: flex;
	align-items: center;
}

.modal-feedback__product .stars ul {
	display: flex;
	margin-right: 0.75rem;
}

.modal-feedback__product .stars li {
	margin-right: 0.125rem;
}

.modal-feedback__product .stars li:last-child {
	margin-right: 0;
}

.modal-feedback__product .stars li i {
	display: block;
	position: relative;
	width: 1.25rem;
	height: 1.25rem;
	background: url("../../img/svg/star-empty.svg") no-repeat 50%/contain;
}

.modal-feedback__product .stars li i.active {
	background-image: url("../../img/svg/star.svg");
}

.modal-feedback__product .stars h5 {
	margin: 0;
	color: #B3A8A1;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.modal-feedback__form form .form-label {
	margin-bottom: 1rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-feedback__form .form-btns .btn {
	width: 100%;
}

.modal-feedback__form .form-foot {
	padding: 0;
}

.modal-table-sizes .modal-dialog {
	max-width: 51.75rem;
}

.modal-table-sizes .modal-body > .scrollbar-inner > .scrollbar-inner {
	padding-right: 1rem;
}

.modal-table-sizes .head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.25rem;
}

.modal-table-sizes .head .modal-form__title {
	margin: 0;
}

.modal-table-sizes .nav-tabs {
	display: inline-flex;
	margin-bottom: 1.25rem;
	width: auto;
	border-bottom: 0.0625rem solid #DCD6D1;
}

.modal-table-sizes .nav-tabs li:not(:last-child) {
	margin-right: 1.5rem;
}

.modal-table-sizes .nav-tabs .nav-link {
	padding: 0.75rem 0;
	color: #B3A8A1;
	font-size: 1.125rem;
	line-height: 1.75rem;
	background: transparent;
	border: 0;
	border-bottom: 0.125rem solid transparent;
}

.modal-table-sizes .tab-pane h3 {
	margin-bottom: 1rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-table-sizes .calculates {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 1.75rem;
}

.modal-table-sizes .calculates > li:not(:last-child) {
	margin-right: 1.5rem;
}

.modal-table-sizes .calculates p {
	margin-bottom: 0.5rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.modal-table-sizes .tables {
	margin-bottom: 1.5rem;
}

.modal-table-sizes .tables .form-selector {
	margin-bottom: 0.75rem;
	width: 100%;
	max-width: 100%;
}

.modal-table-sizes .tables .form-selector--btn {
	padding: 0 1.5rem;
	justify-content: center;
	text-align: center;
}

.modal-table-sizes .tables .form-selector--btn span {
	width: auto;
}

.modal-table-sizes .tables__block h3 {
	margin-bottom: 0.75rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.005em;
}

.modal-table-sizes .tables__window-wrap {
	margin-bottom: 0.75rem;
	background: #fff;
	border-radius: 0.5rem;
	overflow: hidden;
}

.modal-table-sizes .tables__window-wrap ul {
	display: flex;
}

.modal-table-sizes .tables__window-wrap ul li {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 0.75rem;
	width: 100%;
	height: 3rem;
	font-size: 0.75rem;
	line-height: 1rem;
	border-bottom: 0.0625rem solid #D9D9D9;
	overflow: hidden;
}

.modal-table-sizes .tables__window-wrap ul li:first-child {
	flex-shrink: 0;
	justify-content: flex-start;
	width: 8.875rem;
	text-align: left;
	background: #D9D9D9;
}

.modal-table-sizes .tables__window-wrap ul:last-child li {
	border-bottom: 0;
}

.modal-table-sizes .tables__window p {
	margin: 0;
	font-size: 0.75rem;
	line-height: 1rem;
	letter-spacing: 0.005em;
}

.modal-table-sizes .info h4 {
	margin-bottom: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.modal-table-sizes .info .wrap {
	display: flex;
	justify-content: space-between;
}

.modal-table-sizes .info .wrap .text {
	width: 24.375rem;
}

.modal-table-sizes .info .wrap .text ul {
	counter-reset: item;
}

.modal-table-sizes .info .wrap .text ul li {
	display: flex;
}

.modal-table-sizes .info .wrap .text ul li::before {
	content: counter(item) ".";
	counter-increment: item;
	margin-right: 0.625rem;
}

.modal-table-sizes .info .wrap .text ul li:last-child {
	margin-bottom: 0;
}

.modal-table-sizes .info .wrap .img {
	width: 16.25rem;
}

.modal-table-sizes .info .wrap .img img {
	width: 100%;
}

.modal-table-sizes .nav-tabs .nav-item.show .nav-link,
.modal-table-sizes .nav-tabs .nav-link.active {
	color: var(--c-main);
	border-bottom-color: var(--c-main);
}

.form-selector {
	position: relative;
	width: auto;
	max-width: 12.5rem;
}

.form-selector.big .form-selector--btn {
	padding: 0 1.25rem;
}

.form-selector.small .form-selector--btn {
	padding: 0 1rem;
}

.form-selector--btn {
	display: flex;
	align-items: center;
	position: relative;
	padding: 0 0.25rem 0 0.75rem;
	height: 3rem;
	color: var(--c-main);
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.5rem;
	white-space: nowrap;
	text-decoration: none;
	background: #fff;
	border: 0.0625rem solid #DCD6D1;
	border-radius: 0.5rem;
}

.form-selector--btn.active {
	background: #F8F5F1;
	border-color: #F8F5F1;
}

.form-selector--btn::after {
	content: '';
	margin: 0 0.5rem;
	width: 1.25rem;
	height: 1.25rem;
	background: url("../../img/svg/mini-arrow-bot-black.svg") no-repeat 50%/contain;
}

.form-selector--btn span {
	display: block;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
}

.form-selector--list {
	position: absolute;
	bottom: -0.5rem;
	left: 0;
	margin: 0;
	padding: 0.5rem;
	width: 100%;
	height: 21rem;
	background: #fff;
	border-radius: 0.5rem;
	box-shadow: 0 0.625rem 1.875rem 0 rgba(38, 36, 34, 0.08);
	transform: translate(0, 100%);
	overflow: hidden;
	z-index: 10;
}

.form-selector--list .scrollbar-inner {
	height: 100%;
}

.form-selector--list .scrollbar-inner .scrollbar-inner {
	padding-right: 1rem;
}

.form-selector--list ul {
	width: 100%;
}

.form-selector--list ul li {
	text-overflow: ellipsis;
	overflow: hidden;
}

.form-selector--list ul li a {
	display: block;
	padding: 0.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.002em;
	white-space: nowrap;
	text-decoration: none;
	text-overflow: ellipsis;
	border-radius: 0.25rem;
}

.form-selector--list ul li a.selected {
	background: #F8F5F1;
}

.form-selector--list ul li a:hover {
	background: #F8F5F1;
}

.form-selector--list .form-checkbox {
	margin-bottom: 1rem;
}

.form-selector--list .form-checkbox:last-child {
	margin-bottom: 0;
}

.form-selector--checks .form-selector--list {
	height: auto;
}

.form-selector--number {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	width: 1rem;
	height: 1rem;
	font-size: 0.5625rem;
	line-height: 1rem;
	text-align: center;
	color: #fff;
	background: var(--c-main);
	border-radius: 50%;
	transform: translate(50%, -50%);
}

.modal-table-sizes .info .wrap .text p,
.modal-table-sizes .info .wrap .text ul li {
	margin-bottom: 1.25rem;
	position: relative;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.002em;
}

.modal-rezerv .modal-body > .scrollbar-inner {
	height: 100%;
}

.modal-rezerv .modal-body > .scrollbar-inner .scrollbar-inner {
	padding-right: 1rem;
	height: 100% !important;
}

.modal-rezerv__title {
	margin-bottom: 1.75rem;
	padding-top: 3.5rem;
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.01em;
	text-align: center;
}

.modal-rezerv__step-1 .form-group {
	position: relative;
	margin-bottom: 1.25rem;
}

.modal-rezerv__step-1 .form-group a {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
}

.modal-rezerv__step-1 .form-group .ico-search {
	left: 0.5rem;
	width: 1.5rem;
	height: 1.5rem;
}

.modal-rezerv__step-1 .form-group .link {
	right: 0.5rem;
	color: #B3A8A1;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.modal-rezerv__step-1 .form-group .change {
	display: block;
}

.modal-rezerv__step-1 .form-group .clear {
	display: none;
}

.modal-rezerv__step-1 .form-control {
	padding: 0 5.4375rem 0 2.5rem;
	height: 2.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-rezerv__step-1 .all-filters__title {
	margin-bottom: 0.5rem;
}

.modal-rezerv__step-1 .all-filters-sizes {
	margin-bottom: 1.25rem;
	padding: 0;
}

.modal-rezerv__step-1 .form-selector {
	margin-bottom: 1.25rem;
	width: 100%;
	max-width: 100%;
}

.modal-rezerv__step-1 .form-selector--colors .form-selector--list ul li a .label-dot {
	margin-right: 0.5rem;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
}

.modal-rezerv__step-1 .form-selector--colors .form-selector--list ul li a .label-dot::after {
	top: 0;
	right: 0;
	width: 1.25rem;
	height: 1.25rem;
	background: var(--c-main);
	border-radius: 50%;
}

.modal-rezerv__step-1 .form-selector--colors .form-selector--list ul li a.selected .label-dot {
	border-color: var(--c-main);
}

.modal-rezerv__step-1 .form-selector--colors .form-selector--list ul li a.selected .label-dot::after {
	opacity: 1;
}

.modal-rezerv__step-1 .choose-shop h3 {
	margin-bottom: 0.125rem;
	padding: 0.5rem 0;
	font-size: 1rem;
	line-height: 1.5rem;
}

.modal-rezerv__step-1 .choose-shop ul li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.75rem 0;
}

.modal-rezerv__step-1 .choose-shop ul li .text h4 {
	margin-bottom: 0.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.modal-rezerv__step-1 .choose-shop ul li .text p {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.002em;
}

.modal-rezerv__step-1 .choose-shop ul li .text p i {
	display: block;
	margin-right: 0.25rem;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 50%;
}

.modal-rezerv__step-1 .choose-shop ul li .text p span {
	color: #FF4E44;
}

.modal-rezerv__step-1 .choose-shop ul li .text :last-child {
	margin-bottom: 0;
}

.modal-rezerv__step-1 .choose-shop ul li .btns {
	flex-shrink: 0;
	padding-left: 1.25rem;
}

.form-selector--colors .form-selector--btn span {
	position: relative;
	padding-left: 0.75rem;
}

.form-selector--colors .form-selector--btn span i {
	display: block;
	position: absolute;
	top: 50%;
	left: 0.75rem;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 50%;
	transform: translate(0, -50%);
}

.form-selector--colors.choosed .form-selector--btn span {
	padding-left: 2rem;
}

.form-selector--colors .form-selector--list {
	height: 15rem;
}

.form-selector--colors .form-selector--list ul li a {
	display: flex;
}

.modal-rezerv__registrate .modal-basket__product {
	margin-bottom: 1.25rem;
}

.modal-rezerv__registrate .info {
	margin-bottom: 0.75rem;
	padding: 0.75rem 0ы;
}

.modal-rezerv__registrate .info h3 {
	margin-bottom: 0.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.modal-rezerv__registrate .info p {
	margin-bottom: 0.5rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.002em;
}

.modal-rezerv__registrate .info :last-child {
	margin-bottom: 0;
}

.modal-rezerv__registrate form .form-btns .btn {
	width: 100%;
}

.modal-rezerv__finish {
	margin: 0 auto;
	width: 26.125rem;
}

.modal-rezerv__finish * {
	text-align: center !important;
}

.modal-rezerv__finish .btn {
	width: 100%;
}

.modal-system .modal-dialog {
	margin-left: auto;
	margin-right: auto;
	max-width: 30rem;
}

.modal-system .modal-content {
	background: #fff;
	border: 0;
	border-radius: 0.75rem;
	border: 1px solid #e2e2e2;
}

.modal-system .modal-body {
	padding: 1.5rem;
}

.modal-system .modal-body > :last-child {
	margin-bottom: 0 !important;
}

.modal-system .btn {
	padding: 0.875rem 1.5rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0005em;
}

.modal-system__head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}

.modal-system__head * {
	margin-bottom: 0 !important;
}

.modal-system__title {
	margin: 0 0 1.5rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.0001em;
}

.modal-system__text {
	margin: 0 0 1.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-map .modal-dialog {
	margin-left: auto;
	margin-right: auto;
	max-width: 78.5rem;
}

.modal-map .modal-content {
	background: #fff;
	border: 0;
	border-radius: 0.75rem;
	overflow: hidden;
}

.modal-map .close {
	position: absolute;
	top: 4rem;
	right: 2rem;
	width: 3rem;
	height: 3rem;
	background: #fff;
	transition: 0.15s;
	border-radius: 0.5rem;
}

.modal-map .close::before {
	background-image: url("../../img/svg/close.svg");
	opacity: 1;
}

.modal-map .close::after {
	background-image: url("../../img/svg/close-white.svg");
	opacity: 0;
}

.modal-map form {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.modal-map form .form-btns {
	margin-top: auto;
}

.modal-map .close::before,
.modal-map .close::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 1.25rem;
	height: 1.25rem;
	background-repeat: no-repeat;
	background: 50%;
	background-size: contain;
	transition: 0.15s;
	transform: translate(-50%, -50%);
}

.modal-map__title {
	margin-bottom: 1.5rem;
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.001em;
}

.modal-map__wrap {
	display: flex;
	height: 40rem;
}

.modal-map__info {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	padding: 1.5rem;
	height: 100%;
}

.modal--address .modal-map__info {
	width: 34.25rem;
}

.modal--points .modal-map__info {
	width: 31.5rem;
}

.modal--points form {
	margin-bottom: 1.5rem;
}

.modal--points form .form-group {
	margin-bottom: 1rem;
}

.modal--points form .form-radios {
	margin-bottom: 1rem;
}

.modal-map__iframe {
	width: 100%;
	height: 100%;
}

.modal-map__iframe iframe {
	width: 100%;
	height: 100%;
}

.modal-map__info-points {
	margin-left: -1.5rem;
	width: calc(100% + (1.5rem * 2));
	height: 24rem;
	border-top: 0.0625rem solid #F8F5F1;
}

.modal-map__info-points > .scrollbar-inner {
	height: 100%;
}

.modal-map__info-points > .scrollbar-inner .scrollbar-inner {
	padding-right: 0.75rem;
}

.modal-map__info-points ul li a {
	display: block;
	padding: 0.75rem 1.5rem;
}

.modal-map__info-points ul li a h4 {
	margin-bottom: 0.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.modal-map__info-points ul li a p {
	margin-bottom: 0.5rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0002em;
}

.modal-map__info-points ul li a :last-child {
	margin-bottom: 0;
}

.modal-map__point {
	display: flex;
	flex-direction: column;
	padding-top: 1.5rem;
	height: 100%;
}

.modal-map__point h5 {
	margin-bottom: 1rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.0001em;
}

.modal-map__point ul {
	margin-bottom: 1.5rem;
}

.modal-map__point ul li {
	display: flex;
	align-items: center;
}

.modal-map__point ul li:not(:last-child) {
	margin-bottom: 0.75rem;
}

.modal-map__point ul li p {
	margin: 0;
}

.modal-map__point p {
	margin: 0 0 0.25rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-map__point .form-btns {
	margin: auto 0 0;
}

.modal-stocks .modal-dialog {
	margin-left: auto;
	margin-right: auto;
	max-width: 58.5rem;
}

.modal-stocks .modal-content {
	background: #fff;
	border: 0;
	border-radius: 0.75rem;
}

.modal-stocks .modal-body {
	padding: 1.5rem;
}

.modal-stocks__head {
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.5rem;
}

.modal-stocks__head img {
	display: block;
	margin-right: 1.25rem;
	width: 8.625rem;
	height: 5.25rem;
	border-radius: 0.5rem;
}

.modal-stocks__head .info {
	margin-right: auto;
}

.modal-stocks__head .info h3 {
	margin-bottom: 0.25rem;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
}

.modal-stocks__head .info p {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-stocks__body h5 {
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.modal-stocks__body p {
	margin-bottom: 0.75rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-stocks__body ul {
	counter-reset: stocklist;
}

.modal-stocks__body ul li {
	position: relative;
	padding-left: 1.625rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-stocks__body ul li::before {
	counter-increment: stocklist;
	content: counter(stocklist) ".";
	position: absolute;
	top: 0;
	left: 0.5rem;
}

.modal-stocks__body :last-child {
	margin: 0;
}

.modal-stocks__body a {
	text-decoration: underline;
}

.modal-fashion .modal-dialog {
	max-width: 59.25rem;
}

.modal-fashion .modal-content {
	background: rgba(248, 245, 241, 0.95);
	border-radius: 0;
}

.modal-fashion .modal-basket__body {
	height: 100%;
}

.modal-fashion .modal-basket__product-img {
	width: 8.25rem;
	height: 12.375rem;
}

.modal-fashion .modal-basket__product-info {
	height: auto;
}

.modal-fashion .modal-basket__product-info .wrap .left {
	width: 40rem;
}

.modal-fashion .modal-basket__product-info .wrap .left p {
	color: var(--c-main);
}

.modal-fashion .modal-basket__product-info .wrap .left .card__meta {
	margin-bottom: 0.5rem;
}

.modal-fashion .modal-basket__product-info .wrap .left h6 {
	margin-bottom: 2rem;
	height: 3.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-fashion .modal-basket__product-info .wrap .left .product__kv-info-sizes ul {
	margin-bottom: 0;
}

.modal-fashion .modal-basket__product-info .wrap .left .product__kv-info-sizes ul li {
	margin-right: 0.25rem;
}

.modal-fashion .modal-basket__product-info .wrap .left .card__colors {
	margin-bottom: 0;
	padding: 0.75rem 0;
}

.modal-fashion .modal-basket__product-info .wrap .left .card__colors li {
	margin-right: 0.25rem;
}

.modal-fashion .product__kv-info-sizes {
	margin-bottom: 0.625rem;
}

.modal-fashion .btn {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 0;
	width: 4rem;
	height: 4rem;
}

.modal-fashion__imgs {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	width: 40rem;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	transform: translate(-100%, 0);
}

.modal-fashion__imgs::-webkit-scrollbar {
	width: 0;
}

.modal-fashion__imgs .img {
	width: 100%;
}

.modal-fashion__imgs .img img {
	width: 100%;
}