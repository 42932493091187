@media (max-width: 991px) {
	.submenu-product {
		top: auto;
		bottom: 2.5rem;
	}

	.submenu-product__wrap {
		flex-direction: column;
		padding: 0.75rem 0 0;
	}

	.submenu-product__info {
		margin: 0 0 0.75rem;
		max-width: 100%;
	}

	.submenu-product__info h3 {
		margin: 0;
		font-size: 0.9375rem;
		line-height: 1.125rem;
	}

	.submenu-product__btns {
		padding: 0.75rem 0;
		width: 100%;
	}

	.submenu-product__btns .btn {
		height: 2.5rem;
	}

	.submenu-product__btns .btn-secondary {
		width: calc(100% - 0.25rem - 2.5rem);
	}

	.submenu-product__btns .btn-secondary-tr {
		margin-left: auto;
		width: 2.5rem;
	}
}