.footer__wrap {
	padding: 1.5rem;
	background: #F8F5F1;
	border-radius: 0.5rem;
}

.footer__shop {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 2.125rem;
	width: 100%;
	height: 27rem;
	text-align: center;
	background: #fff;
}

.footer__shop h5 {
	margin-bottom: 1.25rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.01em;
}

.footer__shop > img {
	display: block;
	width: 16.25rem;
	height: 16.25rem;
	object-fit: contain;
}

.footer__menu {
	margin-bottom: 1.25rem;
	padding-top: 0.625rem;
}

.footer__menu h3 {
	margin-bottom: 0.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.footer__menu ul li a {
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.002em;
}

.footer__data {
	margin-bottom: 1.25rem;
	padding: 1rem 0;
	border-top: 0.0625rem solid #DCD6D1;
	border-bottom: 0.0625rem solid #DCD6D1;
}

.footer__data h4 {
	margin: 0;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.002em;
}

.footer__data .phone {
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.01em;
}

.footer__btns-list {
	display: flex;
	align-items: center;
}

.footer__btns-list p {
	flex-shrink: 0;
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.002em;
}

.footer__btns-list ul {
	display: flex;
	align-items: center;
	margin-left: 1.5rem;
}

.footer__btns-list ul li {
	background: #fff;
	border-radius: 0.25rem;
}

.footer__btns-list ul li:not(:last-child) {
	margin-right: 0.75rem;
}

.footer__btns-list ul li img {
	width: auto;
	height: 2rem;
}

.footer__bot {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 3rem;
	text-align: center;
}

.footer__bot p {
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
}