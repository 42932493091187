.catalog {
	padding-top: 1.75rem;
	.card {
		margin-bottom: 1.5rem;
	}
	.card__img {
		height: 41.625rem;
	}
	.banner {
		height: 51.125rem;
	}
}
.promo {
	.text-page__list-block {
		margin-bottom: 1.75rem;
	}
}
.catalog__empty {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: calc(100vh - 1.75rem - 3.75rem - 1.75rem - 1.25rem - 1.5rem);
	text-align: center;
	h3 {
		margin-bottom: 40px;
		font-size: 48px;
		line-height: 52px;
		letter-spacing: -0.001em;
	}
}
.filters {
	position: relative;
	margin-bottom: 1rem;
	&.fixed {
		.filters__wrap {
			position: fixed;
			top: calc(1.75rem + 3.75rem);
			padding: 0.75rem;
			background: rgba(255, 255, 255, 0.85);
			border-radius: 0.5rem;
			backdrop-filter: blur(0.25rem);
			transform: translate(0, -100%);
			opacity: 0;
			transition: 0.3s;
			pointer-events: none;
		}
		&.hide {
			.filters__wrap {
				transform: translate(0, 0);
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
}
.filters__wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
}
.filters__list {
	display: flex;
	align-items: center;
	width: 100%;
	z-index: 10;
	& > div {
		position: relative;
		& > a {
			display: flex;
			align-items: center;
			position: relative;
			padding: 0 1rem;
			height: 3rem;
			font-size: 1rem;
			background: #fff;
			border: 0.0625rem solid #DCD6D1;
			border-radius: 0.5rem;
			&:hover {
				text-decoration: none;
			}
			.ico-arrow-bot-b {
				transition: 0.3s;
			}
			&.active {
				.ico-arrow-bot-b {
					transform: rotate(180deg);
				}
			}
			.numbers {
				position: absolute;
				top: 0;
				right: 0;
				padding: 0.25rem 0 0;
				width: 1rem;
				height: 1rem;
				color: #fff;
				font-weight: 500;
				font-size: 0.5625rem;
				line-height: 1;
				text-align: center;
				background: var(--c-main);
				border-radius: 50%;
				transform: translate(50%, -50%);
			}
		}
	}
}
.filters__list-first {
	flex-shrink: 0;
	margin-right: 0.75rem;
}
.filters__list-last {
	flex-shrink: 0;
	margin-left: 0.75rem;
}
.filters__activities {
	display: flex;
	align-items: center;
	width: 100%;
	overflow: hidden;
	.filters__active {
		position: relative;
		margin-right: 0.5rem;
		width: auto;
		a {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 0 0.9375rem;
			height: 3rem;
			font-weight: 500;
			font-size: 0.875rem;
			line-height: 1.25rem;
			letter-spacing: 0.0005em;
			text-align: center;
			white-space: nowrap;
			background: #fff;
			border: 0.0625rem solid #DCD6D1;
			border-radius: 6.25rem;
			&:hover {
				text-decoration: none;
			}
			&.active {
				background: #F8F5F1;
				border-color: #F8F5F1;
				.ico {
					display: block;
				}
			}
			.ico {
				display: none;
			}
		}
	}
}
.filters__collapse {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	position: absolute;
	bottom: -0.5rem;
	left: 0;
	padding: 0.5rem;
	background: #fff;
	border-radius: 0.5rem;
	transform: translate(0, 100%);
	z-index: 1;
	.filters__collapse-container {
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		flex-wrap: wrap;
		margin-left: 0;
		width: 0;
		height: 100%;
		transition: 0.3s;
		overflow: hidden;
	}
	.btn {
		margin-top: 1rem;
		width: 100%;
		font-size: 0.875rem;
		line-height: 1.25rem;
	}
}
.filters__collapse-wrap {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
	& > .scrollbar-inner {
		height: 100%;
	}
}
.filters__collapse-title {
	margin: 0;
	padding: 0.5rem;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.5rem;
}
.filters__collapse-links {
	flex-direction: column;
	border: 0;
	li {
		margin: 0;
	}
}
.filters__collapse-link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 0.5rem;
	width: 100%;
	color: var(--c-main);
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.002em;
	white-space: nowrap;
	border: 0;
	border-radius: 0.25rem;
	&.active {
		background: #F8F5F1;
	}
	b {
		font-weight: 600;
	}
	&:hover {
		text-decoration: none;
	}
	&.out {
		&::after {
			content: '';
			flex-shrink: 0;
			width: 1.5rem;
			height: 1.5rem;
			background: url('../../img/svg/chevron-right-black.svg') no-repeat 50%/contain;
		}
	}
}
.filters__collapse-checkboxes {
	margin-bottom: auto;
	li {
		.form-checkbox {
			padding: 0.5rem;
			&.active {
				background: #F8F5F1;
			}
		}
		.form-radio {
			padding: 0.5rem;
			&.active {
				background: #F8F5F1;
			}
		}
	}
}
.range-inputs {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	width: 100%;
}
.range-inputs-group {
	position: relative;
	width: calc(50% - (0.5rem / 2));
	height: 3rem;
	input {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 0 2rem;
		width: 100%;
		height: 100%;
		color: #B3A8A1;
		font-weight: 500;
		font-size: 1rem;
		line-height: 1.5rem;
		background: rgba(0, 0, 0, 0);
		border: 0.0625rem solid #DCD6D1;
		border-radius: 0.5rem;
		outline: 0;
	}
	p {
		position: absolute;
		top: 50%;
		left: 0.75rem;
		margin-bottom: 0;
		color: #B3A8A1;
		font-weight: 500;
		font-size: 1rem;
		line-height: 1.5rem;
		transform: translate(0, -50%);
	}
}
.range-inputs-group input:focus,
	.range-inputs-group input:focus-visible {
	color: var(--c-main);
	border-color: var(--c-main);
}
.range-inputs-group input:focus + p,
	.range-inputs-group input:focus-visible + p {
	color: var(--c-main);
}
.range-slider {
	height: 0.25rem;
	.irs {
		height: 100%;
	}
	.irs-line {
		top: 0;
		width: 100%;
		height: 100%;
		background: #F8F5F1;
		border-radius: 6.25rem;
	}
	.irs-bar {
		top: 0;
		height: 100%;
		background: var(--c-main);
		border-radius: 6.25rem;
	}
	.irs-handle {
		top: 50%;
		width: 1.125rem;
		height: 1.125rem;
		background: var(--c-main);
		border: 0;
		border-radius: 50%;
		box-shadow: none;
		transform: translate(0, -50%);
		z-index: 1;
		cursor: pointer;
	}
}
.range-slider .irs--round .irs-handle.state_hover,
	.range-slider .irs--round .irs-handle:hover {
	background: var(--c-main);
}
.range-slider .irs-handle > i,
	.range-slider .irs-min,
	.range-slider .irs-max,
	.range-slider .irs-from,
	.range-slider .irs-to,
	.range-slider .irs-single {
	display: none;
}
.catalog__foot {
	display: flex;
	justify-content: center;
	&.mt {
		margin-top: 1.75rem;
	}
}
.paginator {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1.5rem auto 0;
	width: 100%;
	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 0.125rem;
		li {
			display: block;
			margin: 0.125rem;
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 2.25rem;
				height: 2.25rem;
				color: var(--c-main);
				font-weight: 500;
				font-size: 0.875rem;
				line-height: 1;
				letter-spacing: 0.002em;
				text-align: center;
				border: 0.0625rem solid #DCD6D1;
				border-radius: 0.5rem;
				&:hover {
					text-decoration: none;
				}
				&.active {
					color: #fff;
					background: var(--c-main);
					border-color: var(--c-main);
				}
				&.empty {
					pointer-events: none;
				}
			}
		}
	}
}
.paginator__arrow {
	display: block;
	width: 1.5rem;
	height: 1.5rem;
	.ico {
		width: 100%;
		height: 100%;
	}
}
.catalog__tags {
	padding: 1.5rem;
	background: #F8F5F1;
	border-radius: 0.5rem;
	.catalog__tags-link {
		display: inline-flex;
		margin: 0.625rem 0 0 0.5rem;
		padding: 0;
		height: auto;
		font-size: 1.125rem;
		line-height: 1.75rem;
		text-decoration: underline;
		cursor: pointer;
		span {
			&:nth-child(1) {
				display: block;
			}
			&:nth-child(2) {
				display: none;
			}
		}
	}
	&.show {
		.catalog__tags-link {
			span {
				&:nth-child(1) {
					display: none;
				}
				&:nth-child(2) {
					display: block;
				}
			}
		}
	}
	.information__link {
		display: inline-flex;
		margin-top: 2.5rem;
	}
}
.catalog__tags-list {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: -0.25rem;
	li {
		padding: 0.25rem;
	}
	a {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 0 0.9375rem;
		height: 3rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		background: #fff;
		border: 0.0625rem solid #DCD6D1;
		border-radius: 6.25rem;
		transition: 0.3s;
	}
}
.catalog__tags-col {
	margin: -0.25rem;
	padding: 0.25rem 0;
	.catalog__tags-list {
		margin: 0;
	}
}
.all-filters {
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 4rem 1.5rem 6.5rem;
	width: 30rem;
	height: calc(100vh - 3.75rem - 1.75rem);
	background: rgba(248, 245, 241, 1);
	border-radius: 0 0.75rem 0.75rem 0;
	transform: translate(-100%, 0);
	transition: 0.3s;
	overflow: hidden;
	pointer-events: none;
	z-index: 100;
	&.active {
		transform: translate(0, 0);
		pointer-events: auto;
	}
	.head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 1.5rem;
		width: 100%;
		height: 4rem;
		background: #F8F5F1;
		z-index: 1;
		h5 {
			margin: 0;
			font-size: 1.25rem;
			line-height: 1.5rem;
		}
		.close {
			width: 1.5rem;
			height: 1.5rem;
			cursor: pointer;
		}
	}
	.body {
		position: relative;
		width: 100%;
		height: 100%;
		& > .scrollbar-inner {
			height: 100%;
			.scrollbar-inner {
				padding-right: 0.875rem;
			}
		}
	}
	.btns {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 0 1.5rem;
		width: 100%;
		height: 6.5rem;
		background: #F8F5F1;
		z-index: 3;
		.btn {
			padding: 0.75rem 0;
			width: calc(50% - (0.5rem / 2));
			&.disabled {
				display: none;
				& + .btn {
					width: 100%;
				}
			}
		}
	}
}
.all-filters-block {
	margin-bottom: 0.75rem;
	background: #fff;
	border-radius: 0.5rem;
	.form-checkbox {
		padding: 0.5rem;
	}
}
.all-filters__accord {
	padding: 0.5rem;
}
.all-filters__title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.005em;
}
.all-filters__link {
	padding: 0.5rem;
	height: 2.5rem;
	cursor: pointer;
	.ico {
		transform: rotate(0deg);
		transition: 0.3s;
	}
	&.active {
		.ico {
			transform: rotate(180deg);
		}
	}
}
.all-filters__height {
	height: 15rem;
	& > .scrollbar-inner {
		height: 100%;
	}
}
.all-filters-block--size {
	.all-filters__height {
		height: auto;
	}
}
.all-filters-block--other {
	.all-filters__height {
		height: auto;
	}
}
.all-filters-price {
	padding: 0.75rem 0.75rem 0.875rem;
	.all-filters__title {
		margin-bottom: 0.5rem;
	}
}
.all-filters-sizes {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: -0.125rem 0;
	padding: 0 0.375rem;
	li {
		margin: 0.125rem;
	}
	.form-checkbox {
		padding: 0;
		.label-dot {
			margin: 0;
			width: 2.25rem;
			height: 2.25rem;
			font-size: 0.875rem;
			line-height: 1;
			border: 0.0625rem solid #DCD6D1;
			border-radius: 0.5rem;
			&::after {
				display: none;
			}
		}
		input {
			&:checked {
				& + .label-dot {
					color: #fff;
					background: var(--c-main);
					border-color: var(--c-main);
				}
			}
		}
	}
}
.all-filters__activated {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 0.25rem;
	& > li {
		position: relative;
		margin-bottom: 0.5rem;
		margin-right: 0.5rem;
		& > a {
			padding: 0.3125rem 0.6875rem;
			color: var(--c-main);
			font-weight: 500;
			font-size: 0.75rem;
			line-height: 1rem;
			white-space: nowrap;
			background: #fff;
			border: 0.0625rem solid #B3A8A1;
			border-radius: 6.25rem;
			&:hover {
				text-decoration: none;
			}
			&.reset-all {
				background: #F8F5F1;
				border-color: #F8F5F1;
			}
			.ico {
				margin-left: 0.125rem;
				width: 1rem;
				height: 1rem;
			}
			.color {
				display: block;
				width: 0.75rem;
				height: 0.75rem;
				border-radius: 50%;
			}
		}
	}
}
.filter--choosed {
	height: 26rem;
	.filters__collapse-wrap {
		width: 20.75rem;
	}
	.filters__collapse-container {
		&.out {
			&.active {
				margin-left: 0.5rem;
				width: 20.75rem;
				&.active-inn {
					width: 42rem;
				}
			}
		}
	}
	.active-inn {
		.filters__collapse-container {
			&.inn {
				margin-left: 0.5rem;
				width: 20.75rem;
			}
		}
	}
}
.filter--category {
	height: 20rem;
	.filters__collapse-wrap {
		width: 16rem;
	}
}
.filter--price {
	flex-direction: column;
	.filters__collapse-wrap {
		width: 16rem;
	}
}
.filter--sort {
	left: auto;
	right: 0;
	height: 13.5rem;
	.filters__collapse-wrap {
		width: 16rem;
	}
}
