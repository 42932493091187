.search-board {
	position: fixed;
	top: 1.75rem;
	left: 0;
	width: 100%;
	z-index: 101;
}

.search-board__form {
	padding: 0.625rem 0;
	background: #fff;
	backdrop-filter: blur(0.9375rem);
}

.search-board__form form {
	display: flex;
	align-items: center;
}

.search-board__form form .form-group {
	position: relative;
	margin: 0;
	width: 100%;
}

.search-board__form form .form-control {
	padding: 0 6.25rem 0 2.5rem;
	color: var(--c-main);
	height: 2.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
	border: 0.0625rem solid #DCD6D1;
	border-radius: 0.5rem;
}

.search-board__form form .form-control::-webkit-input-placeholder {
	color: #DCD6D1;
}

.search-board__form form .form-control::-moz-placeholder {
	color: #DCD6D1;
}

.search-board__form form .form-control:-moz-placeholder {
	color: #DCD6D1;
}

.search-board__form form .form-control:-ms-input-placeholder {
	color: #DCD6D1;
}

.search-board__form form .form-control:focus {
	border-color: var(--c-main);
}

.form-control__search {
	position: absolute;
	top: 50%;
	left: 0.5rem;
	width: 1.5rem;
	height: 1.5rem;
	transform: translate(0, -50%);
}

.form-control__clear {
	position: absolute;
	top: 50%;
	right: 0.5rem;
	margin: 0;
	color: #B3A8A1;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.002em;
	opacity: 0;
	transform: translate(0, -50%);
	pointer-events: none;
}

.form-control__clear:hover {
	color: #B3A8A1;
	text-decoration: none;
}

form .typed .form-control__clear {
	opacity: 1;
	pointer-events: auto;
}

.search-board__close {
	flex-shrink: 0;
	margin-left: 0.75rem;
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
}

.search-board__cont {
	padding: 1.5rem 0;
	width: 100%;
	background: rgba(255, 255, 255, 0.9);
	backdrop-filter: blur(1.875rem);
}

.search-board__cont h5 {
	margin-bottom: 1rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.search-board__cont .card {
	background: transparent;
}

.search-board__cont .btns {
	padding-top: 1.25rem;
}

.search-board__cont .btns .btn {
	width: 100%;
}

.search-board__cont-menu .menu--empty {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: flex-start;
	margin-bottom: 2rem;
}

.search-board__cont-menu .menu--empty li {
	display: flex;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
}

.search-board__cont-menu .menu--empty li a {
	display: inline-block;
	padding: 0.375rem 0.75rem;
	color: var(--c-main);
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.005em;
	text-decoration: none;
	background: #fff;
	border-radius: 6.25rem;
}

.search-board__cont-menu .menu--searching {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 2rem;
}

.search-board__cont-menu .menu--searching li {
	display: flex;
	width: 100%;
}

.search-board__cont-menu .menu--searching li a {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.375rem 0;
	width: 100%;
	color: var(--c-main);
	font-size: 1.125rem;
	line-height: 1.75rem;
	text-decoration: none;
}

.search-board__cont-menu .menu--searching li a::after {
	content: '';
	margin-left: 0.75rem;
	width: 1.5rem;
	height: 1.5rem;
	background: url("../../img/svg/search-grey.svg") no-repeat 50%/contain;
}