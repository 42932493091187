.collapse__menu {
	position: absolute;
	top: 3.75rem;
	left: 0;
	width: 100%;
	background: rgba(255, 255, 255, 0.9);
	backdrop-filter: blur(1.875rem);
	z-index: 10;
}

.collapse__menu-wrap {
	position: relative;
	padding: 1.5rem 0;
	height: calc(100vh - 5.5rem);
}

.collapse__menu-wrap .row .row.scroll-wrapper {
	width: calc(100% + 0.5rem);
}

.collapse__menu-wrap .row .row.scroll-wrapper > .scroll-content {
	padding-right: 0.5rem;
	width: 100% !important;
}

.collapse__menu-wrap .row > div {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.collapse__menu-wrap div {
	height: 100%;
}

.collapse__menu-wrap .collapse__menu-list--small-wrap {
	height: auto;
}

.collapse__menu-wrap h4 {
	margin: 0 0 0.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: -0.002em;
}

.collapse__menu-close {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	width: 3.5rem;
	height: 3.5rem;
	background: var(--c-main);
	border-radius: 0.5rem;
	transition: 0.15s;
}

.collapse__menu--block {
	width: 100%;
	height: 100%;
}

.collapse__menu-kv {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	position: relative;
	padding: 1.75rem;
	width: 100%;
	height: 100%;
	color: #fff;
	z-index: 1;
}

.collapse__menu-kv img {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	object-fit: cover;
	z-index: -1;
}

.collapse__menu-kv h3 {
	margin: 0;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
}

.collapse__menu-list--big {
	margin-bottom: 2.75rem;
}

.collapse__menu-list--big:last-child {
	margin-bottom: 0;
}

.collapse__menu-list--big li:not(:last-child) {
	margin-bottom: 0.75rem;
}

.collapse__menu-list--big li h3 {
	margin-bottom: 0.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
	text-transform: uppercase;
}

.collapse__menu-list--big li a {
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.001em;
}

.collapse__menu-list--big li a.red {
	color: #FF4E44;
}

.collapse__menu-list--big li p {
	margin: 0;
	padding-bottom: 0.25rem;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: -0.001em;
}

.collapse__menu-list--small.mb--big {
	margin-bottom: 4.75rem;
}

.collapse__menu-list--small.mb--medium {
	margin-bottom: 3.5rem;
}

.collapse__menu-list--small.mb--small {
	margin-bottom: 2.5rem;
}

.collapse__menu-list--small li:not(:last-child) {
	margin-bottom: 0.25rem;
}

.collapse__menu-list--small li.last {
	padding-top: 0.5rem;
}

.collapse__menu-list--small a {
	font-size: 1.5rem;
	line-height: 2rem;
}

.collapse__menu-list--small a.f-exp {
	font-size: 1rem;
	line-height: 1.5rem;
}