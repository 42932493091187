@media (max-width: 991px) {
	.subscribe {
		margin-bottom: 1rem;
	}

	.subscribe__block {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 0.75rem;
		border-radius: 0.75rem;
	}

	.subscribe__block h5 {
		margin-bottom: 0.75rem;
		font-size: 1.0625rem;
		line-height: 1.375rem;
		letter-spacing: 0;
	}

	.subscribe__block form {
		margin-left: 0;
		width: 100%;
	}

	.subscribe__block form .form-control {
		padding: 0.25rem 2.75rem 0 1rem;
		width: 100%;
		height: 2.75rem;
		font-size: 1rem;
		line-height: 1.5rem;
		border-radius: 0.5rem;
	}

	.subscribe__block form .btn-secondary {
		right: 0.25rem;
		width: 2.25rem;
		height: 2.25rem;
	}

	.subscribe__block form .btn-secondary .ico {
		width: 1rem;
		height: 1rem;
	}
}