.subscribe {
	margin-bottom: 1.5rem;
}

.subscribe__block {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem 0;
	background: var(--c-main);
	border-radius: 0.75rem;
}

.subscribe__block h5 {
	margin: 0;
	color: #fff;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.01em;
}

.subscribe__block form {
	margin-left: 2.5rem;
}

.subscribe__block form .form-group {
	position: relative;
	margin: 0;
}

.subscribe__block form .form-control {
	padding: 0.25rem 11rem 0 1rem;
	width: 27.5rem;
	height: 3.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
	background: #fff;
	border: 0;
	border-radius: 0.5rem;
	box-shadow: none;
}

.subscribe__block form .form-control::-webkit-input-placeholder {
	color: var(--c-main);
}

.subscribe__block form .form-control::-moz-placeholder {
	color: var(--c-main);
}

.subscribe__block form .form-control:-moz-placeholder {
	color: var(--c-main);
}

.subscribe__block form .form-control:-ms-input-placeholder {
	color: var(--c-main);
}

.subscribe__block form .btn-secondary {
	padding: 0;
	position: absolute;
	top: 50%;
	right: 0.25rem;
	width: 9.375rem;
	height: 3rem;
	font-size: 0.875rem;
	transform: translate(0, -50%);
}