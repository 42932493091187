.lk {
	padding-top: 1.5rem;
}

.lk__wrap {
	align-items: flex-start;
}

.lk__container {
	position: relative;
	overflow: hidden;
}

.lk__container::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: calc(var(--bs-gutter-x) * .5);
	height: 100%;
	background: #fff;
	z-index: 10;
}

.lk__title {
	margin-bottom: 1rem;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.0001em;
}

.lk__favorite {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -1.5rem;
}

.lk__favorite .card {
	margin-right: 1.5rem;
	margin-bottom: 1.5rem;
	width: 22.5rem;
}

.lk__menu {
	position: sticky;
	top: 6.875rem;
	left: 0;
	background: #fff;
	z-index: 2;
}

.lk__menu .nav-tabs {
	flex-direction: column;
	padding-right: 4.625rem;
	border: 0;
}

.lk__menu .nav-tabs .nav-link {
	display: block;
	margin: 0;
	padding: 0.75rem 1rem;
	width: 100%;
	color: var(--c-main);
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: left;
	background: #fff;
	border: 0;
	border-radius: 0.5rem;
}

.lk__menu .nav-tabs .nav-link.active {
	color: #fff;
	background: var(--c-main);
}

.lk__menu-user {
	margin-bottom: 1.5rem;
	padding: 0 1rem;
}

.lk__menu-user .photo {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-bottom: 0.75rem;
	width: 7.5rem;
	height: 7.5rem;
	background: #F8F5F1;
	border-radius: 50%;
}

.lk__menu-user .photo h4 {
	margin: 0;
	color: #B3A8A1;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.lk__menu-user .name h5 {
	margin: 0;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
	word-spacing: 100vw;
}

.lk__main-banner {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-bottom: 1.5rem;
	width: 100%;
	height: 10rem;
	text-align: center;
}

.lk__main-banner picture {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.lk__main-banner picture img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.5rem;
}

.lk__main-banner h4 {
	margin-bottom: 0;
	position: relative;
	color: #fff;
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.001em;
}

.lk__main-top {
	margin-bottom: 1.5rem;
}

.lk__main-top ul {
	display: flex;
}

.lk__main-top ul li {
	margin-right: 1.5rem;
	padding: 1rem;
	width: 21rem;
}

.lk__main-top ul li p {
	margin-bottom: 0.75rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.lk__main-top ul li h5 {
	margin: 0;
	font-size: 1.25rem;
	line-height: 1.5rem;
}

.lk__main-body {
	margin-bottom: 2.75rem;
}

.lk__main-body--title {
	margin-bottom: 1rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
}

.lk__main-foot {
	overflow: hidden;
}

.lk__main-foot h4 {
	margin-bottom: 0.5rem;
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.0001em;
}

.lk__main-foot h5 {
	margin-bottom: 1rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__main-foot-list .swiper-slide {
	margin-right: 1.25rem;
	padding: 1rem;
	width: 26.25rem;
}

.lk__main-foot-list .swiper-slide:last-child {
	margin-right: 0;
}

.lk__main-foot-list .product {
	display: flex;
	align-items: flex-start;
	margin-bottom: 1rem;
}

.lk__main-foot-list .product .img {
	flex-shrink: 0;
	margin-right: 0.75rem;
	width: 6.75rem;
	height: 10.5rem;
}

.lk__main-foot-list .product .img img {
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 0.25rem;
	object-fit: cover;
}

.lk__main-foot-list .product p {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__main-foot-list .vote {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding: 0.25rem 0;
}

.lk__main-foot-list .vote .stars {
	display: flex;
}

.lk__main-foot-list .vote .stars li {
	margin-right: 0.125rem;
}

.lk__main-foot-list .vote .stars li:last-child {
	margin-right: 0;
}

.lk__main-foot-list .vote .stars li i {
	display: block;
	position: relative;
	width: 1.25rem;
	height: 1.25rem;
	background: url("../../img/svg/star-empty.svg") no-repeat 50%/contain;
}

.lk__main-foot-list .vote .stars li i.active {
	background-image: url("../../img/svg/star.svg");
}

.lk__main-foot-list .vote p {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__main-foot-list .btns {
	width: 100%;
}

.lk__main-foot-list .btns .btn {
	font-size: 1rem;
	line-height: 1.5rem;
	width: 100%;
}

.lk__order {
	margin-bottom: 1.5rem;
	padding: 1.5rem;
}

.lk__order:last-child {
	margin-bottom: 0;
}

.lk__order.finish .number--delivery {
	background: #F8F5F1;
}

.lk__order hr {
	display: block;
	width: 100%;
	margin: 1.5rem 0;
	height: 0.0625rem;
	background: #DCD6D1;
	border: 0;
	opacity: 1;
}

.lk__order-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.lk__order-head p {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__order-head h5 {
	margin: 0;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.lk__order-number {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
}

.lk__order-number h3 {
	margin: 0 1rem 0 0;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
}

.lk__order-number .number--payment {
	margin: 0 1rem 0 0;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.lk__order-number .number--delivery {
	display: inline-flex;
	margin: 0;
	padding: 0.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
	background: #F5FF78;
	border-radius: 0.25rem;
}

.lk__order-date {
	margin-bottom: 1rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__order-status {
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.5rem;
}

.lk__order-status li {
	position: relative;
	padding-top: 1.25rem;
	width: 100%;
}

.lk__order-status li:last-child {
	width: 40%;
}

.lk__order-status li:not(:last-child)::before {
	content: '';
	position: absolute;
	top: 0.4375rem;
	left: 0;
	width: 100%;
	height: 0.125rem;
	background: #DCD6D1;
}

.lk__order-status li i {
	position: absolute;
	top: 0;
	left: 0;
	width: 1rem;
	height: 1rem;
	background: #DCD6D1;
	border-radius: 50%;
}

.lk__order-status li p {
	display: block;
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.lk__order-status li small {
	display: block;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0002em;
}

.lk__order-status li.active::before,
.lk__order-status li i.active {
	background: var(--c-main);
}

.lk__order-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 1.5rem;
	border-top: 0.0625rem solid #DCD6D1;
}

.lk__order-info.show .lk__order-info-btn span:nth-child(1) {
	display: none;
}

.lk__order-info.show .lk__order-info-btn span:nth-child(2) {
	display: block;
}

.lk__order-info--wrap {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
}

.lk__order-data {
	width: 44rem;
}

.lk__order-data h3 {
	margin-bottom: 1.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__order-data ul li {
	display: flex;
	margin-bottom: 0.75rem;
}

.lk__order-data ul li p {
	display: block;
	margin: 0;
	width: 100%;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__order-data ul li p:nth-child(1) {
	flex-shrink: 0;
	margin-right: 1rem;
	width: 15rem;
}

.lk__order-photo {
	display: flex;
	align-items: flex-start;
}

.lk__order-photo .img {
	margin-left: 0.5rem;
	width: 4.75rem;
	height: 7.25rem;
}

.lk__order-photo .img img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.25rem;
}

.lk__order-info-col {
	width: 100%;
}

.lk__order-info-btn {
	display: inline-flex;
	margin-top: 1.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: underline;
}

.lk__order-info-btn span:nth-child(1) {
	display: block;
}

.lk__order-info-btn span:nth-child(2) {
	display: none;
}

.lk__order-foot {
	margin-top: 1.5rem;
	display: flex;
}

.lk__order-foot .btn {
	margin-right: 1.5rem;
}

.lk__order-table {
	margin-top: 1.5rem;
	padding-top: 1.5rem;
	border-top: 0.0625rem solid #DCD6D1;
}

.lk__order-table .head ul {
	display: flex;
	padding: 0.75rem 0;
	width: 100%;
}

.lk__order-table .head ul li:nth-child(1) {
	width: 40%;
}

.lk__order-table .head ul li:nth-child(2) {
	width: 20%;
	text-align: right;
}

.lk__order-table .head ul li:nth-child(3) {
	width: 20%;
	text-align: right;
}

.lk__order-table .head ul li:nth-child(4) {
	width: 20%;
	text-align: right;
}

.lk__order-table .body ul li {
	display: flex;
	align-items: flex-start;
	padding: 0.75rem 0;
}

.lk__order-table .body ul li > div:nth-child(1) {
	width: 40%;
}

.lk__order-table .body ul li > div:nth-child(2) {
	width: 20%;
	text-align: right;
}

.lk__order-table .body ul li > div:nth-child(3) {
	width: 20%;
	text-align: right;
}

.lk__order-table .body ul li > div:nth-child(4) {
	width: 20%;
	text-align: right;
}

.lk__order-table .body .product {
	display: flex;
	align-items: flex-start;
}

.lk__order-table .body .product .img {
	flex-shrink: 0;
	margin-right: 0.75rem;
	width: 4.75rem;
	height: 7.25rem;
}

.lk__order-table .body .product .img img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.25rem;
}

.lk__order-table .body .product .text {
	padding: 0 0.75rem;
}

.lk__order-table .body p {
	margin-bottom: 0.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__order-table .body p:last-child {
	margin-bottom: 0;
}

.lk__order-table .body small {
	display: flex;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.lk__order-table .body small span {
	margin-right: 0.75rem;
}

.lk__order-table .foot ul li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.25rem 0;
}

.lk__order-table .foot ul li p {
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.lk__profile { display: flex; flex-direction: column; align-items:flex-start; }

.lk__profile .nav-tabs {
	margin-bottom: 2rem;
	border-bottom: 0.0625rem solid #DCD6D1;
}

.lk__profile .nav-tabs li {
	margin-right: 1.5rem;
}

.lk__profile .nav-tabs li:last-child {
	margin-right: 0;
}

.lk__profile .nav-tabs .nav-link {
	margin: 0;
	padding: 0.75rem 0 0.625rem;
	color: #B3A8A1;
	font-size: 1.125rem;
	line-height: 1.75rem;
	border: 0;
	border-bottom: 0.125rem solid transparent;
	border-radius: 0;
}

.lk__profile .nav-tabs .nav-link.active {
	color: var(--c-main);
	border-bottom-color: var(--c-main);
}

.lk__profile form > :last-child {
	margin-bottom: 0;
}

.lk-subscribe__block {
	margin-bottom: 1.25rem;
	padding: 1.5rem;
	width: 28.5rem;
}

.lk-subscribe__block h4 {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk-subscribe__block h5 {
	margin-bottom: 1.25rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0002em;
}

.lk-subscribe__block :last-child {
	margin-bottom: 0;
}

.lk__address:not(:last-child) {
	margin-bottom: 1.5rem;
}

.lk__address-title {
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
}

.lk__address-title h4 {
	margin: 0 1.5rem 0 0;
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.001em;
}

.lk__address-title a {
	font-size: 1.125rem;
	line-height: 1.75rem;
	text-decoration: underline;
}

.lk__address-row .swiper-wrapper {
	align-items: stretch;
}

.lk__address-row .swiper-slide {
	display: flex;
	flex-direction: column;
	margin-right: 1.5rem;
	padding: 1.5rem;
	width: 28.5rem;
	height: auto;
}

.lk__address-row .swiper-slide.show {
	z-index: 1;
}

.lk__address-row .head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.25rem;
}

.lk__address-row .lk__address-main {
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.lk__address-row .body p {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
	max-height: 7rem;
	text-overflow: ellipsis;
	overflow: hidden;
}

.lk__address-dots {
	position: relative;
	z-index: 10;
}

.lk__address-btn {
	cursor: pointer;
}

.lk__address-col {
	position: absolute;
	left: 50%;
	bottom: 0;
	padding: 0.5rem;
	background: #fff;
	box-shadow: 0 0.625rem 1.875rem 0 rgba(38, 36, 34, 0.08);
	border-radius: 0.5rem;
	transform: translate(-50%, 100%);
}

.lk__address-col li a {
	padding: 0.5rem;
	width: 11.75rem;
	border-radius: 0.25rem;
}

.lk__address-col li a .ico-chevron-right-b {
	margin-left: auto;
}

.lk__bonus-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 1.5rem;
	padding: 1.5rem;
	border-radius: 0.5rem;
	height: 21rem;
}

.lk__bonus-card.basic {
	background: #F8F5F1;
}

.lk__bonus-card.basic ul li * {
	color: var(--c-main);
}

.lk__bonus-card.silver {
	background: #B3A8A1;
}

.lk__bonus-card.silver ul li * {
	color: var(--c-main);
}

.lk__bonus-card.gold {
	background: var(--c-main);
}

.lk__bonus-card.gold ul li * {
	color: #fff;
}

.lk__bonus-card ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.lk__bonus-card .top h5 {
	margin: 0;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.lk__bonus-card .mid p {
	margin: 0;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0002em;
}

.lk__bonus-card .mid h2 {
	margin: 0;
	font-size: 5rem;
	line-height: 5.25rem;
	letter-spacing: -0.002em;
}

.lk__bonus-card .mid h6 {
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
}

.lk__bonus-card .bot p {
	margin: 0;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0002em;
}

.lk__bonus-card .bot h6 {
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
}

.lk__bonus-score {
	padding: 1.5rem;
}

.lk__bonus-score--title {
	margin-bottom: 1rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.lk__bonus-score-list {
	height: 31rem;
}

.lk__bonus-score-list > .scrollbar-inner {
	height: 100%;
}

.lk__bonus-score-list ul {
	padding-right: 1.25rem;
}

.lk__bonus-score-list ul li {
	position: relative;
	margin-bottom: 1rem;
}

.lk__bonus-score-list ul li:last-child {
	margin-bottom: 0;
}

.lk__bonus-score-list ul li h4 {
	margin-bottom: 0.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.lk__bonus-score-list ul li h5 {
	margin-bottom: 0.25rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__bonus-score-list ul li h6 {
	margin-bottom: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}

.lk__bonus-score-list ul li p {
	position: absolute;
	bottom: 1.5rem;
	right: 0;
	margin: 0;
	padding: 0.5rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
	background: #ccc;
	border-radius: 0.25rem;
}

.lk__bonus-score-list ul li.plus p {
	background: #F5FF78;
}

.lk__bonus-score-list ul li.menus p {
	color: #fff;
	background: #FF4E44;
}

.lk__bonus-code {
	margin-bottom: 2.0625rem;
	padding: 1.5rem;
}

.lk__bonus-code h5 {
	margin-bottom: 1.5rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.lk__bonus-code .form-group {
	position: relative;
	margin-bottom: 0;
}

.lk__bonus-code .form-group .btn {
	position: absolute;
	top: 50%;
	right: 0.25rem;
	padding: 0;
	width: 5rem;
	height: 2.6875rem;
	transform: translate(0, -50%);
}

.lk__bonus-code .form-control {
	padding: 0 5.75rem 0 1rem;
	height: 3.125rem;
	font-size: 1.125rem;
}

.lk__bonus-banner {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	position: relative;
	margin-bottom: 1.25rem;
	padding: 1.75rem;
	height: 22.375rem;
	border-radius: 0.5rem;
}

.lk__bonus-banner:last-child {
	margin-bottom: 0;
}

.lk__bonus-banner h3 {
	margin-bottom: 0.25rem;
	color: #fff;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
}

.lk__bonus-banner p {
	margin-bottom: 1.25rem;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
}

.lk__bonus-banner .btn {
	padding: 0.5rem 1.5rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.lk__bonus-banner picture,
.lk__bonus-banner picture img {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.5rem;
	z-index: -1;
}

.lk__refund .top {
	margin-bottom: 1.5rem;
}

.lk__refund .lk__order-info {
	padding: 0;
}

.lk__refund .lk__order-data:first-child {
	padding-top: 1.5rem;
}

.lk__refund .lk__order-table .head ul li:nth-child(1) {
	width: 34%;
}

.lk__refund .lk__order-table .head ul li:nth-child(2) {
	width: 8%;
	padding-left: 0.75rem;
	text-align: left;
}

.lk__refund .lk__order-table .head ul li:nth-child(3) {
	width: 36%;
	padding-left: 0.75rem;
	text-align: left;
}

.lk__refund .lk__order-table .head ul li:nth-child(4) {
	width: 9%;
	text-align: right;
}

.lk__refund .lk__order-table .head ul li:nth-child(5) {
	width: 13%;
	text-align: right;
}

.lk__refund .lk__order-table .body ul li > div:nth-child(1) {
	width: 34%;
}

.lk__refund .lk__order-table .body ul li > div:nth-child(2) {
	width: 8%;
	padding-right: 0.75rem;
	text-align: right;
}

.lk__refund .lk__order-table .body ul li > div:nth-child(3) {
	width: 36%;
	padding-left: 0.75rem;
	text-align: left;
}

.lk__refund .lk__order-table .body ul li > div:nth-child(4) {
	width: 9%;
	text-align: right;
}

.lk__refund .lk__order-table .body ul li > div:nth-child(5) {
	width: 13%;
	text-align: right;
}

.lk__refund-petition .lk__title {
	margin-bottom: 1.5rem;
}

.lk__refund-petition .lk__order-status li:last-child {
	width: 100%;
}

.lk__refund-petition form .form-col-wrap {
	flex-grow: 1;
}

.lk__refund-petition form .form-group {
	flex-grow: 1;
	margin-right: 0;
	width: auto;
}

.lk__refund-petition form .form-group .btn {
	width: 18.5rem;
}

.lk__refund-petition--lk .information {
	padding: 1.5rem;
}

.lk__refund-petition--lk .information p {
	margin-bottom: 1rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__refund-petition--lk .information__link {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.lk__refund-petition--products .lk__order-table {
	margin: 0;
	padding: 0;
	border: 0;
}

.lk__refund-petition--products .lk__order-table .head ul li:nth-child(1) {
	width: 36%;
}

.lk__refund-petition--products .lk__order-table .head ul li:nth-child(2) {
	width: 10%;
	text-align: center;
}

.lk__refund-petition--products .lk__order-table .head ul li:nth-child(3) {
	width: 24%;
	padding: 0 0.75rem;
	text-align: left;
}

.lk__refund-petition--products .lk__order-table .head ul li:nth-child(4) {
	width: 10%;
	padding-left: 0.75rem;
}

.lk__refund-petition--products .lk__order-table .head ul li:nth-child(5) {
	width: 15%;
	text-align: right;
}

.lk__refund-petition--products .lk__order-table .head ul li:nth-child(6) {
	width: 5%;
}

.lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(1) {
	width: 36%;
}

.lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(2) {
	width: 10%;
	text-align: center;
}

.lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(3) {
	width: 24%;
	padding: 0 0.75rem;
}

.lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(4) {
	width: 10%;
	padding-right: 0.3125rem;
	text-align: right;
}

.lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(5) {
	width: 15%;
	padding-right: 0.75rem;
	text-align: right;
}

.lk__refund-petition--products .lk__order-table .body ul li > div:nth-child(6) {
	width: 5%;
	padding-right: 0.5rem;
}

.lk__refund-petition--products .lk__order-table .foot {
	margin-bottom: 2.25rem;
}

.lk__refund-petition--products .lk__order-table .foot ul li p {
	padding: 0.25rem 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.lk__refund-petition--products .form-selector {
	display: flex;
	max-width: 100%;
}

.lk__refund-petition--products .form-selector--btn {
	padding: 0 0.5rem;
	height: auto;
	border: 0;
	border-radius: 0;
	text-align: left;
}

.lk__refund-petition--products .form-selector--btn span {
	width: auto;
}

.lk__refund-petition--products .form-selector--list ul li a {
	width: 100%;
	text-align: left;
}

.lk__refund-petition--products .form-checkbox {
	justify-content: flex-end;
}

.lk__refund-petition--products .form-checkbox .label-dot {
	margin: 0;
}

.lk__refund-petition--products .form-checkbox .label-dot::after {
	background-image: url("../../img/svg/check-2-white.svg");
}

.lk__refund-petition--products .form-checkbox input:checked + .label-dot {
	background: var(--c-main);
}

.lk__refund-petition--products form .form-line > label {
	width: auto;
}

.lk__refund-petition--products form .form-group {
	flex-grow: 0;
	width: 26.5625rem;
}

.lk__refund-petition--products form .form-btns .btn {
	margin-right: 1.5rem;
	width: 18.5rem;
}

.lk__refund-petition--requisites form .form-btns .btn {
	margin-right: 1.5rem;
	width: 18.5rem;
}