.submenu-product {
	position: fixed;
	top: 5.4375rem;
	left: 0;
	width: 100%;
	background: #fff;
	transform: translate(0, -100%);
	transition: 0.3s;
	z-index: 11;
}

.submenu-product.fixed {
	transform: translate(0, 0);
}

.submenu-product__wrap {
	display: flex;
	align-items: center;
	padding: 0.75rem 0;
}

.submenu-product__img {
	margin-right: 0.75rem;
	width: 4.75rem;
	height: 7.25rem;
}

.submenu-product__img img {
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 0.25rem;
	object-fit: cover;
}

.submenu-product__info {
	margin-right: auto;
	max-width: 55.9375rem;
}

.submenu-product__info h3 {
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	line-height: 2rem;
}

.submenu-product__info ul {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}

.submenu-product__info ul li {
	margin-right: 0.75rem;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.submenu-product__info .stars {
	display: flex;
	align-items: center;
}

.submenu-product__info .stars .ico {
	margin-right: 0.25rem;
}

.submenu-product__info .stars p {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.submenu-product__price {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-right: 1.5rem;
}

.submenu-product__price h5 {
	display: inline-flex;
	align-items: center;
	margin: 0;
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: -0.001em;
}

.submenu-product__price p {
	display: inline-flex;
	align-items: center;
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.75rem;
	letter-spacing: 0;
}

.submenu-product__price .discount {
	display: inline-block;
	margin-left: 0.5rem;
	padding: 0.375rem;
	color: #fff;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.0002em;
	background: #FF4E44;
	border-radius: 0.25rem;
}

.submenu-product__price--main {
	color: var(--c-main);
}

.submenu-product__price--new {
	color: #FF4E44;
}

.submenu-product__price--old {
	color: #B3A8A1;
	text-decoration: line-through;
}

.submenu-product__btns {
	display: flex;
}

.submenu-product__btns .btn {
	height: 4rem;
}

.submenu-product__btns .btn-secondary-tr {
	margin-left: 0.5rem;
	padding: 0;
	width: 4rem;
}