.page-company__title {
	margin-bottom: 1.75rem;
	font-size: 5rem;
	line-height: 5.25rem;
	letter-spacing: -0.002em;
	text-align: center;
}
.page-company__tabs {
	display: flex;
	justify-content: center;
	margin-bottom: 3.75rem;
	.nav-tabs {
		padding: 0.125rem;
		border: 0;
		border-radius: 0.5rem;
		background: #F8F5F1;
		li {
			margin: 0 0.0625rem;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			.nav-link {
				position: relative;
				padding: 0;
				width: 12.875rem;
				height: 3.5rem;
				color: var(--c-main);
				font-size: 1rem;
				border: 0;
				border-radius: 0.5rem;
				&:hover {
					color: var(--c-main);
				}
				&.active {
					color: #fff;
					background: var(--c-main);
				}
			}
		}
	}
}
.page-company__coop-text {
	margin-bottom: 3.75rem;
	align-items: center;
	h3 {
		margin-bottom: 1.25rem;
		font-size: 3rem;
		line-height: 3.25rem;
		letter-spacing: -0.001em;
	}
	p {
		margin: 0;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
	ol {
		margin-bottom: 0;
		padding-left: 1.625rem;
		li {
			&::marker {
				font-size: 1.125rem;
				line-height: 1.75rem;
			}
		}
	}
}
.page-company__coop picture,
	.page-company__coop picture img {
	display: block;
	width: 100%;
	height: auto;
	border-radius: 0.5rem;
}
.page-company__coop-scheme {
	margin-bottom: 3.75rem;
	padding: 2.75rem 0;
	background: #F8F5F1;
	h3 {
		margin-bottom: 2.5rem;
		font-size: 3rem;
		line-height: 3.25rem;
		letter-spacing: -0.001em;
	}
	ul {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		li {
			width: 23.125rem;
			img {
				display: block;
				margin-bottom: 1rem;
				width: 3.25rem;
			}
			p {
				margin-bottom: 0.5rem;
				font-size: 1.125rem;
				line-height: 1.75rem;
				&:last-child {
					margin-bottom: 0;
				}
				a {
					text-decoration: underline;
				}
			}
		}
	}
}
.page-company__coop-app {
	display: flex;
	margin-bottom: 3.75rem;
	form {
		padding: 3.25rem;
		width: calc(50% - (var(--bs-gutter-x) * .5));
		border: 0.0625rem solid #DCD6D1;
		border-right: 0;
		border-radius: 0.5rem 0 0 0.5rem;
		.form-group {
			width: calc(50% - 0.75rem);
		}
	}
	picture {
		width: calc(50% + (var(--bs-gutter-x) * .5));
	}
}
.page-company__coop-menu {
	margin-bottom: 3.75rem;
	.banner {
		height: 22.5rem;
	}
}
.page-company__coop-menu picture,
	.page-company__coop-menu picture img {
	width: 100%;
	height: 100%;
}
.page-company__coop-enter {
	display: flex;
	align-items: flex-start;
	picture {
		width: calc(50% - (var(--bs-gutter-x) * .5));
	}
	form {
		padding: 3.25rem;
		width: calc(50% + (var(--bs-gutter-x) * .5));
		border: 0.0625rem solid #DCD6D1;
		border-left: 0;
		border-radius: 0 0.5rem 0.5rem 0;
		.form-foot {
			.form-text {
				margin-bottom: 0.75rem;
			}
			.links {
				display: flex;
				justify-content: center;
				a {
					margin: 0 0.875rem;
					font-size: 1rem;
					line-height: 1.5rem;
				}
			}
		}
	}
}
.page-company__vacancies-tabs {
	display: flex;
	justify-content: center;
	margin-bottom: 2.5rem;
	.nav-tabs {
		border: 0;
		border-bottom: 0.0625rem solid #DCD6D1;
		li {
			margin: 0 0.75rem;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			.nav-link {
				position: relative;
				padding: 0.75rem 0;
				color: #B3A8A1;
				font-weight: 600;
				font-size: 1.125rem;
				line-height: 1.75rem;
				border: 0;
				&:hover {
					color: var(--c-main);
					&::after {
						display: block;
					}
				}
				&.active {
					color: var(--c-main);
					&::after {
						display: block;
					}
				}
				&::after {
					content: '';
					display: none;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 0.125rem;
					background: var(--c-main);
				}
			}
		}
	}
}
.page-company__vacancies {
	.tab-content {
		padding-bottom: 0.5rem;
	}
	.block {
		display: block;
		margin-bottom: 1.25rem;
		padding: 1.5rem;
		width: 100%;
		cursor: pointer;
		h5 {
			margin-bottom: 0.75rem;
			color: #B3A8A1;
			font-size: 0.875rem;
			line-height: 1.25rem;
			letter-spacing: 0.0002em;
		}
		h6 {
			margin-bottom: 0.5rem;
			font-size: 1rem;
			line-height: 1.5rem;
		}
		p {
			margin: 0;
			font-size: 0.875rem;
			line-height: 1.25rem;
			letter-spacing: 0.0002em;
		}
	}
}
.page-company__vacancy-main {
	position: relative;
	padding-bottom: 3.75rem;
	h6 {
		margin-bottom: 0.75rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
	ul {
		margin-bottom: 1.5rem;
		li {
			display: flex;
			align-items: flex-start;
			font-size: 1rem;
			line-height: 1.5rem;
			&::before {
				content: '·';
				flex-shrink: 0;
				margin: 0 0.5rem;
			}
		}
	}
	& > :last-child {
		margin-bottom: 0;
	}
}
.page-company__vacancy {
	.back {
		display: block;
		margin-left: auto;
		width: 1.5rem;
		height: 1.5rem;
		cursor: pointer;
	}
}
.page-company__vacancy-title {
	margin-bottom: 1.5rem;
	font-size: 1.25rem;
	line-height: 1.5rem;
}
.page-company__vacancy-aside {
	.bd {
		margin-bottom: 1.75rem;
		padding: 1.5rem;
	}
	ul {
		li {
			margin-bottom: 1rem;
			&:last-child {
				margin-bottom: 0;
			}
			h6 {
				margin-bottom: 0.25rem;
				color: #B3A8A1;
				font-size: 1rem;
				line-height: 1.5rem;
			}
			p {
				margin: 0;
				font-size: 1rem;
				line-height: 1.5rem;
			}
		}
	}
	.btn {
		width: 100%;
	}
}
.page-company__vacancies-banner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-top: 3.25rem;
	width: 100%;
	height: 10rem;
	text-align: center;
	border-radius: 0.5rem;
	h4 {
		margin-bottom: 0.75rem;
		color: #fff;
		font-size: 1.75rem;
		line-height: 2rem;
		letter-spacing: -0.001em;
	}
	.btn {
		padding: 0;
		width: 13.5rem;
		height: 3rem;
		font-size: 0.875rem;
	}
}
.page-company__vacancies-banner picture,
	.page-company__vacancies-banner picture img {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	z-index: -1;
}
.page-company__contacts-data {
	margin-bottom: 1.25rem;
	padding: 1.5rem;
	ul {
		li {
			display: flex;
			flex-direction: column;
			margin-bottom: 1.25rem;
			&:last-child {
				margin-bottom: 0;
			}
			h6 {
				margin-bottom: 0.5rem;
				color: #B3A8A1;
				font-size: 0.875rem;
				line-height: 1.25rem;
				letter-spacing: 0.0002em;
			}
			p {
				margin: 0;
				font-size: 1rem;
				line-height: 1.5rem;
			}
		}
	}
}
.page-company__contacts-payer {
	padding: 1.25rem;
	ul {
		li {
			margin-bottom: 0.75rem;
			font-size: 0.875rem;
			line-height: 1.25rem;
			letter-spacing: 0.0002em;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.page-company__contacts-map {
	h5 {
		margin-bottom: 1rem;
		font-size: 1.5rem;
		line-height: 1.75rem;
	}
	p {
		margin-bottom: 1.125rem;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0.0002em;
	}
	.iframe {
		display: block;
		width: 100%;
		height: auto;
		border-radius: 0.5rem;
	}
}
.page-company__contacts-map-tabs {
	display: flex;
	margin-bottom: 0.9375rem;
	.nav-tabs {
		border: 0;
		border-bottom: 0.0625rem solid #DCD6D1;
		li {
			margin: 0 0.75rem;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			.nav-link {
				position: relative;
				padding: 0.75rem 0;
				color: #B3A8A1;
				font-weight: 600;
				font-size: 1.125rem;
				line-height: 1.75rem;
				border: 0;
				&:hover {
					color: var(--c-main);
					&::after {
						display: block;
					}
				}
				&.active {
					color: var(--c-main);
					&::after {
						display: block;
					}
				}
				&::after {
					content: '';
					display: none;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 0.125rem;
					background: var(--c-main);
				}
			}
		}
	}
}
