@media (max-width: 991px) {
	.line-page__kv-block {
		padding: 1.75rem 1.125rem;
		height: 30rem;
	}

	.line-page__kv-block p {
		font-size: 0.9375rem;
		line-height: 1.3125rem;
	}

	.line-page__kv-title {
		width: 100%;
	}

	.line-page .row:last-child > div {
		margin-bottom: 0.75rem;
	}

	.line-page .row:last-child > :last-child {
		margin-bottom: 0;
	}

	.line-page .row:last-child .line-page__text :last-child {
		margin-bottom: 0;
	}

	.line-page .now-buy__slider {
		padding: 0 0.75rem;
	}

	.line-page__text h1 {
		font-size: 4.375rem;
		letter-spacing: -0.01em;
	}

	.line-page__text h3 {
		margin-bottom: 0.75rem;
		font-size: 1.1875rem;
		line-height: 1.25rem;
		letter-spacing: 0;
	}

	.line-page__text p {
		margin-bottom: 0.75rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.line-page__img {
		display: block;
		width: 100%;
	}

	.line-page__img img {
		display: block;
		width: 100%;
		height: auto;
		border-radius: 0.5rem;
	}

	.line-page__title {
		position: relative;
		margin: 0;
		height: 0;
		z-index: -1;
	}

	.line-page__title img {
		display: block;
		position: relative;
		bottom: 1.625rem;
		width: 100%;
		transform: translate(0, -50%);
	}

	.line-page__absolute {
		position: relative;
		padding: 0.75rem 0.625rem;
		width: 100%;
		height: auto;
		pointer-events: auto;
	}

	.line-page__model {
		padding-bottom: 0;
	}

	.line-page__model-bg {
		top: 0.75rem;
		width: 61.0625rem;
	}

	.line-page__model-img {
		top: 13rem;
		right: -3.25rem;
		width: 24.25rem;
	}

	.line-page__model--top {
		padding-top: 0.75rem;
	}

	.line-page__model--mid {
		justify-content: flex-start;
	}

	.line-page__model--bot {
		justify-content: flex-start;
	}

	.line-page__advantage .col {
		padding: 0;
	}

	.line-page__advantage-block img {
		border-radius: 0;
	}

	.line-page__advantage-block .line-page__text {
		position: relative;
		padding: 0.75rem;
		border-radius: 0;
	}

	.line-page__text-title {
		position: absolute;
		left: auto;
		top: -3rem;
		right: 0;
	}

	.line-page__choose-block {
		width: 100%;
		height: 29.25rem;
	}

	.line-page__choose-block picture img {
		border-radius: 0.5rem;
	}

	.line-page__choose-block .btn {
		top: 0.5rem;
		right: 0.5rem;
		width: 7.25rem;
		height: 2.25rem;
	}

	.line-page__choose-block .btn span {
		font-size: 0.6875rem;
		line-height: 0.875rem;
	}

	.line-page__choose-block .choose__slide-name {
		bottom: 1rem;
		font-size: 0.9375rem;
		line-height: 1.75rem;
		letter-spacing: 0;
	}

	.line-page__choose-block .hint-1 .hint__dot {
		top: 10.375rem;
		left: 12.375rem;
	}

	.line-page__choose-block .hint-2 .hint__dot {
		top: 17.125rem;
		left: 7.75rem;
	}

	.line-page__choose-block .hint-3 .hint__dot {
		top: 20.375rem;
		left: 13rem;
	}

	.line-page__choose-block .hint__dot {
		width: 1rem;
		height: 1rem;
	}

	.line-page__choose-block .hint__dot::before {
		width: 300%;
		height: 300%;
	}

	.line-page__choose-block .hint__dot::after {
		width: 0.5rem;
		height: 0.5rem;
	}

	.line-page__choose-block .card {
		padding: 0.25rem;
		width: 5.75rem;
		border-radius: 0.5rem;
	}

	.line-page__choose-block .card:hover {
		border-radius: 0.5rem;
	}

	.line-page__choose-block .card:hover .card__img picture img {
		border-radius: 0.25rem;
	}

	.line-page__choose-block .card .bot {
		display: none;
	}

	.line-page__choose-block .card-1 {
		top: 2.875rem;
		right: 0.5rem;
	}

	.line-page__choose-block .card-2 {
		top: 2.875rem;
		left: 0.5rem;
	}

	.line-page__choose-block .card-3 {
		top: 14.75rem;
		right: 0.5rem;
	}

	.line-page__choose-block .card__img {
		height: 7.5rem;
	}

	.line-page__choose-block .card__img picture img {
		border-radius: 0.25rem;
	}

	.line-page__choose-block .card__img .top {
		display: none;
	}

	.line-page__choose-block .card__text {
		margin-bottom: 0.125rem;
		height: 2rem;
		font-size: 0.75rem;
		line-height: 1rem;
		white-space: normal;
	}

	.line-page__choose-block .card__price {
		font-size: 0.6875rem;
		line-height: 0.875rem;
	}

	.line-page__choose-block .card__img-slider-dots li {
		margin-right: 0.25rem;
	}

	.line-page__choose .line-page__absolute {
		justify-content: flex-start;
		padding-bottom: 0.75rem;
	}
}