.centerVideo {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: absolute;
	width: 100%;
	left: 0;
	overflow: hidden;
		@media (max-width: 768px) {
	position: relative;
			}
}

.videoDesc{
		@media (max-width: 768px) {
			display: none;
			}
}

.imageHeight{
	@media (max-width: 768px) {
			display: none;
		}
}