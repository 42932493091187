.countdownBlock{
	position: relative;
	height: 150px;
	#clock{
		display: flex;
	}
		.countdownDigit{
			font-size: 68px;
					color: #fff;
					margin: 0 10px;
		}
}
.countdown{
	position: absolute;
 background: #1f243a;
 width: 100%;

}
.clock{
	margin-top: 10px;
		display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			@media screen and (max-width: 768px) {
				gap: 50px;
				}
}
.texts{
		color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 77px;
			margin-top: 20px;
}

 