.now-buy__slider .slick-arrow {
	position: absolute;
	top: -2.875rem;
	padding: 0;
	width: 1.5rem;
	height: 1.5rem;
	color: transparent;
	font-size: 0;
	line-height: 0;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	border: 0;
	border-radius: 0;
	outline: 0;
	box-shadow: none;
	z-index: 1;
	cursor: pointer;
}

.now-buy__slider .slick-arrow:focus {
	outline: 0;
}

.now-buy__slider .slick-prev {
	right: 5.5rem;
	background-image: url("../../img/svg/arrow-left-black.svg");
}

.now-buy__slider .slick-next {
	right: 1.25rem;
	background-image: url("../../img/svg/arrow-right-black.svg");
}

.now-buy__slide {
	margin-right: 1.5rem;
	width: 22.1875rem;
}

.now-buy__slide:last-child {
	margin-right: 0;
}

.card {
	border: 0;
}

.card__img {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	margin-bottom: 0.75rem;
	width: 100%;
	height: 32.8125rem;
	z-index: 0;
	overflow: hidden;
}

.card__img picture {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: -1;
}

.card__img picture img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 0%;
	border-radius: 0.5rem;
}

.card__img .note {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0.1875rem 0.5rem 0.1875rem;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 0.03em;
	text-align: center;
	white-space: nowrap;
	background: rgba(255, 255, 255, 0.9);
	border-radius: 0.25rem;
}

.card__img .note.online {
	color: var(--c-main);
	background: #F5FF78;
}

.card__img .note.last {
	color: #fff;
	background: #FF4E44;
}

.card__img .top {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 0.5rem;
	width: 100%;
}

.card__img .top ul {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.card__img .top ul li:not(:last-child) {
	margin-bottom: 0.25rem;
}

.card__img .top .like {
	display: block;
	position: relative;
	margin-left: auto;
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
}

.card__img .top .like::before {
	background-image: url("../../img/svg/heart.svg");
	opacity: 1;
}

.card__img .top .like::after {
	background-image: url("../../img/svg/heart-full.svg");
	opacity: 0;
}

.card__img .top .like.active::after {
	opacity: 1;
}

.card__img .bot__notes {
	display: flex;
	position: relative;
	padding: 0 0.5rem;
}

.card__img .bot__notes > ul {
	display: flex;
	position: relative;
	padding: 0.5rem 0;
	transition: 0.6s;
}

.card__img .bot__notes > ul li:not(:last-child) {
	margin-right: 0.25rem;
}

.card__img .bot__notes i {
	position: absolute;
	top: 0;
	width: 2.125rem;
	height: 2.125rem;
	opacity: 0;
	z-index: 1;
}

.card__img .bot__notes i.notes__left {
	left: 0;
}

.card__img .bot__notes i.notes__right {
	right: 0;
}

.now-buy--big .card__img {
	height: 41.625rem;
}

.card__img-slider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.card__img-slider picture {
	opacity: 0;
}

.card__img-slider picture.active {
	opacity: 1;
	z-index: 1;
}

.card__img-slider-dots {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 0.5rem;
	width: 100%;
	transform: translate(0, 100%);
	transition: 0.3s;
	z-index: 1;
}

.card__img-slider-dots li {
	display: block;
	margin-right: 0.5rem;
	width: 100%;
	height: 0.125rem;
	background: #fff;
	border-radius: 6.25rem;
	opacity: 0.25;
}

.card__img-slider-dots li:last-child {
	margin-right: 0 !important;
}

.card__img-slider-dots li.active {
	opacity: 1;
}

.card__img .top .like::before,
.card__img .top .like::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
}

.card__basket {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: -6rem;
	padding: 0.75rem;
	width: 100%;
	height: 6rem;
	text-align: center;
	background: rgba(248, 245, 241, 0.95);
	transition: 0.3s;
}

.card__basket p {
	margin-bottom: 0.5rem;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.card__basket ul {
	display: flex;
}

.card__basket ul li {
	display: flex;
	margin: 0 0.125rem;
}

.card__basket ul li a {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 0.25rem;
	width: 2.25rem;
	height: 2.25rem;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 0.002em;
	background: #fff;
	border-radius: 0.5rem;
}

.card__meta {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}

.card__meta li {
	display: flex;
	align-items: center;
	margin-right: 0.5rem;
}

.card__meta li .ico {
	margin-right: 0.25rem;
	width: 1.25rem;
	height: 1.25rem;
}

.card__meta li p {
	position: relative;
	top: 0.125rem;
	margin: 0;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.002em;
}

.card__text {
	margin: 0 0 0.5rem;
	height: 3.5rem;
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 1.75rem;
	overflow: hidden;
	text-overflow: ellipsis;
}

.card__price {
	display: flex;
	align-items: center;
	margin: 0 0 0.5rem;
	font-weight: 600;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.card__price span {
	margin: 0 1rem 0 0;
}

.card__price .new {
	color: #FF4E44;
}

.card__price .old {
	color: #B3A8A1;
	text-decoration: line-through;
}

.card__colors {
	display: flex;
	align-items: center;
}

.card__colors li {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.25rem;
	width: 0.75rem;
	height: 0.75rem;
	border: 0.0625rem solid transparent;
	border-radius: 50%;
}

.card__colors li.active {
	border-color: #B3A8A1;
}

.card__colors li i {
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	transform: scale(0.8);
}

.card__btns {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 0.75rem;
}

.card__btns .add {
	padding: 0;
	width: 18.75rem;
	height: 3.5rem;
}

.card__btns .delete {
	padding: 0;
	width: 3.5rem;
	height: 3.5rem;
}