@media (max-width: 991px) {
    .banner__video-desc {
        display: none;
    }

    .banner__video-mob {
        display: block;
    }

    .subscribe__wrap {
        .subscribe__sb {
            margin-left: 0;
        }
    }

    .kv {
        .container-fluid {
            .row {
                .col {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }

    .collections--pb .page-title {
        &.page-title-header {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .head-line__slider .swiper-slide a {
        font-size: 0.6875rem;
        line-height: 0.875rem;
        text-align: right;

        &:hover {
            color: unset;
        }
    }

    .wrap_cc7a {
        bottom: 5.5rem !important;
    }

    .footer__menu h3::after {
        display: none;
    }

    .footer__menu-link-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .footer__menu-link-dropdown {
            display: block;
            content: "";

            width: 1.25rem;
            height: 1.25rem;
            background: url("../../img/svg/mini-arrow-bot-black.svg") no-repeat 50% / contain;
            transition: 0.3s;

            &.show {
                rotate: 180deg;
            }
        }
    }

    .start__bounce {
        .swiper-wrapper {
            animation: bounce ease-in-out 1.5s;
        }

        @keyframes bounce {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(-150px, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }
    }

    .information__text {
        ul {
            li {
                font-size: 0.8125rem;
                line-height: 1.125rem;
                margin-bottom: 0.5rem;
            }
        }
    }

    .collapse__menu-list--big {
        .collapse__menu-slider .slide h5 {
            color: #fff;
            margin-top: 10px;
        }
    }

    .collapse__menu-list--big {
        &.mt--top {
            margin-top: 1.25rem;
        }

        .collection-desc--mob {
            font-size: 0.9rem;
        }
    }

    .share__block {
        position: relative;

        .information__collapse {
            bottom: -0.5rem;
            left: auto;
            right: 0;
            padding: 0.375rem;
            box-shadow: 0 0.625rem 1.875rem 0 rgba(38, 36, 34, 0.08);
            border-radius: 0.5rem;
            transform: translate(0, 100%);

            .social {
                li {
                    margin-left: 0;
                    padding: 0.375rem;

                    a {
                        font-size: 0.6875rem;
                        line-height: 0.8125rem;

                        .soc {
                            margin-right: 0.5rem;
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }
            }
        }
    }

    .site-title {
        .site-title__h {
            font-size: 0.9375rem;
            line-height: 1.125rem;
            margin: 0;

            display: inline;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .navbar-city-cont {
        z-index: 10;
    }

    .modal-stocks__head .info h3 {
        width: 20rem;
    }

    .stocks .nav-tabs {
        overflow-y: hidden;
    }

    .modal-stocks {
        background: #fff;
    }

    .product__kv-back-page {
        z-index: 1;
    }

    .card__price {
        font-size: 0.725rem;
    }

    .modal-review .modal-body {
        width: 100%;
    }

    .share__block .information__collapse .social li .btn-ico {
        .soc {
            height: 1rem;
            margin-right: 0.5rem;
            width: 1rem;
        }

        .soc-text {
            font-size: 0.6875rem;
            line-height: 0.8125rem;
        }
    }

    .shops__map-info form .form-wrap .form-selector:nth-child(2) .form-selector--list {
        width: 500px;
        // left: 0;
        right: auto;
        overflow-x: scroll;
        // -webkit-appearance: none;
    }

    .shops__map-info form .form-wrap .form-selector .form-selector--list {
        width: 450px;
        // left: 0;
        right: auto;
        overflow-x: scroll;
        // -webkit-appearance: none;
    }

    .shops__store-iframe {
        margin-left: 0;
    }

    .shops__map-info form .form-wrap .form-selector .form-checkbox .label-text {
        font-size: 0.65rem;
        line-height: 1.1875rem;
    }

    .shops__map-info form .form-wrap .form-selector--list ul li a {
        font-size: 0.65rem;
        line-height: 1.1875rem;
    }

    .shops__map-info form .form-selector--list {
        // height: auto;
    }

    .shops__map-info form .form-wrap .form-selector .form-checkbox .label-text {
        flex-shrink: 1;
    }

    .shops .banner__outer {
        width: 100%;
        position: relative;
        padding-bottom: 66.67%;
        height: 0;
        overflow: hidden;
    }

    .shops .banner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .shops__map-info form .form-wrap {
        overflow-y: hidden;
    }

    .modal-main-system.show,
    .modal-main.show {
        z-index: 100;
    }

    .shops__map-info form .form-selector--list {
        height: 16.5rem;
    }

    .shops__map-info form .fs-custom-type {
        height: 4.5rem;
    }

    .shops__map-info form .form-wrap {
        margin-bottom: -18.125rem !important;
        padding: 0.5rem 0.75rem 18.125rem;
    }

    .modal-map__iframe {
        height: 18rem;
    }

    .modal-card-mobile-popup {
        background: #fff;
        top: 0;
        height: 100dvh;
        .modal-content {
            width: 100%;
        }
        .modal-body{
            padding: 0;
        }
        .card-mobile-popup__slider-wrapper {
            width: 100%;
            margin: 0 auto;

            img {
                width: 100%;
                height: auto;
            }
        }

        .modal-feedback__title{
        position: absolute;
        background: unset;
        margin: 0;
        padding: 0;
        z-index: 2;
        top: .5rem;
        right: .5rem;
        border-radius: 0.5rem;
        padding: 7.5px;
        background: white;
        opacity: 0.8;
        }

        .card-mobile-popup__controls {
    display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        left: 0;
        padding: 0 0.75rem;
        width: 100%;
        transform: translate(0, -50%);
        pointer-events: none;
        z-index: 2;

                .slick-btn {
        padding: 0;
            width: 1.5rem;
            height: 1.5rem;
            color: rgba(0, 0, 0, 0);
            font-size: 0;
            line-height: 0;
            background: #DCD6D1;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
            border: 0;
            border-radius: 0;
            outline: 0;
            box-shadow: none;
            cursor: pointer;
            pointer-events: auto;
                    }
                
                    .slick-btn-prev {
                        background-image: url('../../img/svg/arrow-left-black.svg');
                    }
                
                    .slick-btn-next {
                        background-image: url('../../img/svg/arrow-right-black.svg');
                    }
        }

        .card-mobile-popup__thumbnail-slider {
            display: flex;
            overflow-x: auto;
            padding: 10px 0;
            justify-content: center;
            background: #DCD6D1;;

            .thumbnail {
                flex: 0 0 auto;
                margin: 0 5px;
                cursor: pointer;

                img {
                    width: 50px;
                    height: auto;
                }
            }
        }
    }

    .modal-basket-wrapper{
       height: calc(100dvh - 6.75rem)
    }

    .collections__banner .banner {
        height: 30rem;
    }

        .advantages-list .block .circle{
                flex-shrink: 0;
        }

.modal-fashion .modal-basket__product-info .wrap .left .modal-basket__product-title {
        margin-bottom: 0.5rem;
            height: 2.375rem;
            font-size: 0.875rem;
            line-height: 1.1875rem;
            letter-spacing: 0;
}

.product-tab-landings {
    .landing-page {
        margin-top: 2rem;
    }
}

.information__img {
    height: 16rem;
}

.product__kv-slider-main .slick-track {
    margin-left: 0;
}

.product__kv-slider-add .slick-slide {
    padding: 0;
}

.product__kv-slider-add .slick-list {
    margin: 0;
}

.product__kv-info-colors .swiper-wrapper{
    margin-left: 0.5rem;
}

.now-buy__slider  .swiper-wrapper{
    margin-left: 0.5rem;
}

.main-product .now-buy__slider{
    padding: 0;
}

.modal-choose-size-slider {
    background: rgba(0, 0, 0, 0.5);
    z-index: 105;

    .modal-content {
        height: auto;
        position: absolute;
        bottom: 0;
        background: #f8f5f1;
        border: 0;
        border-radius: 1rem 1rem 0 0;
    }

    .modal-body {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1.875rem;
        text-align: center;
    }
}

.modal-fashion .close{
        top: 0.5rem;
}

.modal-fashion .modal-feedback__title{
        margin: 0 -0.75rem 0;
}

.text-page__main-banner{
height: unset;
margin-bottom: 0;
}
.landing_imageHeight{
    display: none;
}

}
