@media (max-width: 991px) {
	.notification {
		top: calc(1.75rem + 2.5rem + 0.5rem);
		padding: 1rem;
		width: calc(100% - (0.75rem * 2));
		border-radius: 0.75rem;
	}

	.notification--left {
		left: 0.75rem;
	}

	.notification--right {
		right: 0.75rem;
	}

	.notification--bottom {
		top: auto;
		bottom: calc(0.75rem + 2.5rem);
	}

	.notification__head {
		margin-bottom: 0.75rem;
	}

	.notification__head h5 {
		font-size: 0.9375rem;
		line-height: 1.125rem;
		letter-spacing: 0;
	}

	.notification__close {
		width: 1.5rem;
		height: 1.5rem;
	}

	.notification__body {
		margin-bottom: 0.75rem;
	}

	.notification__body p {
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.notification__btns .btn {
		width: calc(50% - (0.5rem / 2));
		height: 2.5rem;
		font-size: 0.75rem;
	}

	.notification__product {
		margin: 0 0 0.75rem;
	}

	.notification__product-img {
		margin-right: 0.75rem;
		width: 2.5rem;
		height: 3.5rem;
	}

	.notification__product-img img {
		border-radius: 0.25rem;
	}

	.notification__product-info {
		height: auto;
	}

	.notification__product-info .wrap .left {
		margin: 0;
		width: auto;
	}

	.notification__product-info .wrap .left h6 {
		margin-bottom: 0.5rem;
		height: 2.375rem;
		font-size: 0.875rem;
		line-height: 1.1875rem;
	}

	.notification__product-info .wrap .left ul {
		margin: 0 0 0.5rem;
	}

	.notification__product-info .wrap .left ul li {
		margin-right: 0.5rem;
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.notification__product-info .wrap .left p {
		font-size: 0.75rem;
		line-height: 1rem;
	}
}