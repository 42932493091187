.blogs-list__title {
	margin-bottom: 1.5rem;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
	text-align: center;
}
.blogs-list {
	.nav-tabs {
		border: 0;
		border-bottom: 0.0625rem solid #DCD6D1;
		li {
			margin-right: 1.5rem;
			&:last-child {
				margin-right: 0;
			}
			.nav-link {
				position: relative;
				padding: 0.75rem 0;
				color: #B3A8A1;
				font-weight: 600;
				font-size: 1.125rem;
				line-height: 1.75rem;
				border: 0;
				&:hover {
					color: var(--c-main);
					&::after {
						display: block;
					}
				}
				&.active {
					color: var(--c-main);
					&::after {
						display: block;
					}
				}
				&::after {
					content: '';
					display: none;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 0.125rem;
					background: var(--c-main);
				}
			}
		}
	}
	.form-btns {
		margin-top: 0.5rem;
	}
}
.blogs-list__tabs {
	display: flex;
	justify-content: center;
	margin: 0 auto 1.5rem;
	ul {
		width: auto;
	}
}
.blogs-list__block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	position: relative;
	margin-bottom: 1.25rem;
	width: 100%;
	.info {
		padding: 1.5rem 1.5rem 1rem;
		ul {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin: -0.25rem -0.25rem 0.5rem;
			li {
				margin: 0.25rem;
				padding: 0 0.375rem;
				font-size: 0.875rem;
				line-height: 1.25rem;
				letter-spacing: 0.0002em;
				background: #F8F5F1;
				border-right: 0.25rem;
			}
		}
		h5 {
			margin-bottom: 2.5rem;
			font-size: 1.5rem;
			line-height: 1.75rem;
			letter-spacing: -0.001em;
		}
		a {
			color: #B3A8A1;
			font-size: 0.875rem;
			line-height: 1.25rem;
			text-decoration: underline;
		}
	}
}
.blogs-list__block picture,
	.blogs-list__block picture img {
	display: block;
	width: 100%;
	height: 13.75rem;
	border-radius: 0.5rem 0.5rem 0 0;
	object-fit: cover;
}
.blog__wrap {
	align-items: flex-start;
}
.blog__aside {
	position: sticky;
	top: 9.375rem;
	left: 0;
	.meta {
		display: flex;
		align-items: center;
		p {
			display: inline-flex;
			margin: 0 1.75rem 0 0;
			padding: 0.75rem 1rem;
			font-size: 0.875rem;
			line-height: 1.3125rem;
			letter-spacing: 0.0005em;
			background: #fff;
			border: 0.0625rem solid #DCD6D1;
			border-radius: 6.25rem;
		}
		.information {
			position: relative;
		}
		.information__link {
			color: #B3A8A1;
			font-size: 1rem;
			line-height: 1.5rem;
		}
		.information__collapse {
			position: absolute;
			bottom: -0.625rem;
			left: 50%;
			padding: 0.75rem;
			background: #fff;
			box-shadow: 0 0.625rem 1.875rem 0 rgba(38, 36, 34, 0.08);
			border-radius: 0.5rem;
			transform: translate(-50%, 100%);
			.social {
				flex-direction: column;
				li {
					padding: 0.5rem;
					a {
						font-size: 1rem;
						line-height: 1.5rem;
						letter-spacing: 0.0002em;
						white-space: nowrap;
						.soc {
							margin-right: 0.5rem;
							width: 1.5rem;
							height: 1.5rem;
							background-size: contain;
						}
					}
				}
			}
		}
	}
}
.blog__aside-back {
	margin-bottom: 1.25rem;
	font-size: 1rem;
	line-height: 1.5rem;
	.ico {
		width: 1.5rem;
		height: 1.5rem;
	}
}
.blog__aside-title {
	margin-bottom: 1.25rem;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
}
.blog__main-section {
	margin-bottom: 2.5rem;
}
.blog__main {
	.imgs {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		margin-bottom: 1.75rem;
		&:last-child {
			margin-bottom: 0;
		}
		.img {
			display: flex;
			align-items: center;
			flex-direction: column;
			text-align: center;
			width: 100%;
			img {
				display: block;
				width: 100%;
				height: auto;
				object-fit: cover;
				border-radius: 0.5rem;
			}
			a {
				margin: 0.5rem 0 0;
				font-size: 0.875rem;
				line-height: 1.25rem;
				letter-spacing: 0.0005em;
				text-align: center;
				text-decoration: underline;
			}
		}
	}
	.imgs-2 {
		margin-left: -1.5rem;
		.img {
			padding-left: 1.5rem;
			width: 50%;
			img {
				height: 43.125rem;
			}
		}
	}
	.imgs-3 {
		margin-left: -0.75rem;
		.img {
			padding-left: 0.75rem;
			width: 33.3333%;
			img {
				height: 28.5rem;
			}
		}
	}
	.imgs-4 {
		margin-left: -0.75rem;
		.img {
			padding-left: 0.75rem;
			width: 25%;
			img {
				height: 21rem;
			}
		}
	}
	.imgs-1 {
		.img {
			img {
				height: 33.625rem;
			}
		}
	}
	.text {
		margin-bottom: 1.75rem;
		&:last-child {
			margin-bottom: 0;
		}
		h3 {
			margin-bottom: 0.75rem;
			font-size: 3rem;
			line-height: 3.25rem;
			letter-spacing: -0.001em;
		}
		h4 {
			margin-bottom: 0.75rem;
			font-size: 1.125rem;
			line-height: 1.75rem;
		}
		p {
			margin-bottom: 0.75rem;
			font-size: 1rem;
			line-height: 1.5rem;
			letter-spacing: 0.0002em;
		}
		small {
			margin-bottom: 0.75rem;
			font-size: 0.875rem;
			line-height: 1.25rem;
			letter-spacing: 0.0002em;
		}
		:last-child {
			margin-bottom: 0;
		}
	}
}
.blog__main-banner {
	display: flex;
	align-items: flex-end;
	position: relative;
	margin-bottom: 2.75rem;
	padding: 1.75rem;
	width: 100%;
	height: 29.75rem;
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 0.5rem;
		z-index: -1;
	}
	.text {
		h2 {
			margin-bottom: 0.25rem;
			color: #fff;
			font-size: 5rem;
			line-height: 5.25rem;
			letter-spacing: -0.002em;
		}
		p {
			margin-bottom: 1.25rem;
			color: #fff;
			font-size: 1.5rem;
			line-height: 2rem;
		}
	}
}
.blog__main-comments--login {
	h4 {
		margin-bottom: 1rem;
		font-size: 1.75rem;
		line-height: 2rem;
		letter-spacing: -0.001em;
	}
	p {
		margin: 0;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: -0.001em;
		a {
			text-decoration: underline;
		}
	}
}
.blog__main-comments--authorize {
	.top {
		h4 {
			margin-bottom: 1.125rem;
			font-size: 1.75rem;
			line-height: 2rem;
			letter-spacing: -0.001em;
		}
		form {
			.form-control {
				width: 38.5625rem;
				height: 3rem;
			}
			.form-btns {
				margin: 0;
				.btn {
					padding: 0;
					width: 18.4375rem;
					height: 3rem;
				}
			}
		}
	}
	.container-btn {
		font-size: 1.25rem;
		letter-spacing: 0.002em;
		text-decoration: underline;
		span {
			&:nth-child(1) {
				display: block;
			}
			&:nth-child(2) {
				display: none;
			}
		}
	}
	.show {
		.container-btn {
			span {
				&:nth-child(1) {
					display: none;
				}
				&:nth-child(2) {
					display: block;
				}
			}
		}
	}
}
.blog__main-comments-block {
	padding: 1.5rem;
	.information__link {
		display: flex;
		align-items: flex-start;
		text-decoration: none;
		cursor: pointer;
		.ico-circle-profile {
			flex-shrink: 0;
			margin-right: 0.75rem;
			width: 3.75rem;
			height: 3.75rem;
		}
		.text {
			margin: 0;
			padding-right: 0.75rem;
			width: auto;
			h6 {
				margin: 0;
				font-size: 1.5rem;
				line-height: 2rem;
			}
			p {
				margin: 0;
				font-size: 1rem;
				line-height: 1.5rem;
				letter-spacing: 0.0002em;
			}
		}
		.ico-chevron-down-b {
			margin-left: auto;
			width: 1.25rem;
			height: 1.25rem;
			transform: rotate(0deg);
			transition: 0.3s;
		}
		&.active {
			.ico-chevron-down-b {
				transform: rotate(180deg);
			}
		}
	}
	.information__collapse {
		.text {
			padding-top: 0.75rem;
			padding-left: 4.5rem;
			p {
				margin: 0;
				font-size: 1.125rem;
				line-height: 1.75rem;
			}
		}
	}
}
.blog__aside-banners {
	position: relative;
}
.blog__aside-banner {
	display: flex;
	align-items: flex-end;
	position: absolute;
	bottom: -2.5rem;
	left: 0;
	padding: 1.75rem;
	width: 100%;
	height: 22.375rem;
	transform: translate(0, 100%);
	transition: 0.3s;
	opacity: 0;
	pointer-events: none;
	&.highlight {
		opacity: 1;
		pointer-events: auto;
	}
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.5rem;
		object-fit: cover;
		z-index: -1;
	}
	.text {
		h3 {
			margin-bottom: 0.25rem;
			color: #fff;
			font-size: 3rem;
			line-height: 3.25rem;
			letter-spacing: -0.001em;
		}
		p {
			margin-bottom: 1.25rem;
			color: #fff;
			font-size: 1.5rem;
			line-height: 2rem;
		}
	}
}
