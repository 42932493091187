.kv {
	padding-top: 0.5rem;
}

.kv__wrap {
	position: relative;
}

.kv__slider .slide-wrap {
	height: 60.25rem;
}

.kv__slider .swiper-wrapper {
	position: relative;
}

.kv__slider-dots {
	position: absolute;
	bottom: 1.75rem;
	right: 1.75rem;
}

/* .kv__slider	.swiper-pagination {
				position: absolute;
				bottom: 3.75rem;
				right: 1.75rem;
			} */

.kv__slider-dots .slick-dots {
	display: flex;
	align-items: center;
	position: relative;
}

.kv__slider .dots {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;
}

.kv__slider-dots .slick-dots li {
	margin-left: 0.75rem;
}

.kv__slider .swiper-pagination {
	display: flex;
	align-items: center;
	position: relative;
	justify-content: end;
}

.kv__slider-dots .slick-dots li:first-child {
	margin: 0;
}

/* .kv__slider-dots .swiper-pagination-bullet {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			margin: 0 4px;
			padding: 0.375rem 0 0;
			width: 3.5rem;
			height: 3.5rem;
			color: #fff;
			font-size: 1.125rem;
			line-height: 1.75rem;
			text-align: center;
			background: rgba(0, 0, 0, 0);
			border: 0.125rem solid rgba(255, 255, 255, 0.5);
			border-radius: 50%;
			outline: 0;
			transition: 0.3s;
			cursor: pointer;
		} */

.kv__slider-dots .swiper-pagination-bullet {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	height: auto;
	margin: 0 10px;
	background-color: transparent;
	border-radius: 50%;
	cursor: pointer;
	transition: 0.3s;
}

.kv__slider-dots .swiper-pagination-bullet span {
	color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.kv__slider-dots .progress-circleSl {
	transform: rotate(-90deg);
	margin: 0 4px;

	width: 3.5rem;
	height: 3.5rem;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.75rem;
	text-align: center;
	background: rgba(0, 0, 0, 0);
	border: 0.1px solid rgba(255, 255, 255, 0.7);
	border-radius: 50%;
}

.circle-origin {
	fill: transparent;
	stroke: rgb(255, 255, 255);
	stroke-width: 3;
	stroke-linecap: round;
	stroke-dasharray: 350px;
	stroke-dashoffset: 350px;
}

.swiper-pagination-bullet-active .circle-origin {
	animation: progress 28.2s;
}

@keyframes progress {
	0% {
		stroke-dashoffset: 350px;
	}

	90% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

/* .kv__slider-dots .slick-dots li button {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			padding: 0.375rem 0 0;
			width: 3.5rem;
			height: 3.5rem;
			color: #fff;
			font-size: 1.125rem;
			line-height: 1.75rem;
			text-align: center;
			background: rgba(0, 0, 0, 0);
			border: 0.125rem solid rgba(255, 255, 255, 0.5);
			border-radius: 50%;
			outline: 0;
			transition: 0.3s;
			cursor: pointer;
		} */

.kv__slider-dots .progress-circle {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	z-index: 2;
	pointer-events: none;
	transform: translate(-50%, -50%);
}

.kv__slider-dots .slick-dots li.slick-active .progress-circle {
	display: block;
}

.kv__slider-dots .progress-circle circle {
	fill: rgba(0, 0, 0, 0);
	stroke: #fff;
}

/* KV-SLIDER END */