.centeredSlider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    width: 100%;
    left: 0;
    overflow: hidden;
    @media (max-width: 768px) {
        position: relative;
		display: block;
    }
}

picture {
    img {
        width: unset;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
}

.imageHeight {
    @media (max-width: 768px) {
        display: none;
    }
}
