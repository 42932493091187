.delivery__head {
	margin-bottom: 1.25rem;
	.nav-tabs {
		display: inline-flex;
		justify-content: space-between;
		padding: 0.125rem;
		width: 100%;
		background: #F8F5F1;
		border: 0;
		border-radius: 0.5rem;
		li {
			margin: 0;
			width: calc(50% - (0.125rem / 2));
		}
		.btn {
			width: 100%;
			&.active {
				color: #fff;
				background: var(--c-main);
			}
		}
	}
	p {
		margin: 0;
		padding: 1.5rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
		background: #F8F5F1;
		border-radius: 0.5rem;
	}
}
.delivery__head-title {
	margin-bottom: 1.75rem;
	font-size: 3rem;
	line-height: 3.25rem;
	letter-spacing: -0.001em;
}
.delivery__change-city {
	margin-bottom: 1.75rem;
	height: 2.5rem;
}
.delivery__change-city-title {
	display: flex;
	align-items: center;
	h4 {
		margin: 0 1.25rem 0 0;
		font-size: 1.75rem;
		line-height: 2rem;
		letter-spacing: -0.001em;
	}
	a {
		font-size: 1rem;
		line-height: 1.5rem;
		text-decoration: underline;
	}
}
.delivery__change-city-form {
	width: 38.4375rem;
	form {
		.form-control {
			padding: 0 6.25rem 0 2.5rem;
		}
	}
}
.delivery__list {
	margin-bottom: 3.5625rem;
}
.delivery__list-block {
	padding: 1.5rem 0;
	border-top: 0.0625rem solid #DCD6D1;
	h5 {
		margin-bottom: 1.5rem;
		font-size: 1.5rem;
		line-height: 1.75rem;
		letter-spacing: -0.001em;
	}
	ul {
		li {
			position: relative;
			&::before {
				content: '·';
				padding: 0 0.5rem;
			}
		}
	}
	.btn {
		margin-top: 1.5rem;
	}
}
.delivery__list-block h6,
	.delivery__list-block p,
	.delivery__list-block ul li {
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.0002em;
}
.delivery__info-block {
	margin-bottom: 0.25rem;
	padding: 1.5rem;
	&:last-child {
		margin-bottom: 0;
	}
	h5 {
		margin-bottom: 1.25rem;
		font-size: 1.5rem;
		line-height: 1.75rem;
		letter-spacing: -0.001em;
	}
	p {
		margin-bottom: 1.75rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
