$color_1: #fff;

.choose__slider.first-slide .choose__slider-main .swiper-wrapper{
	left: -5rem;
}

.choose__info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	position: absolute;
	top: 0;
	left: 0;
	padding: 0 4.0625rem;
	width: 38.5rem;
	height: 57.75rem;
	background: #fff;
	transform: translate(-100%, 0);
	transition: 0.3s;
	z-index: 2;
	h2 {
		margin-bottom: 1.5rem;
		font-weight: 600;
		font-size: 5rem;
		line-height: 5.25rem;
		letter-spacing: -0.02em;
		white-space: nowrap;
	}
	p {
		margin-bottom: 1.75rem;
		font-size: 1.25rem;
		line-height: 1.75rem;
		letter-spacing: -0.025em;
	}
	.btn {
		width: 23.75rem;
		.ico {
			margin-right: 0.5rem;
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}
.choose__slider-main {
	position: relative;
	width: 100%;
	transition: 0.3s;
	overflow: hidden;

	.swiper-wrapper {
		left: 5rem;
		transition: 0.3s !important;
	}

	.swiper-slide {
		display: flex;
		align-items: center;
		margin: 0 0.75rem;
		width: 28.5rem;
		height: 57.75rem;
		transition: 0.3s;
	}
	.swiper-slide-active {
		width: 38.5rem;
		.swiper-slide-cont {
			height: 100%;
		}
		.slide__wrap {
			height: 100%;
			.btn {
				.ico {
					left: 1.75rem;
				}
			}
			.choose__slide-name {
				opacity: 1;
			}
		}
	}
	.swiper-slide-cont {
		position: relative;
		width: 100%;
		height: 55.25rem;
		border-radius: 0.5rem;
		transition: 0.3s;
		overflow: hidden;
	}
	.slide__wrap {
		flex-shrink: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 48.4375rem;
		height: 55.25rem;
		transform: translate(-50%, -50%);
		transition: 0.3s;
		picture {
			display: block;
			width: 100%;
			height: 100%;
			transition: 0.3s;
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.btn {
			position: absolute;
			top: 1.25rem;
			right: 6.25rem;
			padding: 0;
			width: 3.5rem;
			height: 3.5rem;
			border: 0;
			overflow: hidden;
			transition: 0.3s;
			.ico {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			span {
				position: absolute;
				top: 50%;
				left: 3.5rem;
				font-size: 1rem;
				line-height: 1;
				white-space: nowrap;
				transform: translate(0, -50%);
			}
		}
		.choose__slide-name {
			position: absolute;
			bottom: 4rem;
			left: 0;
			margin: 0;
			width: 100%;
			color: $color_1;
			font-size: 1.5rem;
			line-height: 1.75rem;
			text-align: center;
			letter-spacing: -0.01em;
			opacity: 0;
			transition: 0.3s;
		}
		.hint {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
		}
		.hint__dot {
			display: block;
			position: absolute;
			width: 1.25rem;
			height: 1.25rem;
			cursor: pointer;
			background: rgba(255, 255, 255, 0.4);
			border-radius: 50%;
			pointer-events: auto;
			&::before {
				opacity: 0;
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				background: #fff;
				border-radius: 50%;
				transform: translate(-50%, -50%);
			}
			&::after {
				width: 0.75rem;
				height: 0.75rem;
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				background: #fff;
				border-radius: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.hint-1 {
			.hint__dot {
				top: 35%;
				left: 58%;
			}
			.card {
				top: 7.625rem;
				right: 1.25rem;
			}
		}
		.hint-2 {
			.hint__dot {
				top: 57%;
				left: 38%;
			}
			.card {
				top: 7.625rem;
				left: 1.25rem;
			}
		}
		.hint-3 {
			.hint__dot {
				top: 69%;
				left: 60%;
			}
			.card {
				top: 30.625rem;
				right: 1.25rem;
			}
		}
		.hint-4 {
			.hint__dot {
				top: 80%;
				left: 35%;
			}

			.card {
				top: 30.625rem;
				left: 1.25rem;
			}
		}
		.card__info {
			overflow: hidden;
		}
		.card__text {
			margin-bottom: 0.25rem;
			height: 1.5rem;
			font-size: 1rem;
			line-height: 1.5rem;
			white-space: nowrap;
		}
		.card__price {
			margin: 0;
			font-size: 0.875rem;
			line-height: 1.25rem;
		}
	}
	.card {
		position: absolute;
		padding: 0.5rem;
		width: 11.25rem;
		background: #fff;
		border-radius: 0.5rem;
		// pointer-events: none;
		transition: 0.3s;
		.bot {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 0.25rem;
			margin-bottom: -2.25rem;
			transition: 0.3s;
			.card__bag {
				display: block;
				width: 2.25rem;
				height: 2.25rem;
				background: var(--c-main) url("../../img/svg/bag-2.svg") no-repeat 50%/1rem;
				border-radius: 0.5rem;
			}
		}
	}
	.card__img {
		height: 15rem;
		picture {
			img {
				border-radius: 0.25rem;
			}
		}
	}
	.card__img-slider-dots {
		li {
			margin-right: 0.25rem;
		}
	}
	.card__basket {
		flex-direction: row;
		margin-bottom: 0;
		padding: 0;
		width: auto;
		height: auto;
		background: transparent;
		ul {
			li {
				display: none;
				a {
					border: 0.0625rem solid #DCD6D1;
				}
			}
			li.active {
				display: block;
			}
		}
		.sizes-swipe {
			display: block;
			width: 1.5rem;
			height: 1.5rem;
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: contain;
			cursor: pointer;
		}
		.sizes-swipe--prev {
			margin-right: 0.25rem;
			background-image: url("../../img/svg/chevron-left-black.svg");
		}
		.sizes-swipe--next {
			margin-left: 0.25rem;
			background-image: url("../../img/svg/chevron-right-black.svg");
		}
	}
	.swiper-conrol {
		position: absolute;
		bottom: 4rem;
		// top: 49%;
		left: 50%;
		width: 100%;
		height: 1.75rem;
		transform: translate(-50%, 0);
		transition: 0.3s;
		z-index: 1;
	}
	.swiper-btn {
		position: absolute;
		bottom: 0;
		width: 1.75rem;
		height: 1.75rem;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		transition: 0.3s;
		cursor: pointer;
	}
	.swiper-btn-prev {
		left: 49.375rem;
		background-image: url("../../img/svg/swiper-prev.svg");
	}
	.swiper-btn-next {
		right: 49.375rem;
		background-image: url("../../img/svg/swiper-next.svg");
	}
}
.choose.scale {
	.choose__slider-main {
		.swiper-wrapper {
			left: -10rem;
		}
		.swiper-slide-active {
			width: 48.4375rem;
			.slide__wrap {
				.btn {
					width: 11.375rem;
				}
			}
		}
		.slide__wrap {
			.btn {
				right: 1.25rem;
			}
		}
	}
}
.choose--active {
	.choose__info {
		opacity: 0;
		pointer-events: none;
	}
}
