.faq {
    @media screen and (max-width: 768px) {
        margin: 20px;
    }
    .faqTitle {
        text-align: center;
        margin: 50px 0;
        font-size: 25px;
        @media screen and (max-width: 768px) {
            margin: 30px 0;
            font-size: 22px;
            line-height: 1.6;
        }
    }
    .faqElem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        margin: 30px 76px 30px 30px;
        cursor: pointer;
        @media screen and (max-width: 768px) {
            margin: 20px 0;
            font-size: 18px;
        }
    }
    .text {
        font-size: 17px;
        line-height: 1.6;
        margin: 30px 76px 30px 30px;
        @media screen and (max-width: 768px) {
            margin: 0 37px 0 0;
            font-size: 16px;
        }
        a {
            text-decoration-line: underline;
            text-decoration-style: dotted;
        }
    }
}

.answerImage {
    margin: 0 30px 30px 30px;

    @media screen and (max-width: 768px) {
        margin: 0;
    }
    img {
        max-width: 100%;
    }
}
